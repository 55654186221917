import { Component, OnInit, Input, HostBinding, Output, EventEmitter, HostListener, AfterViewInit, AfterContentChecked } from '@angular/core';
import { RWElement } from '../../../classes/RWElement';
import { RWElementParser } from '../../../classes/RWElementParser';
import { RWElementType } from '../../../classes/RWElementType';
import { ElementPropertyService } from 'src/app/services/element-property.service';
import { TreeTableHeaderObject, TreeTableData, TreeTableRow, TtDataType, TreeTableRowAction } from 'angular-tree-table';
import { RWElementRendererDisplayMode } from "src/app/classes/RWElementRendererDisplayMode";
import { RWEventType } from 'src/app/classes/RWEventType';
import { RWElementControlType } from 'src/app/classes/RWElementControlType';
import { DataSourceExecutorService } from 'src/app/services/data-source-executor.service';
import { RWElementSelectOption } from 'src/app/classes/RWElementSelectOption';
import { RWActionType } from 'src/app/classes/RWActionType';
import { ActivatedRoute, RouterEvent } from '@angular/router';
import { RWAPIMethod } from 'src/app/classes/RWAPIMethod';
import { HttpClient } from '@angular/common/http';
import { RequestContentType } from 'src/app/classes/RequestContentType';
import { FileUploadDataProviderService } from 'src/app/services/file-upload-data-provider.service';
import { ActionExecutorService } from 'src/app/services/action-executor.service';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { Util } from 'src/app/util/util';
import * as moment from 'moment';
import { RWTableConfig } from 'src/app/classes/RWTableConfig';
declare var $: any

@Component({
  selector: 'app-rw-element-renderer',
  templateUrl: './rw-element-renderer.component.html',
  styleUrls: ['./rw-element-renderer.component.scss']
})
export class RWElementRendererComponent implements OnInit, AfterContentChecked {


  @Input() elementData: RWElement;
  @Input() displayMode: RWElementRendererDisplayMode;
  @Output() remove = new EventEmitter<RWElement>();
  @Output() copyPath = new EventEmitter<string>();
  currentCopyPath = undefined;
  @Output() dynamicDuplicate = new EventEmitter<RWElement>();
  @Output() dynamicRemove = new EventEmitter<RWElement>();

  @HostBinding('class') className;
  @HostBinding('id') idVal;
  @HostBinding('style')
  get myStyle(): SafeStyle {
    if (this.elementData !== undefined && this.elementData !== null) {
      if (this.elementData.getFontSize() != undefined && this.elementData.getFontSize() !== null && this.elementData.getFontSize() !== '') {
        return this.sanitizer.bypassSecurityTrustStyle('font-size: ' + this.elementData.getFontSize());
      }
    }
    return this.sanitizer.bypassSecurityTrustStyle('');
  }

  allCols: string[] = [];
  selectedControl: RWElement;
  columns = [0, 2, 3, 4, 6, 8, 10, 12];

  title = 'test1';
  jCount = 0;
  tableData: TreeTableData = null;
  config = {
    columnVisibilityDropDown: true,
    commonSearch: true,
    excelExportButton: true,
    excelExportFileName: 'ExportFile',
    excelExportButtonText: 'Excel Export',
    showExpandArrows: true,
    showExpandAllArrows: true,
    showPageLengthDropdown: true,
    locale: 'en-IN',
    currencyCode: 'INR'
  };
  subTableData = new TreeTableData(this.config);

  tableHeaders: TreeTableHeaderObject[] = [];
  subTableHeaders: TreeTableHeaderObject[] = [];
  formValidation: boolean;
  radioCheckedValue: string;

  constructor(private elementPropertyService: ElementPropertyService,
    private route: ActivatedRoute,
    private dataSourceExecutorService: DataSourceExecutorService,
    private http: HttpClient,
    private fileUploadDataProvider: FileUploadDataProviderService,
    private actionExecutorService: ActionExecutorService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.onLoadEventExecutor();
    this.populatePossibleCols();
    if (this.elementPropertyService.getCurrency() !== undefined) {
      if (this.elementPropertyService.getCurrency().locale !== undefined && this.elementPropertyService.getCurrency().currencyCode !== undefined) {
        this.config.locale = this.elementPropertyService.getCurrency().locale;
        this.config.currencyCode = this.elementPropertyService.getCurrency().currencyCode;
      }
    }
    this.tableData = new TreeTableData(this.config);
    if (this.elementData.getControlType() === RWElementControlType.TABLE) {
      this.elementData.getTableHeaders().sort(function (a, b) { return a.columnOrder - b.columnOrder });
      if (this.elementData.getTableConfig() !== undefined) {
        this.tableData = new TreeTableData(this.elementData.getTableConfig());
      } else {
        let intitalTableConfig = new RWTableConfig();
        intitalTableConfig.columnVisibilityDropDown = false,
          intitalTableConfig.commonSearch = true,
          intitalTableConfig.excelExportButton = true,
          intitalTableConfig.excelExportButtonText = 'Export Excel',
          intitalTableConfig.excelExportFileName = 'Export File Name',
          intitalTableConfig.showExpandAllArrows = true,
          intitalTableConfig.showExpandArrows = true,
          intitalTableConfig.showPageLengthDropdown = true,
          intitalTableConfig.showTableHeaders = true,
          intitalTableConfig.visibleColumnFiltersVisibility = false
        this.elementData.setTableConfig(intitalTableConfig);
        this.tableData = new TreeTableData(intitalTableConfig);
      }
    }

    this.reloadTable();
    this.elementPropertyService.tableSettingsChanged.subscribe(resp => {
      this.reloadTable();
    })

    this.elementPropertyService.validationChanged.subscribe(resp => {
      if (resp.formValidationValue != undefined) {
        this.formValidation = resp.formValidationValue
      } else {
        this.formValidation = true
      }
    })

    if (this.elementData.getControlType() === RWElementControlType.DATE) {
      let currentDate = moment(new Date()).format('YYYY-MM-DD');
      if (this.elementData.getMin() === 'new Date()') {
        this.elementData.setMinDate(currentDate);
      }
    }
  }

  getEventListenerInput() {
    return { elementData: this.elementData, context: this };
  }

  // elementInlineStyles() {
  //   if (this.elementData.getInlineStyles() === undefined || this.elementData.getInlineStyles() === null || this.elementData.getInlineStyles() === '' || this.elementData.getInlineStyles() === '{}') {
  //     return undefined;
  //   }
  //   return JSON.parse(this.elementData.getInlineStyles());
  // }



  onLoadEventExecutor() {

    if (this.elementData.getPayLoadDataSource() != undefined) {
      this.elementPropertyService.setPayLoadElement(this.elementData.getValue(), this.elementData.getPayLoadDataSource().payLoadDataSourceName, this.elementData.getPayLoadDataSource().payLoadProperty, this.elementData.validate(), this.elementData);
    }

    const event = this.elementData.getEvents().find(v => v.type === RWEventType.ON_LOAD);
    if (event === undefined) {
      return;
    }
    if (event.action.type === RWActionType.LOAD_OTHER_DATASOURCE) {
      this.actionExecutorService.execute(event.action, this.elementData, (resp: any) => { }, undefined, this);
    }

    if (event.action.type === RWActionType.RELOAD_OTHER_DATASOURCE) {
      const dataSourceCode = this.elementData.getOtherDataSourceNameForOnLoadWithoutIndex();
      if (dataSourceCode !== undefined && dataSourceCode !== null && dataSourceCode !== '') {
        this.dataSourceExecutorService.load(
          {
            dataSource: dataSourceCode,
            inherited: false,
            property: '',
            shouldUseDataSourceResult: true,
            enableLocalSubscription: false
          },
          this.route
        ).subscribe(resp => { });
      }
    }

    if (event.action.type === RWActionType.RELOAD_SELF_DATASOURCE) {
      const dataSourceCode = this.elementData.getValueDataSource().dataSource;
      if (dataSourceCode !== undefined && dataSourceCode !== null && dataSourceCode !== '') {
        this.dataSourceExecutorService.load(
          {
            dataSource: dataSourceCode,
            inherited: false,
            property: '',
            shouldUseDataSourceResult: true,
            enableLocalSubscription: false
          },
          this.route
        ).subscribe(resp => { });
      }
    }

    if (event.action.type === RWActionType.LOAD_SELF_DATASOURCE) {

      if (this.elementData.getValueDataSource() !== undefined) {
        this.actionExecutorService.execute(event.action, this.elementData, (resp: any) => {
          let data = resp; // [this.elementData.getValueDataSource().dataSource];
          if (this.elementData.getValueDataSource().property !== undefined &&
            this.elementData.getValueDataSource().property !== null &&
            this.elementData.getValueDataSource().property !== '') {
            data = Util.evaluateConcat(this.elementData.getValueDataSource().property, data, this.elementPropertyService.getCurrency());
            if (this.elementData.getControlType() === RWElementControlType.DATE) {
              data = moment(data).format('YYYY-MM-DD');
            }
            if (this.elementData.getElementType() === RWElementType.IMAGE) {
              this.elementData.setAttribute('src', data);
            }
          }
          this.elementData.setExtraInfo(data);
          if (this.elementData.getValueDataSource().shouldUseDataSourceResult) {
            this.elementData.setValue(data);
            if (this.elementData.getControlType() === RWElementControlType.RADIO) {
              if (data === this.elementData.getRadioValue()) {
                this.elementData.setAttribute('checked', 'checked');
              }
            }
            if (this.elementData.getControlType() === RWElementControlType.CHECKBOX) {
              if (data === this.elementData.getCheckboxValue()) {
                this.elementData.setAttribute('checked', 'checked');
              }
            }
            this.changedElement(data, this.elementData);
          }
          if (this.elementData.getElementType() === RWElementType.CONTROL) {
            if (this.elementData.getControlType() === RWElementControlType.TABLE) {
              if (this.tableData.data.length > 0) {
                this.tableData.data.splice(0, this.tableData.data.length);
              }
              if (Array.isArray(data)) {
                const rows: TreeTableRow[] = [];
                let ind = 1;
                for (const item of data) {

                  // below condition is checking for null and making empty for respective key only
                  for (var i = 0; i < this.elementData.getTableHeaders().length; i++) {
                    if (item[this.elementData.getTableHeaders()[i].dataProperty] === null) {
                      item[this.elementData.getTableHeaders()[i].dataProperty] = '';
                    }
                  }

                  const row = new TreeTableRow(ind + '', item, false, null);
                  const childrenDataProperty = this.elementData.getChildernDataProperty();
                  if (childrenDataProperty !== undefined && childrenDataProperty !== null && item[childrenDataProperty] !== undefined && item[childrenDataProperty] !== null) {
                    row.expandable = true;
                    if (this.elementPropertyService.getCurrency().locale !== undefined && this.elementPropertyService.getCurrency().currencyCode !== undefined) {
                      this.config.locale = this.elementPropertyService.getCurrency().locale;
                      this.config.currencyCode = this.elementPropertyService.getCurrency().currencyCode;
                    }
                    if (this.elementData.getSubTableConfig() !== undefined) {
                      this.subTableData = new TreeTableData(this.elementData.getSubTableConfig());
                    } else {
                      let intitalSubTableConfig = new RWTableConfig();
                      intitalSubTableConfig.columnVisibilityDropDown = false,
                        intitalSubTableConfig.commonSearch = false,
                        intitalSubTableConfig.excelExportButton = false,
                        intitalSubTableConfig.excelExportButtonText = 'Export Excel',
                        intitalSubTableConfig.excelExportFileName = 'Export File Name',
                        intitalSubTableConfig.showExpandAllArrows = true,
                        intitalSubTableConfig.showExpandArrows = true,
                        intitalSubTableConfig.showPageLengthDropdown = false,
                        intitalSubTableConfig.showTableHeaders = true,
                        intitalSubTableConfig.visibleColumnFiltersVisibility = false
                      this.elementData.setSubTableConfig(intitalSubTableConfig);
                      this.subTableData = new TreeTableData(intitalSubTableConfig);
                    }
                    const subData = [];
                    for (let j = 0; j < item[childrenDataProperty].length; j++) {
                      const subRow = new TreeTableRow(j + 'sub', item[childrenDataProperty][j], false, null);
                      subRow.clickablesContext = this;
                      subRow.clickables = {};
                      for (const subHeader of this.elementData.getSubTableHeaders()) {
                        if (subHeader.subClickableAction !== undefined && subHeader.subClickableAction !== null && subHeader.subClickableAction.properties.length > 0) {
                          subRow.clickables[subHeader.dataProperty] = this.subRowClickableClicked;
                        }
                      }
                      const actionSubHeader = this.elementData.getSubTableHeaders().find(v => v.dataType === TtDataType.ACTIONS.toString());
                      if (actionSubHeader !== undefined && actionSubHeader !== null) {
                        if (actionSubHeader.actions !== undefined && actionSubHeader.actions !== null && actionSubHeader.actions.length > 0) {
                          for (const action of actionSubHeader.actions) {
                            const rowAction = new TreeTableRowAction(action.text, action.title, 'btn btn-secondary', this.subRowActionClicked);
                            rowAction.context = this;
                            subRow.actions.push(rowAction);
                          }
                        }
                      }
                      subData.push(subRow);
                    }
                    this.subTableData.headers = this.subTableHeaders;

                    this.subTableData.data = subData;
                    row.children = this.subTableData;
                  }
                  row.clickablesContext = this;
                  row.clickables = {};
                  for (const header of this.elementData.getTableHeaders()) {
                    if (header.clickableAction !== undefined && header.clickableAction !== null && header.clickableAction.properties.length > 0) {
                      row.clickables[header.dataProperty] = this.rowClickableClicked;
                    }
                  }
                  const actionHeader = this.elementData.getTableHeaders().find(v => v.dataType === TtDataType.ACTIONS.toString());
                  if (actionHeader !== undefined && actionHeader !== null) {
                    if (actionHeader.actions !== undefined && actionHeader.actions !== null && actionHeader.actions.length > 0) {
                      for (const action of actionHeader.actions) {
                        const rowAction = new TreeTableRowAction(action.text, action.title, 'btn btn-secondary', this.rowActionClicked);
                        rowAction.context = this;
                        row.actions.push(rowAction);
                      }
                    }
                  }

                  rows.push(row);
                  ind++;
                }
                this.tableData.data = rows;
              }
            }
          }
        }, undefined, this);
      }

      if (this.elementData.getElementType() === RWElementType.CONTROL && this.elementData.getControlType() == RWElementControlType.SELECT) {
        if (this.elementData.getOptionsDataSource() !== undefined) {
          this.dataSourceExecutorService.load(this.elementData.getOptionsDataSource(), this.route).subscribe(resp => {
            let data = resp; // [this.elementData.getOptionsDataSource().dataSource];
            if (this.elementData.getOptionsDataSource().property !== undefined &&
              this.elementData.getOptionsDataSource().property !== null &&
              this.elementData.getOptionsDataSource().property !== '') {
              data = Util.getValueFromObjectByPropertyPath(data, this.elementData.getOptionsDataSource().property);
            }
            if (this.elementData.getOptionsLength() > 0) {
              this.elementData.removeAllOptions();
            }
            if (Array.isArray(data)) {
              for (const item of data) {
                const option = new RWElementSelectOption();
                option.displayText = Util.getValueFromObjectByPropertyPath(item, this.elementData.getOptionsDataSource().valueProperty);
                option.value = Util.getValueFromObjectByPropertyPath(item, this.elementData.getOptionsDataSource().keyProperty);
                this.elementData.addOption(option);
                // below logic is prepopulating purpose
                if (this.elementData.getDropDownType() === 'Multiple') {
                  for (var i = 0; i < this.elementData.getOptions().length; i++) {
                    for (var j = 0; j < this.elementData.getValue().length; j++) {
                      if (this.elementData.getOptions()[i].value === this.elementData.getValue()[j][this.elementData.getPayLoadKey()]) {
                        this.elementData.getOptions()[i].selected = "selected";
                      }
                    }
                  }
                }
              }
            }
          });
        }
      }
    }
  }

  rowClickableClicked(data: any, dataProperty: string): void {
    const clickedHeader = this.elementData.getTableHeaders().find(v => v.dataProperty === dataProperty);
    if (clickedHeader === undefined) {
      return;
    }


    if (clickedHeader.clickableAction !== undefined && clickedHeader.clickableAction !== null) {
      this.elementData.setContext({ data: data, action: clickedHeader.clickableAction });
      this.actionExecutorService.execute(clickedHeader.clickableAction, this.elementData, undefined, undefined, this);
    } else {
      console.warn('No actions found');
    }
  }

  rowActionClicked(data: any, rowAction: TreeTableRowAction): void {
    const actionsHeader = this.elementData.getTableHeaders().find(v => v.dataType === TtDataType.ACTIONS);
    if (actionsHeader === undefined) {
      return;
    }
    const rwAction = actionsHeader.actions.find(v => v.text === rowAction.label);
    if (rwAction !== undefined && rwAction !== null) {
      this.elementData.setContext({ data: data, action: rwAction.action });
      this.actionExecutorService.execute(rwAction.action, this.elementData, undefined, undefined, this);
    } else {
      console.warn('No actions found');
    }
  }

  subRowClickableClicked(data: any, dataProperty: string): void {
    const clickedHeader = this.elementData.getSubTableHeaders().find(v => v.dataProperty === dataProperty);
    if (clickedHeader === undefined) {
      return;
    }
    if (clickedHeader.subClickableAction !== undefined && clickedHeader.subClickableAction !== null) {
      this.elementData.setContext({ data: data, action: clickedHeader.subClickableAction });
      this.actionExecutorService.execute(clickedHeader.subClickableAction, this.elementData, undefined, undefined, this);
    } else {
      console.warn('No actions found');
    }
  }

  subRowActionClicked(data: any, rowAction: TreeTableRowAction): void {
    const actionsHeader = this.elementData.getSubTableHeaders().find(v => v.dataType === TtDataType.ACTIONS);
    if (actionsHeader === undefined) {
      return;
    }
    const rwAction = actionsHeader.actions.find(v => v.text === rowAction.label);
    if (rwAction !== undefined && rwAction !== null) {
      this.elementData.setContext({ data: data, action: rwAction.action });
      this.actionExecutorService.execute(rwAction.action, this.elementData, undefined, undefined, this);
    } else {
      console.warn('No actions found');
    }
  }

  reloadTable() {
    this.tableInit();
    this.subTableInit();
  }

  ngAfterContentChecked(): void {
    if (this.elementData.getElementType() !== RWElementType.CONTROL && this.displayMode !== 'TOOLBOX') {
      this.className = this.elementData.getAttribute('class');
    } else if (this.elementData.getControlType() === RWElementControlType.FORM && this.displayMode !== 'TOOLBOX') {
      this.className = this.elementData.getAttribute('class');
    }
    // this.className += this.elementData.getEnableDynamicallyDuplicate() ? 'dynamic-duplicate-enabled' : '';
    this.idVal = this.elementData.getAttribute('id');
  }

  populatePossibleCols() {
    for (let i = 1; i < 13; i++) {
      this.allCols.push('col-' + i);
      this.allCols.push('col-sm-' + i);
      this.allCols.push('col-md-' + i);
      this.allCols.push('col-lg-' + i);
      this.allCols.push('col-xs-' + i);
    }
  }

  addRowClicked(): void {
    const row = new RWElement();
    row.setLevel(this.elementData.getLevel() + 1);
    row.addClass('row');
    this.elementData.addChild(row);
  }

  addColClicked(col: number): void {
    const row = new RWElement();
    row.setLevel(this.elementData.getLevel() + 1);
    if (col > 0) {
      row.addClass('col-' + col);
    } else {
      row.addClass('col');
    }
    this.elementData.addChild(row);
  }

  removeClicked(): void {
    this.remove.next(this.elementData);
  }

  removeChildClicked($event: RWElement) {
    const index = this.elementData.getChildren().findIndex(v => v.getId() === $event.getId());
    if (index > -1) {
      this.elementData.getChildren().splice(index, 1);
    }
  }

  settingsClicked(event: any, elementData: RWElement) {
    this.selectedControl = elementData;
    this.elementPropertyService.setElement(elementData);
  }

  copyClicked() {
    if (this.currentCopyPath === undefined) {
      this.currentCopyPath = '#' + this.elementData.getId();
    } else {
      this.currentCopyPath = '#' + this.elementData.getId() + '.' + this.currentCopyPath;
    }
    this.copyPath.next(this.currentCopyPath);
  }

  copyPathClicked(path: string) {
    this.currentCopyPath = '#' + this.elementData.getId() + '.' + path;
    if (this.elementData.getLevel() === 0) {
      this.copyText(this.currentCopyPath);
    } else {
      this.copyPath.next(this.currentCopyPath);
    }
  }

  copyText(text: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    console.log('textCopied', text);
  }

  @HostListener('drop', ['$event']) dropped(event: any) {
    if (this.displayMode !== RWElementRendererDisplayMode.DESIGN) {
      return false;
    }
    const eventTargetId = event.target.attributes.getNamedItem('id').value;
    if (eventTargetId != this.elementData.getId()) {
      return;
    }
    const controlJson = JSON.parse(event.dataTransfer.getData('CONTROL'));
    let control = RWElementParser.jsonToLayoutElement(controlJson)

    control = this.updateChildLevelsAndIds(this.elementData, control);

    this.elementData.getChildren().push(control);
  }

  updateChildLevelsAndIds(parent: RWElement, child: RWElement): RWElement {
    child.setNewId();
    child.setLevel(parent.getLevel() + 1);
    for (let [index, subChild] of child.getChildren().entries()) {
      child.setChildren(index, this.updateChildLevelsAndIds(child, subChild));
    }
    return child;
  }

  @HostListener('dragover', ['$event']) dragOver(event: any) {
    event.preventDefault();
  }

  canShowAddCol(): boolean {
    return this.elementData.getClasses().indexOf('row') > -1;// && this.elementData.getLevel() > 0;
  }

  tableInit() {
    this.tableHeaders.splice(0, this.tableHeaders.length);
    const headers = this.elementData.getTableHeaders();
    for (const rawHeader of headers) {
      const header = new TreeTableHeaderObject(rawHeader.title, rawHeader.dataProperty, rawHeader.style, rawHeader.show);
      header.dataType = <TtDataType>rawHeader.dataType;
      this.tableHeaders.push(header);
    }
    this.tableData.headers = this.tableHeaders;
  }

  subTableInit() {
    this.subTableHeaders.splice(0, this.subTableHeaders.length);
    const subHeaders = this.elementData.getSubTableHeaders().sort(function (a, b) { return a.columnOrder - b.columnOrder });
    for (const rawHeader of subHeaders) {
      const header = new TreeTableHeaderObject(rawHeader.title, rawHeader.dataProperty, rawHeader.style, rawHeader.show);
      header.dataType = <TtDataType>rawHeader.dataType;
      this.subTableHeaders.push(header);
    }
    this.subTableData.headers = this.subTableHeaders;
  }

  postGetResponse(url, obj) {
    return this.http.post(url, obj);
  }
  postUrl(url, obj) {
    this.postGetResponse(url, obj).subscribe((resp) => {
      console.info("posturlResp", resp);
    });
  }

  fileSelected(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList && fileList.length > 0) {
      this.fileUploadDataProvider.add(this.elementData, fileList);
    }
  }

  onFormSubmit(formEvent: any) {
    const event = this.elementData.getEvents().find(v => v.type === RWEventType.FORMSUBMIT);
    if (event === undefined || event === null || event.action === undefined) {
      return true
    }

    var formData = $(formEvent.target).serializeArray();
    var finalArray = [];
    var obj = {};
    for (var i = 0; i < formData.length; i++) {
      if (typeof obj[formData[i].name] != "undefined") {
        var subArray = [];
        subArray.push(obj[formData[i].name]);
        // above line is pervious obj insert to subArray
        subArray.push(formData[i].value);
        // above line is new value insert to subArray
        obj[formData[i].name] = subArray;
      } else {
        obj[formData[i].name] = formData[i].value;
      }
    }
    finalArray.push(obj);
    var formStringfy = JSON.stringify(finalArray);
    var formJson = JSON.parse(formStringfy);

    if (event.action.type === RWActionType.ALERT) {
      alert(event.action.properties[0].value);
    } else if (event.action.type === RWActionType.STORE_SELF_DATASOURCE) {
      if (this.elementData.getFormContentType() === RequestContentType.MULTIPART_FORM_DATA) {
        const formData = new FormData();
        if (formEvent.target[0].value === '') {
          return;
        }
        const files = $(formEvent.target).find('input[type=file]');
        const file = files[0];
        console.log("$$files",files)

        console.log("$$file",file)

        const fileData = this.fileUploadDataProvider.get($(file).attr('id'));
        console.log("$$fileData", fileData)
        console.log("$$fileDataElement", this.elementData, this.elementData.getFileType())
        if (fileData !== undefined) {
          if (this.elementData.getFileType() === undefined || this.elementData.getFileType() === 'Single') {
            formData.append('import_file', fileData[fileData.length - 1], fileData[fileData.length - 1].name);
          } else {
            for (var i = 0; i < fileData.length; i++) {
              formData.append('import_file', fileData[i], fileData[i].name);
            }
          }
        }

        this.dataSourceExecutorService.load(this.elementData.getValueDataSource(), this.route, undefined, undefined, formData, undefined, this.elementData.getFormContentType()).subscribe(resp => {
          console.info('multipartformdata res', resp);
        });
      } else {
        this.dataSourceExecutorService.load(this.elementData.getValueDataSource(), this.route, undefined, undefined, formJson, undefined, this.elementData.getFormContentType()).subscribe(resp => {
          console.info('non multipartformdata res', resp);
        });
      }
    } else if (event.action.type === RWActionType.API_CALL) {

      const methodType = event.action.properties.find(v => v.key === 'method');
      if (methodType === undefined || methodType === null) {
        return true;
      }
      const urlValue = event.action.properties.find(v => v.key === 'url');
      if (urlValue === undefined || urlValue === null) {
        return true;
      }
      if (methodType.value === RWAPIMethod.POST) {
        this.postUrl(urlValue.value, formJson);
      }

    } else if (event.action.type === RWActionType.LOAD_OTHER_DATASOURCE) {
      this.actionExecutorService.execute(event.action, this.elementData, (resp: any) => { }, undefined, this);
    }
  }

  dynamicAddBtnClicked(): void {
    this.dynamicDuplicate.next(this.elementData);
  }

  dynamicRemoveBtnClicked(): void {
    this.dynamicRemove.next(this.elementData);
  }

  dynamicDuplicateAction(event: RWElement): void {
    const index = this.elementData.getChildren().findIndex(v => v.getId() === event.getId());
    if (index === -1) {
      return;
    }
    const rawEvent = JSON.stringify(event);
    console.log('JSON.parse(rawEvent).enableDynamicallyDuplicate', JSON.parse(rawEvent).enableDynamicallyDuplicate);
    console.log('JSON.parse(rawEvent).enableDynamicallyRemove', JSON.parse(rawEvent).enableDynamicallyRemove);
    let copyEvent = RWElementParser.jsonToLayoutElement(JSON.parse(rawEvent));
    console.log('copyEvent.getEnableDynamicallyDuplicate()', copyEvent.getEnableDynamicallyDuplicate());
    console.log('copyEvent.getEnableDynamicallyRemove()', copyEvent.getEnableDynamicallyRemove());
    copyEvent.setNewId();
    copyEvent = this.updateChildLevelsAndIds(this.elementData, copyEvent);

    // Updating Payload Properties if exists
    copyEvent = this.updateDuplicateFieldsPayloadProperties(copyEvent, this.elementData);

    this.elementData.addChild(copyEvent);
  }

  updateDuplicateFieldsPayloadProperties(copyElement: RWElement, parentElement: RWElement): RWElement {
    const propName = copyElement.getPayLoadDataSourceProperty();
    if (propName !== undefined && propName !== null && propName !== '') {
      // user.address[].dno
      if (propName.includes('[') && propName.includes(']')) {
        const arrayVarParts = propName.split('['); // user.address, ].dno
        const arrayProperty = arrayVarParts[0]; // user.address
        let arrayIndexVal = 0;
        const arrayIndex = arrayVarParts[1].split(']')[0]; // empty string ''
        if (arrayIndex !== undefined && arrayIndex !== null && arrayIndex !== '') {
          arrayIndexVal = parseInt(arrayIndex);
        }

        let arrayIndexNextPath = '';
        if (arrayVarParts[1].split(']').length > 1) {
          arrayIndexNextPath = arrayVarParts[1].split(']')[1]; // .dno
        }


        // const newPropName = arrayProperty + '[' + (arrayIndexVal + 1) + ']' + arrayIndexNextPath;
        // Considering the children count instead of Selected/ Add button clicked object to add index - to get proper index
        const newPropName = arrayProperty + '[' + (parentElement.getChildren().length) + ']' + arrayIndexNextPath;
        console.log(`new PropName for ${copyElement.getPayLoadDataSourceProperty()} is ${newPropName}`);
        copyElement.setPayLoadDataSourcePropertyD(newPropName);
      } else {
        console.warn('Property name should contain [] for repeatable fields');
      }
    }
    copyElement.getChildrenRaw().forEach((v: RWElement, index: number) => {
      copyElement.setChildren(index, this.updateDuplicateFieldsPayloadProperties(v, parentElement));
    });
    return copyElement;
  }

  getPayloadDatasourceNameFromChildren(element: RWElement): { dsName: string, propName: string } | undefined {
    let dsName = element.getPayLoadDataSourceName();
    if (dsName !== undefined && dsName !== null && dsName !== '') {
      return { dsName, propName: element.getPayLoadDataSourceProperty() };
    }
    for (let v of element.getChildrenRaw()) {
      let data = this.getPayloadDatasourceNameFromChildren(v);
      if (data !== undefined && data !== null) {
        return data;
      }
    }
    return undefined;
  }

  dynamicRemoveAction(event: RWElement): void {
    const index = this.elementData.getChildrenRaw().findIndex(v => v.getId() === event.getId());
    if (index === -1) {
      console.warn('deleteRemoveAction index not exists', event);
      return;
    }
    console.log('deleteRemoveAction index exists', index);
    let data = this.getPayloadDatasourceNameFromChildren(event);
    if (data !== undefined) {
      this.elementPropertyService.deletePayLoadElementByPath(data.dsName, data.propName, index);
    } else {
      console.warn('Prop Name Empty for removing field', event, index);
    }
    let child = this.elementData.getChildrenRaw()[index];
    let classes = child.getClasses();
    classes.push('removed-duplicate-field');
    child.setClassesD(classes);
    this.elementData.setChildren(index, child);
    // this.elementData.removeChild(event);

    // Copy Element Data
    // const copyElement = RWElementParser.jsonToLayoutElement(JSON.stringify(this.elementData));
    // this.elementData.getChildrenRaw().forEach(v => {
    //   this.elementData.removeChild(v);
    // })
    // copyElement.getChildrenRaw().forEach(v => {
    //   v = this.updateDuplicateFieldsPayloadProperties(v, this.elementData);
    //   this.elementData.addChild(v);
    //   this.changedElement(v.getValue(), v);
    // });

    // this.elementPropertyService.deletePayLoadLastElementByPath(event.getPayLoadDataSource().payLoadDataSourceName, event.getPayLoadDataSource().payLoadProperty);
  }

  changedElement(currentValue: any, elementData: RWElement, checkEvent?) {
    let multiSelectData = [];

    if (currentValue === 'null') {
      currentValue = '';
    }

    if (checkEvent !== undefined) {
      if (elementData.getControlType() === RWElementControlType.RADIO) {
        if (checkEvent.target.checked === true) {
          currentValue = elementData.getRadioValue();
          elementData.setAttribute("checked", "checked");
          let radioElements = this.elementPropertyService.getCurrentRwElements().filter(x => x.getName() === elementData.getName());
          for (var i = 0; i < radioElements.length; i++) {
            if (radioElements[i].getAttribute("checked") === undefined) {
              if (radioElements[i].getValidations() !== undefined) {
                radioElements[i].getValidations().required = false;
              }
            }
          }
        }
      }
    }

    if (checkEvent !== undefined) {
      if (elementData.getControlType() === RWElementControlType.CHECKBOX) {
        if (checkEvent.target.checked === false) {
          currentValue = '';
        }
      }
    }
    if (checkEvent !== undefined) {
      if (elementData.getControlType() === RWElementControlType.SELECT) {
        if (elementData.getDropDownType() === 'Multiple') {
          for (var i = 0; i < checkEvent.target.selectedOptions.length; i++) {
            multiSelectData.push({ [elementData.getPayLoadKey()]: checkEvent.target.selectedOptions.item(i).value });
          }
          currentValue = multiSelectData;
        }
      }
    }

    if (elementData.getPayLoadDataSource() === undefined || elementData.getPayLoadDataSource() === null) {
      console.warn("No PayLoad DataSourceName & Property")
      return;
    }
    elementData.setValue(currentValue);

    this.elementPropertyService.setPayLoadElement(currentValue, elementData.getPayLoadDataSource().payLoadDataSourceName, elementData.getPayLoadDataSource().payLoadProperty, elementData.validate(), elementData);
  }
}
