<div class="page-title">
    <label>Settings</label>
</div>
<div class="page-content">
    <div class="new-form">
        <form #newForm="ngForm" (ngSubmit)="submit($event, newForm)">
            <label> Application </label>
            <div class="form-row align-items-center">
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">Application Name</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Application Name</div>
                        </div>
                        <input name="applicationName" [(ngModel)]="application.applicationName" type="text"
                            class="form-control" required />
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Description</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Description</div>
                        </div>
                        <textarea class="form-control" name="description"
                            [(ngModel)]="application.description"></textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Footer Text</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Footer Text</div>
                        </div>
                        <input name="footerText" [(ngModel)]="application.footerText" type="text"
                            class="form-control" />
                    </div>
                </div>
            </div>
            <div class="form-row align-items-center">
                <div class="col-md-4">
                    <label class="sr-only">Logo</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Logo</div>
                        </div>
                        <select class="form-control" name="logo" [(ngModel)]="application.logo" required>
                            <option [value]="null"></option>
                            <option *ngFor="let logo of logoList" [value]="logo"> {{logo}} </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4 border-image">
                    <label class="d-flex sr-only justify-content-center">Selected Logo</label>
                    <img [src]="application.logo" class="logo-image mx-auto d-block" alt="">
                </div>
            </div>
            <div class="form-row align-items-center">
                <div class="col-md-4">
                    <label class="sr-only">Background Image</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Background Image</div>
                        </div>
                        <select class="form-control" name="backgroundImage" [(ngModel)]="application.backgroundImage"
                            required>
                            <option [value]="null"></option>
                            <option *ngFor="let logo of logoList" [value]="logo"> {{logo}} </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4 border-image">
                    <label class="d-flex sr-only justify-content-center">Selected Background Image</label>
                    <img [src]="application.backgroundImage" class="logo-image mx-auto d-block" alt="">
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">Font Size</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Font Size</div>
                        </div>
                        <input name="fontSize" [(ngModel)]="application.fontSize" type="text" class="form-control" />
                    </div>
                </div>
            </div>
            <label> Currency </label>
            <div class="form-row align-items-center">
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">Currency Type</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Currency Type</div>
                        </div>
                        <select class="form-control" name="locale" [(ngModel)]="application.locale" required>
                            <option></option>
                            <option>en-IN</option>
                            <option>en-US</option>
                            <option>th-TH</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">Currency Symbol</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Currency Symbol</div>
                        </div>
                        <input name="currencyCode" [(ngModel)]="application.currencyCode" type="text" class="form-control" />
                    </div>
                </div>
            </div>

            <div class="form-row align-items-center">
            <div class="col-md-12">
                <label class="external-css-editor-label" for="external-css-editor">External CSS</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">External Css</div>
                    </div>
                    <textarea class="form-control" name="externalCss"
                        [(ngModel)]="application.externalCss"></textarea>
                </div>
                <!-- <div id="external-css-editor" class="external-code-editor"></div> -->
            </div>
            </div>

            <div class="form-row align-items-center">
                <div class="col-md-6 offset-md-6">
                    <button type="submit" [disabled]="!submitToggle" class="btn btn-primary mb-2 mt-1">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="page-title">
    <label>Add Logo</label>
</div>
<div class="page-content">
    <div class="new-form">
        <form #logoForm="ngForm">
            <!-- <div class="form-row align-items-center">
                <div class="col-auto">
                    <label class="sr-only" for="inlineFormInput2">URL</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">URL</div>
                        </div>
                        <input name="URL" [(ngModel)]="logoUpload.URL" type="text" class="form-control" required />
                    </div>
                </div>
                <div class="col-auto">
                    <button type="button" class="btn btn-primary mb-2">ADD</button>
                </div>
            </div>
            <div class="col-md-1 offset-md-1 pt-2">
                <h6>OR</h6>
            </div> -->
            <div class="form-row align-items-center">
                <div class="col-auto">
                    <div class=" input-group input-file invoiceUploadRow pt-3">
                        <input type="file" class="form-control" accept="image/*" placeholder='Choose a file...'
                            name="imagePath" #imagePath="ngModel" [(ngModel)]="logoUpload.imagePath"
                            (change)="onChange($event)" required />
                        <span class="input-group-btn">
                            <button class="btn btn-primary mb-2" (click)="onUploadFile()" type="button">Upload</button>
                        </span>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
