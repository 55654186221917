import { Component, OnInit } from '@angular/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { NgForm } from '@angular/forms';
import { RWDataSource, RWDataSourceResponseType } from 'src/app/classes/RWDataSource';
import { RWDataSourceHttpConfiguration } from 'src/app/classes/RWDataSourceHttpConfiguration';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from 'rxjs';


@Component({
  selector: 'app-new-data-source',
  templateUrl: './new-data-source.component.html',
  styleUrls: ['./new-data-source.component.scss']
})
export class NewDataSourceComponent implements OnInit {

  model = {
    code: undefined,
    name: undefined,
    statusCode: undefined,
    validationExp: undefined,
    msgProperty: undefined,
    errorMsg: undefined,
    showpopup: undefined,
    scheme: undefined,
    host: undefined,
    path: undefined,
    method: undefined,
    queryParams: undefined,
    defaultBody: undefined,
    defaultHeader: undefined,
    responseType: undefined,
    fileName: undefined
  };
  titleToggle: boolean = false;
  code: string;
  isErrorLoading = false;
  errorMessage = null;
  editingId: string = undefined;
  isCopying = false;
  fileNameToggle:boolean = false;

  constructor(private dataProviderService: DataProviderService, private route: ActivatedRoute, private router: Router) { }
  ngOnInit(): void {
    this.editingId = this.route.snapshot.params['id'];
    if (this.route.snapshot.data['copy']) {
      this.isCopying = true;
    }
    if (this.editingId === undefined) {
      this.titleToggle = true;
      this.model.responseType = RWDataSourceResponseType.raw;
      this.loadData(this.editingId)
    } else {
      this.titleToggle = false;
      this.loadData(this.editingId)
    }
  }

  submit(event: any, form: NgForm) {
    if (form.invalid) {
      return;
    }
    const dataSource = new RWDataSource();
    dataSource.id = this.editingId;
    dataSource.code = this.model.code;
    dataSource.name = this.model.name;
    dataSource.expectedStatusCode = this.model.statusCode;
    dataSource.validationExpression = this.model.validationExp;
    dataSource.validationErrorMessageProperty = this.model.msgProperty;
    dataSource.validationErrorMessageRaw = this.model.errorMsg;
    dataSource.showPopupOnError = this.model.showpopup === undefined ? false : this.model.showpopup;
    dataSource.responseType = this.model.responseType === undefined || this.model.responseType === '' ? 'raw' : this.model.responseType;
    dataSource.fileName = this.model.fileName;
    const http = new RWDataSourceHttpConfiguration();
    http.scheme = this.model.scheme;
    http.path = this.model.path;
    http.method = this.model.method;
    http.host = this.model.host;
    http.defaultQueryParams = this.model.queryParams;
    http.defaultHeaders = this.model.defaultHeader;
    http.defaultBody = this.model.defaultBody;
    dataSource.http = http
    if (this.editingId && !this.isCopying) {
      this.dataProviderService.updateDataSource(dataSource, this.editingId).then(done => {
        alert('Updated DataSource');
        this.router.navigate(['/data-sources']);
      }, error => {
        alert('Unable to update datasource ' + error);
      });

    } else {
      this.dataProviderService.addDataSource(dataSource).then(done => {
        let text = this.isCopying ? 'Copied' : 'Created'
        alert('DataSource ' + text);
        this.router.navigate(['/data-sources']);
        form.reset();
      }, error => {
        alert('Unable to create datasource ' + error);
      });
    }
  }

  loadData(id: string) {
    this.dataProviderService.getDataSourceConfig(id).then(dsConfig => {
      console.log("this.isCopyingthis.isCopying", this.isCopying,dsConfig)
      if (!this.isCopying) {
        this.model.code = dsConfig.code;
        this.model.name = dsConfig.name;
      }
      this.model.statusCode = dsConfig.expectedStatusCode;
      this.model.validationExp = dsConfig.validationExpression;
      this.model.msgProperty = dsConfig.validationErrorMessageProperty;
      this.model.errorMsg = dsConfig.validationErrorMessageRaw;
      this.model.showpopup = dsConfig.showPopupOnError;
      this.model.scheme = dsConfig.http.scheme;
      this.model.host = dsConfig.http.host;
      this.model.path = dsConfig.http.path;
      this.model.method = dsConfig.http.method;
      this.model.queryParams = dsConfig.http.defaultQueryParams;
      this.model.defaultHeader = dsConfig.http.defaultHeaders;
      this.model.defaultBody = dsConfig.http.defaultBody;
      this.model.responseType = dsConfig.responseType;
      this.model.fileName = dsConfig.fileName;
      if(this.model.responseType === RWDataSourceResponseType.blob) {
        this.fileNameToggle = true;
      } else {
        this.fileNameToggle = false;
      }
    }, error => {
      this.isErrorLoading = true;
      this.errorMessage = 'Unable to retrieve the datasources: ' + error;
    });
  }

  onResponseTypeChange(responseType: string) {
    if(responseType === RWDataSourceResponseType.blob) {
      this.fileNameToggle = true;
    } else {
      this.fileNameToggle = false;
      this.model.fileName = ''
    }
  }
}