import { RWElement } from './RWElement';
import { ApplicationConfig } from './ApplicationConfig';
import { ApplicationPageConfig } from './ApplicationPageConfig';
import { ApplicationMenuConfig } from './ApplicationMenuConfig';
import { RWAction } from './RWAction';
import { RWEvent } from './RWEvent';
import { RWActionProperty } from './RWActionProperty';
import { RWElementTableHeader } from './RWTableHeader';
import { RWDataSource, RWDataSourceResponseType } from './RWDataSource';
import { RWElementBaseDataSource } from './RWElementBaseDataSource';
import { RWElementOptionsDataSource } from './RWElementOptionsDataSource';
import { RWElementTableHeaderAction } from './RWElementTableHeaderAction';
import { RWElementTableHeaderClickableAction } from './RWElementTableHeaderClickableAction';
import { RWTableConfig } from './RWTableConfig';
import { RWPayLoadDataSource } from './RWPayLoadDataSource';
import { RWValidations } from './RWValidations';
import { AuditInfo } from './AuditInfo';

export class RWElementParser {
  static jsonToApplicationConfig(jsonObject: any): ApplicationConfig {
    const config = new ApplicationConfig();
    config.id = jsonObject['id'];
    config.code = jsonObject['code'];
    config.title = jsonObject['title'];
    config.name = jsonObject['name'];
    config.url = jsonObject['url'];
    config.description = jsonObject['description'];
    config.externalCss = jsonObject['externalCss'];
    config.favicon = jsonObject['favicon'];
    config.footerText = jsonObject['footerText'];
    config.backgroundImage = jsonObject['backgroundImage'];
    config.logo = jsonObject['logo'];
    config.fontSize = jsonObject['fontSize'];
    config.locale = jsonObject['locale'];
    config.currencyCode = jsonObject['currencyCode'];
    if (jsonObject['audit'] !== undefined && jsonObject['audit'] !== null) {
      config.audit = RWElementParser.jsonToAuditInfo(jsonObject['audit']);
    }

    if (jsonObject['pages'] !== undefined && jsonObject['pages'] !== null) {
      for (const page of jsonObject['pages']) {
        config.pages.push(RWElementParser.jsonToPageConfig(page));
      }
    }
    if (jsonObject['menu'] !== undefined && jsonObject['menu'] !== null) {
      for (const menu of jsonObject['menu']) {
        config.menu.push(RWElementParser.jsonToMenuConfig(menu));
      }
    }
    if (jsonObject['dataSources'] !== undefined && jsonObject['dataSources'] !== null) {
      for (const dataSource of jsonObject['dataSources']) {
        config.dataSources.push(RWElementParser.jsonToRWDataSource(dataSource));
      }
    }
    return config;
  }

  static jsonToAuditInfo(jsonObject: any): AuditInfo {
    let info = new AuditInfo();
    if (jsonObject === undefined || jsonObject === null) {
      return info;
    }
    info.createdAt = jsonObject['createdAt'];
    info.createdById = jsonObject['createdById'];
    info.createdByName = jsonObject['createdByName'];
    info.deletedAt = jsonObject['deletedAt'];
    info.deletedById = jsonObject['deletedById'];
    info.deletedByName = jsonObject['deletedByName'];
    info.lastModifiedAt = jsonObject['lastModifiedAt'];
    info.lastModifiedById = jsonObject['lastModifiedById'];
    info.lastModifiedByName = jsonObject['lastModifiedByName'];
    return info;
  }

  static jsonToPageConfig(jsonObject: any): ApplicationPageConfig {
    const config = new ApplicationPageConfig();
    config.id = jsonObject['id'];
    config.code = jsonObject['code'];
    config.name = jsonObject['name'];
    config.version = jsonObject['version'];
    config.title = jsonObject['title'];
    config.layout = jsonObject['layout'];
    if (jsonObject['audit'] !== undefined && jsonObject['audit'] !== null) {
      config.audit = RWElementParser.jsonToAuditInfo(jsonObject['audit']);
    }
    for (const element of jsonObject['designData']) {
      config.designData.push(RWElementParser.jsonToLayoutElement(element));
    }
    return config;
  }

  static jsonToRWDataSource(jsonObject: any): RWDataSource {
    const config = new RWDataSource();
    config.id = jsonObject['id'];
    config.code = jsonObject['code'];
    config.name = jsonObject['name'];
    config.type = jsonObject['type'];
    config.http = jsonObject['http'];
    config.expectedStatusCode = jsonObject['expectedStatusCode'];
    config.validationExpression = jsonObject['validationExpression'];
    config.validationErrorMessageProperty = jsonObject['validationErrorMessageProperty'];
    config.validationErrorMessageRaw = jsonObject['validationErrorMessageRaw'];
    config.showPopupOnError = jsonObject['showPopupOnError']
    if (jsonObject['audit'] !== undefined && jsonObject['audit'] !== null) {
      config.audit = RWElementParser.jsonToAuditInfo(jsonObject['audit']);
    }
     //TODO: Need to change this to allow multiple responseTypes in future
     config.responseType = jsonObject['responseType'] === 'blob' ? RWDataSourceResponseType.blob : RWDataSourceResponseType.raw;
     config.fileName = jsonObject['fileName'];
     return config;
  }

  static jsonToMenuConfig(jsonObject: any): ApplicationMenuConfig {
    const config = new ApplicationMenuConfig();
    config.id = jsonObject['id'];
    config.code = jsonObject['code'];
    config.title = jsonObject['title'];
    config.pageCode = jsonObject['pageCode'];
    config.navigationEnvironment = jsonObject['navigationEnvironment'];
    if (jsonObject['show'] === undefined || jsonObject['show'] === null) {
      config.show = true;
    } else {
      config.show = jsonObject['show'];
    }
    if (jsonObject['children'] !== undefined && jsonObject['children'] !== null) {
      for (const element of jsonObject['children']) {
        config.children.push(RWElementParser.jsonToMenuConfig(element));
      }
    }
    config.role = jsonObject['role'];
    if (jsonObject['audit'] !== undefined && jsonObject['audit'] !== null) {
      config.audit = RWElementParser.jsonToAuditInfo(jsonObject['audit']);
    }
    return config;
  }

  static jsonToLayoutElement(jsonObject: any): RWElement {
    const element = new RWElement();

    element.setClassesD(jsonObject['classes']);

    element.setIdD(jsonObject['id']);
    element.setAttributes(jsonObject['attributes']);
    element.setPayLoadKeyD(jsonObject['payLoadKey']);

    element.setElementType(jsonObject['elementType'])
    element.setControlType(jsonObject['controlType']);
    element.setControlOptions(jsonObject['controlOptions']);
    element.setContentTextD(jsonObject['contentText']);
    element.setVerticalAccordionHeaderTextD(jsonObject['verticalAccordionHeaderText']);
    element.setRoleD(jsonObject['role']);
    element.setRouterD(jsonObject['router']);
    element.setVoiceTextD(jsonObject['voiceText']);
    element.setDropDownTypeD(jsonObject['dropDownType']);
    element.setChildrenDataPropertyD(jsonObject['childrenDataProperty'])
    element.setLevel(jsonObject['level']);
    element.setEnableDynamicallyDuplicateD(jsonObject['enableDynamicallyDuplicate']);
    element.setEnableDynamicallyRemoveD(jsonObject['enableDynamicallyRemove']);
    element.setIsReadOnlyD(jsonObject['isReadOnly']);
    element.setIsDisabledOnlyD(jsonObject['isDisabled']);
    if (jsonObject['children'] !== undefined && jsonObject['children'] !== null) {
      for (const child of jsonObject['children']) {
        element.addChild(RWElementParser.jsonToLayoutElement(child));
      }
    }

    element.setNameD(jsonObject['name']);
    element.setFontSizeD(jsonObject['fontSize']);
    element.setTitleD(jsonObject['title']);
    element.setPlaceholderD(jsonObject['placeholder']);
    element.setRadioValueD(jsonObject['radioValue']);
    element.setCheckedD(jsonObject['checked']);
    element.setCheckboxValueD(jsonObject['checkboxValue']);
    element.setTypeD(jsonObject['type']);
    element.setMinD(jsonObject['min']);
    element.setMaxD(jsonObject['max']);
    element.setMinLengthD(jsonObject['minLength']);
    element.setMaxLengthD(jsonObject['maxLength']);
    element.setFormatD(jsonObject['format']);
    element.setOrderD(jsonObject['order']);
    if (jsonObject['options'] !== undefined && jsonObject['options'] !== null) {
      for (const child of jsonObject['options']) {
        element.addOption(child);
      }
    }
    element.setSourceD(jsonObject['src']);
    element.setHeightD(jsonObject['height']);
    element.setWidthD(jsonObject['width']);
    element.setHrefD(jsonObject['href']);
    // element.setAccordionHeaderText(jsonObject['accordionHeaderText']);
    if (jsonObject['events'] !== undefined && jsonObject['events'] !== null) {
      for (const event of jsonObject['events']) {
        if (event !== undefined && event !== null) {
          element.addEvent(RWElementParser.jsonToRWEvent(event));
        }
      }
    }

    if (jsonObject['tableHeaders'] !== undefined && jsonObject['tableHeaders'] !== null) {
      for (const header of jsonObject['tableHeaders']) {
        element.addHeader(RWElementParser.jsonToRWElementTableHeader(header))
      }
    }

    if (jsonObject['subTableHeaders'] !== undefined && jsonObject['subTableHeaders'] !== null) {
      for (const header of jsonObject['subTableHeaders']) {
        element.addSubHeader(RWElementParser.jsonToRWElementTableHeader(header))
      }
    }

    element.setValueDataSource(RWElementParser.jsonToRWElementBaseDataSource(jsonObject['valueDataSource']));
    element.setOptionsDataSource(RWElementParser.jsonToRWElementOptionsDataSource(jsonObject['optionsDataSource']));
    element.setPayLoadDataSource(RWElementParser.jsonToRWElementPayLoadDataSource(jsonObject['payLoadDataSource']));

    element.setFormContentTypeD(jsonObject['formContentType']);

    element.setTableConfig(RWElementParser.jsonToRWTableConfig(jsonObject['tableConfig']));
    element.setSubTableConfig(RWElementParser.jsonToRWSubTableConfig(jsonObject['subTableConfig']));

    element.setValidations(RWElementParser.jsonToRWElementValidations(jsonObject['validations']));

    element.setAnchorTypeD(jsonObject['anchorType']);

    element.setIsLoadingD(jsonObject['isLoading']);
    element.setFileTypeD(jsonObject['fileType'])


    return element;
  }

  static jsonToRWEvent(jsonObject: any): RWEvent {
    const event = new RWEvent(jsonObject['type']);
    if (jsonObject['action'] !== undefined && jsonObject['action'] !== null) {
      event.action = RWElementParser.jsonToRWAction(jsonObject['action']);
    }
    return event;
  }

  static jsonToRWAction(jsonObject: any): RWAction {
    const action = new RWAction(jsonObject['type']);
    action.properties = [];
    if (jsonObject['properties'] !== undefined && jsonObject['properties'] !== null) {
      for (const prop of jsonObject['properties']) {
        action.properties.push(RWElementParser.jsonToRWActionProperty(prop));
      }
    }
    return action;
  }

  static jsonToRWActionProperty(jsonObject: any): RWActionProperty {
    const property: RWActionProperty = new RWActionProperty(jsonObject['key'], jsonObject['type'], jsonObject['value']);
    return property;
  }

  static jsonToRWElementTableHeader(jsonObject: any): RWElementTableHeader {
    const tableproperty: RWElementTableHeader = new RWElementTableHeader(jsonObject['title'], jsonObject['dataProperty'], jsonObject['style'], jsonObject['show'], jsonObject['role'], jsonObject['dataType'], jsonObject['columnOrder']);
    if (jsonObject['clickableAction'] !== undefined && jsonObject['clickableAction'] !== null) {
      tableproperty.clickableAction = RWElementParser.jsonToRWAction(jsonObject['clickableAction']);
    }
    if (jsonObject['subClickableAction'] !== undefined && jsonObject['subClickableAction'] !== null) {
      tableproperty.subClickableAction = RWElementParser.jsonToRWAction(jsonObject['subClickableAction']);
    }

    if (jsonObject['actions'] === undefined || jsonObject['actions'] == null) {
      return tableproperty;
    }
    if (!Array.isArray(jsonObject['actions'])) {
      return tableproperty;
    }
    tableproperty.actions = [];
    for (const action of jsonObject['actions']) {
      tableproperty.actions.push(RWElementParser.jsonToRWTableHeaderAction(action));
    }
    return tableproperty;
  }

  static jsonToRWElementBaseDataSource(jsonObject: any): RWElementBaseDataSource {
    if (jsonObject === undefined) {
      return undefined;
    }
    const dataSource = new RWElementBaseDataSource();
    dataSource.dataSource = jsonObject['dataSource'];
    dataSource.inherited = jsonObject['inherited'];
    dataSource.property = jsonObject['property'];
    dataSource.shouldUseDataSourceResult = jsonObject['shouldUseDataSourceResult'];
    return dataSource;
  }

  static jsonToRWElementOptionsDataSource(jsonObject: any): RWElementOptionsDataSource {
    if (jsonObject === undefined) {
      return undefined;
    }
    const dataSource = new RWElementOptionsDataSource();
    dataSource.dataSource = jsonObject['dataSource'];
    dataSource.inherited = jsonObject['inherited'];
    dataSource.property = jsonObject['property'];
    dataSource.shouldUseDataSourceResult = jsonObject['shouldUseDataSourceResult'];
    dataSource.keyProperty = jsonObject['keyProperty'];
    dataSource.valueProperty = jsonObject['valueProperty'];
    return dataSource;
  }

  static jsonToRWTableHeaderAction(jsonObject: any): RWElementTableHeaderAction {
    if (jsonObject === undefined || jsonObject === null) {
      return null;
    }
    return new RWElementTableHeaderAction(jsonObject['text'], jsonObject['title'], RWElementParser.jsonToRWAction(jsonObject['action']));
  }

  static jsonToRWSubTableHeaderAction(jsonObject: any): RWElementTableHeaderAction {
    if (jsonObject === undefined || jsonObject === null) {
      return null;
    }
    return new RWElementTableHeaderAction(jsonObject['text'], jsonObject['title'], RWElementParser.jsonToRWAction(jsonObject['action']));
  }

  static jsonToRWTableHeaderClickableAction(jsonObject: any): RWElementTableHeaderClickableAction {
    if (jsonObject === undefined || jsonObject === null) {
      return null;
    }
    return new RWElementTableHeaderClickableAction(jsonObject['dataProperty'], RWElementParser.jsonToRWAction(jsonObject['action']));
  }

  static jsonToRWTableConfig(jsonObject: any): RWTableConfig {
    if (jsonObject === undefined) {
      return undefined;
    }
    const tableConfig = new RWTableConfig();
    tableConfig.columnVisibilityDropDown = jsonObject['columnVisibilityDropDown'];
    tableConfig.commonSearch = jsonObject['commonSearch'];
    tableConfig.excelExportButton = jsonObject['excelExportButton'];
    tableConfig.excelExportButtonText = jsonObject['excelExportButtonText'];
    tableConfig.excelExportFileName = jsonObject['excelExportFileName'];
    tableConfig.showExpandAllArrows = jsonObject['showExpandAllArrows'];
    tableConfig.showExpandArrows = jsonObject['showExpandArrows'];
    tableConfig.showPageLengthDropdown = jsonObject['showPageLengthDropdown'];
    tableConfig.showTableHeaders = jsonObject['showTableHeaders'];
    tableConfig.visibleColumnFiltersVisibility = jsonObject['visibleColumnFiltersVisibility'];
    return tableConfig;
  }

  static jsonToRWSubTableConfig(jsonObject: any): RWTableConfig {
    if (jsonObject === undefined) {
      return undefined;
    }
    const subTableConfig = new RWTableConfig();
    subTableConfig.columnVisibilityDropDown = jsonObject['columnVisibilityDropDown'];
    subTableConfig.commonSearch = jsonObject['commonSearch'];
    subTableConfig.excelExportButton = jsonObject['excelExportButton'];
    subTableConfig.excelExportButtonText = jsonObject['excelExportButtonText'];
    subTableConfig.excelExportFileName = jsonObject['excelExportFileName'];
    subTableConfig.showExpandAllArrows = jsonObject['showExpandAllArrows'];
    subTableConfig.showExpandArrows = jsonObject['showExpandArrows'];
    subTableConfig.showPageLengthDropdown = jsonObject['showPageLengthDropdown'];
    subTableConfig.showTableHeaders = jsonObject['showTableHeaders'];
    subTableConfig.visibleColumnFiltersVisibility = jsonObject['visibleColumnFiltersVisibility'];
    return subTableConfig;
  }

  static jsonToRWElementPayLoadDataSource(jsonObject: any): RWPayLoadDataSource {
    if (jsonObject === undefined) {
      return undefined;
    }
    const payLoadDataSource = new RWPayLoadDataSource();
    payLoadDataSource.payLoadDataSourceName = jsonObject['payLoadDataSourceName'];
    payLoadDataSource.payLoadProperty = jsonObject['payLoadProperty'];

    return payLoadDataSource;
  }

  static jsonToRWElementValidations(jsonObject: any): RWValidations {
    if (jsonObject === undefined) {
      return undefined;
    }
    const validations = new RWValidations();
    validations.requiredMessage = jsonObject['requiredMessage'];
    validations.required = jsonObject['required'];
    validations.requiredClass = jsonObject['requiredClass'];
    validations.regexRequired = jsonObject['regexRequired'];
    validations.regexPattern = jsonObject['regexPattern'];

    return validations;
  }

}
