import { Injectable } from '@angular/core';
import { RWElement } from '../classes/RWElement';

@Injectable({
  providedIn: 'root'
})
export class FileUploadDataProviderService {

  private files: { [key: string]: File[] } = {};

  constructor() { }

  add(elementData: RWElement, fileList: FileList) {
    this.files = {}
    if (this.files[elementData.getId()] === undefined) {
      this.files[elementData.getId()] = [];
    }
    for (let i = 0; i < fileList.length; i++) {
      this.files[elementData.getId()].push(fileList.item(i));
    }
  }

  get(id: string): File[] | undefined {
    return this.files[id];
  }

}
