import { Component, OnInit } from '@angular/core';
import { TreeTableData, TreeTableHeaderObject, TreeTableRow, TtDataType, TreeTableRowAction } from 'angular-tree-table';
import { RWDataSource } from 'src/app/classes/RWDataSource';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ApplicationConfig } from 'src/app/classes/ApplicationConfig';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-sources',
  templateUrl: './data-sources.component.html',
  styleUrls: ['./data-sources.component.scss']
})
export class DataSourcesComponent implements OnInit {
  tableConfig: any = {
    context: this,
    showExpandArrows: false,
    showExpandAllArrows: false,
    excelExportButton: true,
    excelExportFileName: 'Menu',
    excelExportButtonText: 'Excel'
  };

  tableData: TreeTableData;
  tableHeaders: TreeTableHeaderObject[] = [];
  dataSource: RWDataSource[] = [];

  constructor(private dataProviderService: DataProviderService, private router: Router) { }
  ngOnInit(): void {
    this.tableData = new TreeTableData(this.tableConfig);
    this.tableInit();
    this.loadData();
  }

  loadData() {
    const rows = [];
    this.tableData.isLoading = true;
    this.dataProviderService.getAppConfig().then((appConfig: ApplicationConfig) => {
      this.tableData.isLoading = false;
      this.dataSource.splice(0, this.dataSource.length);
      appConfig.dataSources.forEach(v => {
        this.dataSource.push(v);
      })
      for (const [index, dataSource] of Object.entries(appConfig.dataSources)) {
        dataSource['sno'] = parseInt(index) + 1;
        dataSource['code'] = dataSource.code;
        dataSource['name'] = dataSource.name;
        dataSource['expectedStatusCode'] = dataSource.expectedStatusCode;
        const row = new TreeTableRow('', dataSource, false, null);
        const latestCopyAction = new TreeTableRowAction('Copy', 'Copy Data Source', 'btn btn-sm btn-secondary', this.latestCopyDataSourceClicked);
        latestCopyAction.context = this;
        row.actions.push(latestCopyAction);
        const editAction = new TreeTableRowAction('Edit', 'Edit', 'btn btn-sm btn-secondary', this.editDataSource);
        editAction.context = this;
        row.actions.push(editAction);
        const deleteAction = new TreeTableRowAction('Delete', 'Delete Data Source '+ dataSource.name , 'btn btn-sm btn-danger', this.deleteDataSourceClicked);
        deleteAction.context = this;
        row.actions.push(deleteAction);
        rows.push(row);
      }
      this.tableData.data = rows;
    });
  }

  latestCopyDataSourceClicked(data: any) {
    this.router.navigate(['/data-sources/copy/' + data['id']]);
  }

  editDataSource(data: any) {
    this.router.navigate(['/data-sources/edit/' + data['id']]);
  }
  
  deleteDataSourceClicked(dataSource: any) {
    let result = confirm('Are you sure, you want delete '+dataSource.name);
    if(result){
    this.dataProviderService.deleteDataSource(dataSource).then(done => {
      alert('Data Source Deleted');
      this.loadData();
    }, error => {
      alert('Unable to create page ' + error);
    });
  }
  }
  tableInit() {
    this.tableHeaders.splice(0, this.tableHeaders.length);
    const header = new TreeTableHeaderObject('S.No', 'sno', null, true);
    header.dataType = TtDataType.NUMBER;
    this.tableHeaders.push(header);
    this.tableHeaders.push(new TreeTableHeaderObject('Code', 'code', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Name', 'name', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('StatusCode', 'expectedStatusCode', null, true));
    const actions = new TreeTableHeaderObject('Actions', '', null, true);
    actions.dataType = TtDataType.ACTIONS;
    this.tableHeaders.push(actions);
    this.tableData.headers = this.tableHeaders;
  }
}