import { ApplicationConfig } from '../classes/ApplicationConfig';
import { ApplicationPageConfig } from '../classes/ApplicationPageConfig';
import { ApplicationMenuConfig } from '../classes/ApplicationMenuConfig';
import * as moment from 'moment';

export class Util {

  static getValueFromObjectByPropertyPath(object: any, propertyPath: string) {
    if (object === undefined || object === null) {
      return '';
    }
    const propertyPaths = propertyPath.split('.');
    let currentObject = object;
    for (let i = 0; i < propertyPaths.length; i++) {
      if (currentObject === undefined || currentObject === null) {
        return '';
      }
      if (propertyPaths[i].indexOf('[') > -1 && propertyPaths[i].indexOf(']') > -1) {
        const parts = propertyPaths[i].split('[');
        const parts2 = parts[1].split(']');
        const index = parts2[0];
        if (Array.isArray(currentObject[parts[0]])) {
          if (currentObject[parts[0]][parseInt(index)] === undefined) {
            return '';
          }
          currentObject = currentObject[parts[0]][parseInt(index)];
        } else {
          return '';
        }
      } else {
        currentObject = currentObject[propertyPaths[i]];
      }
    }
    return currentObject;
  }

  static testPath(syntax: string, value: string) {
    // console.log('testPath inputs', syntax, value);
    // Step 1
    if (syntax.indexOf(':') === -1) {
      return syntax === value;
    }

    // Step 2
    const syntaxParts = syntax.split('/');
    const valueParts = value.split('/');
    if (syntaxParts.length !== valueParts.length) {
      return false;
    }

    // Step 3
    for (let i = 0; i < syntaxParts.length; i++) {
      const syntaxPartIsVariable = syntaxParts[i].indexOf(':') > -1;
      if (syntaxPartIsVariable) {
        const valuePartIsVariable = valueParts[i].indexOf(':') > -1;
        if (valuePartIsVariable) {
          return false;
        }
        continue;
      }
      if (syntaxParts[i] !== valueParts[i]) {
        return false;
      }
    }

    // Step 4
    return true;
  }

  static getPage(path: string, appConfig: ApplicationConfig): { page: ApplicationPageConfig, menu: ApplicationMenuConfig } {
    if (appConfig === undefined || appConfig === null) {
      console.warn('AppConfig not loaded');
      return;
    }
    const menu = appConfig.menu.find(v => {
      return Util.testPath(v.code, '/' + path);
    });
    if (menu !== undefined && menu !== null) {
      if (menu.role === undefined || menu.role === null) {
        menu.role = '';
      }
      const pageCode = menu.pageCode.split('^')[0];
      const pageVersion = menu.pageCode.split('^')[1];
      const page = appConfig.pages.find(v => v.code === pageCode && v.version === parseInt(pageVersion));
      return { page, menu };
    }
  }

  static executeExpression(expression, data, currency?: any) {
    let result = undefined;
    console.log('expression ', expression);
    if (expression.indexOf(' - ') > -1) {
      const expressionParts = expression.split(' - ');
      for (let [index, expressionPart] of expressionParts.entries()) {
        expressionParts[index] = Util.executeExpression(expressionPart, data);
      }
      expressionParts.forEach(v => {
        if (result === undefined) {
          result = v;
        } else {
          result = result - parseFloat(v);
        }
      });
      console.log('expression - result', result, expressionParts);
      return result;
    } else if (expression.indexOf(' + ') > -1) {
      const expressionParts = expression.split(' + ');
      for (let [index, expressionPart] of expressionParts.entries()) {
        expressionParts[index] = Util.executeExpression(expressionPart, data);
      }
      expressionParts.forEach(v => {
        if (result === undefined) {
          result = v;
        } else {
          result = result + parseFloat(v);
        }
      });
      console.log('expression + result', result, expressionParts);
      return result;
    } else if (expression.indexOf(' * ') > -1) {
      const expressionParts = expression.split(' * ');
      for (let [index, expressionPart] of expressionParts.entries()) {
        expressionParts[index] = Util.executeExpression(expressionPart, data);
      }
      expressionParts.forEach(v => {
        if (result === undefined) {
          result = v;
        } else {
          result = result * parseFloat(v);
        }
      });
      console.log('expression * result', result, expressionParts);
      return result;
    } else if (expression.indexOf(' / ') > -1) {
      const expressionParts = expression.split(' / ');
      for (let [index, expressionPart] of expressionParts.entries()) {
        expressionParts[index] = Util.executeExpression(expressionPart, data);
      }
      expressionParts.forEach(v => {
        if (result === undefined) {
          result = v;
        } else {
          result = result / parseFloat(v);
        }
      });
      console.log('expression / result', result, expressionParts);
      return result;
    } else {
      return Util.getValueWithPathFromObject(expression, data, currency);
    }
  }

  /**
   * {
   *    "PO_NUMBER": "123456",
   *    "PO_TAX": [{
   *        "SGST": 15
   *    }]
   * }
   * 
   * PO_TAX.SGST
   * PO_TAX[0].SGST
   * PO_TAX[0].SGST + PO_TAX[0].CGST
   * =CONCAT('SGST: '|||PO_TAX[0].SGST + PO_TAX[0].CGST|||'\r\n')
   */
  static getValueWithPathFromObject(path: string, data: any, currency?: any) {
    // $VA: Old Version support - which will automatically detect the data type
    if (!path.startsWith('$SS:') && !path.startsWith('$VS:') && !path.startsWith('$VD:') && !path.startsWith('$VA:') && !path.startsWith('$VC:') && !path.startsWith('$VND:') && !path.startsWith('$VN:')) {
      path = '$VA:' + path;
    }
    if (path.startsWith('$SS:')) {
      return path.replace('$SS:', '');
    }
    let partParts = path.split(':');
    let partVariableType = partParts[0];
    let partVariable = partParts[1];
    if (partVariableType === '$VA') {
      partVariable = path.replace('$VA:', '');
    }

    const pathParts = partVariable.split('.');
    let result = data;
    for (let [index, part] of pathParts.entries()) {
      if (part.endsWith(']')) {
        const subParts = part.split('[');
        const arrayProperty = subParts[0];
        if (result[arrayProperty] === undefined || result[arrayProperty] === null || !Array.isArray(result[arrayProperty])) {
          return '';
        }
        const arrayIndex = parseInt(subParts[1].replace(']', ''));
        if (isNaN(arrayIndex)) {
          return '#ERR: NaN';
        }
        result = result[arrayProperty][arrayIndex];
      } else {
        if (result === undefined || result === null) {
          return '';
        }
        if (part === ' ') {
          return ' ';
        }
        if (result[part] === undefined) {
          if (partVariableType === '$VA') {
            return part === partVariable ? part : ''
          } else {
            return '';
          }
        }
        if (partVariableType === '$VD') {
          if (pathParts.length - 1 === index) {
            let rawDate = result[part];
            let dateFormat = partParts[2];
            if (dateFormat === undefined || dateFormat === null || dateFormat === '') {
              dateFormat = 'DD-MMM-YYYY';
            }
            if (typeof rawDate === 'string') {
              rawDate = moment(rawDate);
            }
            result = moment(rawDate).format(dateFormat);
          } else {
            result = result[part];
          }
        } else if (partVariableType === '$VC') {
          if (pathParts.length - 1 === index) {
            let rawAmount = result[part];
            if (rawAmount === undefined) {
              rawAmount = 0;
            }
            let currencyOptional = {
              style: "currency",
              currency: currency.currencyCode,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
            result = new Number(rawAmount).toLocaleString(currency.locale, currencyOptional);
          } else {
            result = result[part];
          }
        } else if (partVariableType === '$VND') {
          if (pathParts.length - 1 === index) {
            let rawAmount = result[part];
            let decimalCount = parseInt(partParts[2]);
            if (decimalCount === undefined || decimalCount === null || isNaN(decimalCount)) {
              decimalCount = 2;
            }
            if (rawAmount === undefined || isNaN(rawAmount)) {
              rawAmount = 0;
            }
            let currencyOptional = {
              minimumFractionDigits: decimalCount,
              maximumFractionDigits: decimalCount
            }
            result = new Number(rawAmount).toLocaleString(currency.locale, currencyOptional);
          } else {
            result = result[part];
          }
        } else if (partVariableType === '$VN') {
          if (pathParts.length - 1 === index) {
            let actualAmount = result[part];
            if (actualAmount === undefined || isNaN(actualAmount) || actualAmount === null) {
              actualAmount = 0;
            }
            let currentAmount = parseInt(result[part]);
            let originalAmount = actualAmount - currentAmount;
            if (originalAmount > 0) {
              actualAmount = parseFloat(actualAmount);
            } else {
              actualAmount = parseInt(actualAmount);
            }
            result = actualAmount
          } else {
            result = result[part];
          }
        } else {
          result = result[part];
        }
      }
    }
    return result;
  }

  static evaluateConcat(expression, data, currency?: any) {
    if (expression.startsWith('=CONCAT(') && expression.endsWith(')')) {
      expression = expression.replace('=CONCAT(', '');
      expression = expression.substring(0, expression.length - 1);
      const expressionParts = expression.split('|||');
      let result = '';
      expressionParts.forEach(v => {
        result += '' + Util.executeExpression(v, data);
      });
      return result;
    } else {
      return Util.executeExpression(expression, data, currency);
    }
  }



  static updateObjectWithPath(object: any, path: string, value: any) {
    var stack = path.split('.');
    while (stack.length > 1) {
      object = object[stack.shift()];
    }
    object[stack.shift()] = value;

  }
}















