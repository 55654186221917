export class RWDataSourceHttpConfiguration {
    scheme: string;
    host: string;
    port: number;
    path: string;
    method:string;
    defaultQueryParams: any;
    defaultBody: any;
    defaultHeaders: any;
    defaultPathParams: any;
}

