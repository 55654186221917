<div class="page-title">
    <label class="page-heading" *ngIf="titleToggle">New Data Source</label>
    <label class="page-heading"*ngIf="!titleToggle">Edit Data Source</label>
    <div class="page-actions">
        <a class="btn btn-sm btn-secondary" routerLink="/data-sources">Back</a>
    </div>
</div>
<div class="page-content">
    <div class="new-form">
        <form #newForm="ngForm" (ngSubmit)="submit($event, newForm)">
            <label> Data Source </label>
            <div class="form-row align-items-center">
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">Code</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Code</div>
                        </div>
                        <input name="Code" [(ngModel)]="model.code" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Code" required />
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Name</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Name</div>
                        </div>
                        <input name="name" [(ngModel)]="model.name" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Name" required />
                    </div>
                </div>

                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">statusCode</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">statusCode</div>
                        </div>
                        <input name="statusCode" [(ngModel)]="model.statusCode" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Status Code" required />
                    </div>
                </div>
            </div>
            <div class="form-row align-items-center">
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Validation Expression</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Validation Expression</div>
                        </div>
                        <input name="validationExp" [(ngModel)]="model.validationExp" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Validation Expression" required />
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Message Property</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Message Property</div>
                        </div>
                        <input name="msgProperty" [(ngModel)]="model.msgProperty" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Message Property" required />
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Error Message</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Error Message</div>
                        </div>
                        <input name="errorMsg" [(ngModel)]="model.errorMsg" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Error Message" required />
                    </div>
                </div>
            </div>
            <div class="form-row align-items-center">
                <div class="col-sm-10">
                    <div class="form-check">
                        <input class="form-check-input" name="showpopup" [(ngModel)]="model.showpopup" type="checkbox"
                            id="gridCheck1">
                        <label class="form-check-label" for="gridCheck1">
                            Show PopUp
                        </label>
                    </div>
                </div>
            </div>
            
            <label>Http Configuration</label>
            <div class="form-row align-items-center">
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">Scheme</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Scheme</div>
                        </div>
                        <input name="scheme" [(ngModel)]="model.scheme" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Scheme" required />
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Host</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Host</div>
                        </div>
                        <input name="host" [(ngModel)]="model.host" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Host" required />
                    </div>
                </div>

                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Path</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Path</div>
                        </div>
                        <input name="path" [(ngModel)]="model.path" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Path" required />
                    </div>
                </div>
            </div>
            <div class="form-row align-items-center">
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">Method</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Method</div>
                        </div>
                        <select class="form-control" name="method" [(ngModel)]="model.method" required>
                            <option></option>
                            <option>GET</option>
                            <option>POST</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Query Params</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Query Params</div>
                        </div>
                        <textarea name="queryParams" [(ngModel)]="model.queryParams" type="text" class="form-control"
                        id="inlineFormInputGroup" placeholder="Query Params"></textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Default Body</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Default Body</div>
                        </div>
                        <textarea  name="defaultBody" [(ngModel)]="model.defaultBody" type="text" class="form-control"
                        id="inlineFormInputGroup" placeholder="Default Body" ></textarea>
                    </div>
                </div>
            </div>
            <div class="form-row align-items-center">
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Default Headers</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Default Headers</div>
                        </div>
                        <input name="defaultHeader" [(ngModel)]="model.defaultHeader" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Default Header"  />
                    </div>
                </div>

                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">ResponseType</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">ResponseType</div>
                        </div>
                        <select class="form-control" name="responseType" [(ngModel)]="model.responseType" (ngModelChange)="onResponseTypeChange($event)">
                            <option></option>
                            <option>raw</option>
                            <option>blob</option>
                        </select>
                    </div>
                </div>
               <ng-container *ngIf="fileNameToggle">
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">FileName</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">FileName</div>
                        </div>
                        <input name="fileName" [(ngModel)]="model.fileName" type="text" class="form-control"
                        id="inlineFormInputGroup" placeholder="File Name"  />
                    </div>
                </div>
            </ng-container>
            </div>
            <div class="form-row align-items-center">
                <div class="col-md-6 offset-md-6">
                    <button type="submit" class="btn btn-primary mb-2">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>