import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RWElement } from '../../../classes/RWElement';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlProviderService } from 'src/app/services/control-provider.service';
import { ApplicationPageConfig } from '../../../classes/ApplicationPageConfig';
import { RWElementRendererDisplayMode } from "src/app/classes/RWElementRendererDisplayMode";
import { ElementPropertyService } from 'src/app/services/element-property.service';
import { ActionExecutorService } from 'src/app/services/action-executor.service';
import { Util } from 'src/app/util/util';
import { report } from 'process';
import { RWElementParser } from 'src/app/classes/RWElementParser';

declare var $: any;

@Component({
  selector: 'app-new-page',
  templateUrl: './new-page.component.html',
  styleUrls: ['./new-page.component.scss']
})
export class NewPageComponent implements OnInit, OnDestroy {

  model = { code: undefined, name: undefined, title: undefined, designData: new RWElement(), popupDesignData: new RWElement(), popupTitle: 'Modal', popupContextData: undefined };
  isEditing = false;
  isCopying = false;
  editingCode = null;
  editingVersion = null;
  isErrorLoading = false;
  errorMessage = null;
  displayMode: RWElementRendererDisplayMode = RWElementRendererDisplayMode.DESIGN;
  scrollListener: any = undefined;
  resizeListener: any = undefined;
  categoryToggleListener: any = undefined;
  editingId: string = undefined;
  showPropertiesBox = true;
  showToolbox = true;
  showPopup: { path: string, size: string, popupContextData: string } = undefined;

  controls: RWElement[] = [];
  categorizedControls: { [key: string]: RWElement[] } = {};
  categories: string[] = [];
  searchValue: string;
  filteredControls = [];
  pageVersions: number[] = [];
  payLoadData = {};
  payLoadDataArray = {};
  payLoadValidationObject = {};
  payLoadRWElementObject  = {};
  currentRwElements: RWElement[] = [];



  constructor(private dataProviderService: DataProviderService,
    private controlProviderService: ControlProviderService,
    private route: ActivatedRoute, private actionExecuteService: ActionExecutorService,
    private router: Router, private elementPropertyService: ElementPropertyService) { }

  ngOnInit(): void {
    if (this.route.snapshot.params['id'] !== undefined && this.route.snapshot.params['id'] !== null) {
      this.isEditing = true;
      this.editingId = this.route.snapshot.params['id'];
    }
    if (this.route.snapshot.data['copy']) {
      this.isCopying = true;
    }
    // this.route.params.subscribe(params => {
    //   console.log("helloooo")
    //   this.editingVersion = this.route.snapshot.params['version'];
    //   this.loadData(this.editingCode, this.editingVersion);
    // })
    if (this.route.snapshot.queryParams['mode'] !== undefined && this.route.snapshot.queryParams['mode'] !== null && this.route.snapshot.queryParams['mode'] !== '') {
      if (this.route.snapshot.queryParams['mode'] === 'PREVIEW') {
        this.displayMode = RWElementRendererDisplayMode.VIEW
      }
    }
    if (!this.isEditing) {
      this.setNewProperties();
      this.loadData(this.editingId);
    } else {
      this.loadData(this.editingId);
    }
    this.loadControls();
    this.fixToolBoxPosition();
    this.registerScrollListener();
    this.elementPropertyService.showPopupSettingsChanged.subscribe(resp => {
      this.showPopup = resp;
      console.log(" this.showPopup", this.showPopup)
      // $('#modal-design1').modal('show');
      this.dataProviderService.getAppConfig().then(config => {
        if (this.showPopup.path === undefined || this.showPopup.path === null) {
          return;
        }
        let pageMenu = Util.getPage(this.showPopup.path, config);

        if (this.showPopup !== undefined) {
          if (pageMenu !== undefined && pageMenu.page !== undefined) {
            this.model.popupTitle = pageMenu.menu.title;
            this.model.popupDesignData = pageMenu.page.designData[0];
            this.model.popupContextData = this.showPopup.popupContextData;
          }
        }
        $(".modal-header").on("mousedown", function (mousedownEvt) {
          var $draggable = $(this);
          var x = mousedownEvt.pageX - $draggable.offset().left,
            y = mousedownEvt.pageY - $draggable.offset().top;
          $("body").on("mousemove.draggable", function (mousemoveEvt) {
            $draggable.closest(".modal-dialog").offset({
              "left": mousemoveEvt.pageX - x,
              "top": mousemoveEvt.pageY - y
            });
          });
          $("body").one("mouseup", function () {
            $("body").off("mousemove.draggable");
          });
          $draggable.closest(".modal").one("bs.modal.hide", function () {
            $("body").off("mousemove.draggable");
          });
        });
      });

    });

    this.elementPropertyService.payLoadResetChanged.subscribe(resetted => {
      if (resetted === undefined || resetted === null) {
        console.warn('Payload Reset Undefined');
        return;
      }
      if (resetted.dataSourceCode === undefined || resetted.dataSourceCode === null || resetted.dataSourceCode === '') {
        console.warn('Payload Reset Data Source Code  Undefined', resetted);
        return;
      }
      this.resetPayload(resetted.dataSourceCode);
    });

    this.elementPropertyService.payLoadElementDeleteChanged.subscribe(resp => {
      console.log('Deleting Payload Property Index', resp, this.payLoadData);
      if (resp.payLoadDataSourceName != undefined && resp.payLoadDataSourceName !== null && resp.payLoadDataSourceName !== '' && resp.payloadProperty != undefined && resp.payloadProperty !== null && resp.payloadProperty !== '') {
        let mainObject = this.payLoadData[resp.payLoadDataSourceName];
        Util.updateObjectWithPath(mainObject, resp.payloadProperty + `.${resp.index}._RW_DELETE_RECORD`, true);
        this.payLoadData[resp.payLoadDataSourceName] = mainObject;
      }
      console.log('Deleted Payload Property', this.payLoadData);
    });

    this.elementPropertyService.payLoadChanged.subscribe(resp => {
      let payLoadProperty = undefined;

      if (resp.element !== undefined) {
        this.currentRwElements.push(resp.element);
      }
      this.elementPropertyService.setCurrentRwElements(this.currentRwElements);

      if (resp.value != undefined && resp.payLoadDataSourceName != undefined && resp.payLoadDataSourceProperty != undefined) {

        let mainObject = this.payLoadData[resp.payLoadDataSourceName];
        if (mainObject === undefined || mainObject === null) {
          mainObject = {};
        }

        if (resp.payLoadDataSourceProperty !== '') {
          resp.payLoadDataSourceProperty.split(".").reduce(function (step, part, index, arr) {   // split the parts and loop
            // console.log('part', part);
            if (part.includes('[') && part.endsWith(']')) {
              const arrayVarParts = part.split('[');
              const arrayProperty = arrayVarParts[0];
              let arrayIndexVal = 0;
              const arrayIndex = arrayVarParts[1].replace(']', '');
              if (arrayIndex !== undefined && arrayIndex !== null && arrayIndex !== '') {
                arrayIndexVal = parseInt(arrayIndex);
              }
              if (step[arrayProperty] === undefined || step[arrayProperty] === null) {
                step[arrayProperty] = [];
              }
              if (step[arrayProperty][arrayIndexVal] === undefined || step[arrayProperty][arrayIndexVal] === null) {
                if (arrayIndexVal === step[arrayProperty].length) { // If trying to add first param in new array item
                  step[arrayProperty].push({});
                }
              }
              return step[arrayProperty][arrayIndexVal];
            } else {
              if (index === arr.length - 1) {  // If we are at the last index, than we set the value
                step[part] = resp.value;
              } else if (step[part] === undefined) {  // If we have not seen this key before, create an object
                step[part] = {};
              }
              return step[part]; // Step up the object we are referencing
            }
          }, mainObject);
        }

        this.payLoadData[resp.payLoadDataSourceName] = mainObject;

        let payLoadFields = this.payLoadDataArray[resp.payLoadDataSourceName];
        if (payLoadFields === undefined || payLoadFields === null) {
          payLoadFields = [];
        }
        payLoadFields.push(resp);
        this.payLoadDataArray[resp.payLoadDataSourceName] = payLoadFields;

        let fieldValidations = this.payLoadValidationObject[resp.payLoadDataSourceName];
        if (fieldValidations === undefined || fieldValidations === null) {
          fieldValidations = {};
        }
        for (var i = 0; i < this.payLoadDataArray[resp.payLoadDataSourceName].length; i++) {
          fieldValidations[this.payLoadDataArray[resp.payLoadDataSourceName][i].payLoadDataSourceProperty] = this.payLoadDataArray[resp.payLoadDataSourceName][i].element.validate();
        }
        this.payLoadValidationObject[resp.payLoadDataSourceName] = fieldValidations;


        let payLoadRWElements = this.payLoadRWElementObject[resp.payLoadDataSourceName];
        if (payLoadRWElements === undefined || payLoadRWElements === null) {
          payLoadRWElements = {};
        }
        for (var i = 0; i < this.payLoadDataArray[resp.payLoadDataSourceName].length; i++) {
          payLoadRWElements[this.payLoadDataArray[resp.payLoadDataSourceName][i].payLoadDataSourceProperty] = this.payLoadDataArray[resp.payLoadDataSourceName][i].element;
        }
        this.payLoadRWElementObject[resp.payLoadDataSourceName] = payLoadRWElements;
        // user.address.dno
        // {user: {address: {dno: val}}}

        // user.address[].dno
        // user.address[1].dno
        // user.address[2].dno
        // {user: {address: [{dno: val}}]}}
        // for (let [key, value] of Object.entries(this.payLoadObject)) {
        //   var data = this.payLoadObject;
        //   payLoadProperty = Object.keys(data).reduce(function (obj, key) {  // loop through the keys in the object
        //     var val = data[key];  // grab the value of this key
        //     key.split(".").reduce(function (step, part, index, arr) {   // split the parts and loop
        //       // console.log('part', part);
        //       if (part.includes('[') && part.endsWith(']')) {
        //         const arrayVarParts = part.split('[');
        //         const arrayProperty = arrayVarParts[0];
        //         let arrayIndexVal = 0;
        //         const arrayIndex = arrayVarParts[1].replace(']', '');
        //         if (arrayIndex !== undefined && arrayIndex !== null && arrayIndex !== '') {
        //           arrayIndexVal = parseInt(arrayIndex);
        //         }
        //         if (step[arrayProperty] === undefined || step[arrayProperty] === null) {
        //           step[arrayProperty] = [];
        //         }
        //         if (step[arrayProperty][arrayIndexVal] === undefined || step[arrayProperty][arrayIndexVal] === null) {
        //           if (arrayIndexVal === step[arrayProperty].length) { // If trying to add first param in new array item
        //             step[arrayProperty].push({});
        //           }
        //         }
        //         return step[arrayProperty][arrayIndexVal];
        //       } else {
        //         if (index === arr.length - 1) {  // If we are at the last index, than we set the value
        //           step[part] = val;
        //         } else if (step[part] === undefined) {  // If we have not seen this key before, create an object
        //           step[part] = {};
        //         }
        //         return step[part]; // Step up the object we are referencing
        //       }
        //     }, obj);
        //     return obj;
        //   }, {});
        //   // console.log("this.payLoadProperty",this.payLoadProperty);
        // }
      }
      // for (var i = 0; i < this.payLOadDataArray.length; i++) {
      //   this.payLoadData[this.payLOadDataArray[i].payLoadDataSourceName] = payLoadProperty;
      // }

      this.elementPropertyService.setPayLoadData(this.payLoadData, this.payLoadValidationObject, this.payLoadRWElementObject);
      console.log('Payload Data Updated', this.payLoadData);
    })

    this.elementPropertyService.pageReloadChanged.subscribe(resp=>{
      this.loadData(this.editingId);
    })
  }

  resetPayload(dataSourceCode: string) {
    if (this.payLoadData !== undefined && this.payLoadData !== null) {
      delete this.payLoadData[dataSourceCode];
    }
    if (this.payLoadValidationObject !== undefined && this.payLoadValidationObject !== null) {
      delete this.payLoadValidationObject[dataSourceCode];
    }
    if (this.payLoadDataArray !== undefined && this.payLoadDataArray !== null) {
      if (this.payLoadDataArray[dataSourceCode] !== undefined && this.payLoadDataArray[dataSourceCode] !== null && Array.isArray(this.payLoadDataArray[dataSourceCode])) {
        let fields = this.payLoadDataArray[dataSourceCode];
        delete this.payLoadDataArray[dataSourceCode];
        for (let field of fields) {
          field.element.setValue('');
          this.elementPropertyService.setPayLoadElement('', field.element.getPayLoadDataSource().payLoadDataSourceName, field.element.getPayLoadDataSource().payLoadProperty, field.element.validate(), field.element);
        }
      }
    }
    // this.elementPropertyService.setPayLoadData(this.payLoadData, this.payLoadValidationObject);
  }

  toggleToolbox() {
    this.showToolbox = !this.showToolbox;
  }

  togglePropertiesBox() {
    this.showPropertiesBox = !this.showPropertiesBox;
  }

  getMainUIBuilderClasses() {
    return {
      'show-properties-box': this.showPropertiesBox,
      'hide-properties-box': !this.showPropertiesBox,
      'show-tool-box': this.showToolbox,
      'hide-tool-box': !this.showToolbox,
      'design-mode-on': this.displayMode === 'DESIGN',
      'design-mode-off': this.displayMode !== 'DESIGN'
    }
  }

  getModalDialogClasses() {
    return {
      'modal-dialog': true,
      'modal-lg': this.showPopup !== undefined && this.showPopup.size === 'lg',
      'modal-sm': this.showPopup !== undefined && this.showPopup.size === 'sm',
      'modal-xl': this.showPopup !== undefined && this.showPopup.size === 'xl'
    }
  }

  closeModalDialog() {
    $(".modal-dialog").removeAttr('style');
  }


  versionChanged(event: any): void {
    this.router.navigate(['/pages/edit/' + this.editingCode + '/' + event.target.value], { queryParams: this.route.snapshot.queryParams });
  }

  loadData(pageId: string) {
    console.log('loadData called', pageId);
    this.dataProviderService.getPageConfig(pageId).then(pageConfig => {
      console.log('pageConfig', pageConfig);
      if (!this.isCopying) {
        this.model.code = pageConfig.code;
        this.model.name = pageConfig.name;
        this.model.title = pageConfig.title;
      }
      this.model.designData = RWElementParser.jsonToLayoutElement(JSON.parse(JSON.stringify(pageConfig.designData[0])));
      this.actionExecuteService.setCurrentPageDesignData(this.model.designData);
    }, error => {
      this.isErrorLoading = true;
      this.errorMessage = 'Unable to retrieve the page layout: ' + error;
    });
  }

  setNewProperties(): void {
    this.model.designData.setTitleD('Default Element');
    this.model.designData.addClass('renderer-wrapper');
  }

  controlItemDrag(event: any, control: RWElement) {
    event.dataTransfer.setData('CONTROL', JSON.stringify(control));
  }

  loadControls() {
    this.controls.push(...this.controlProviderService.getAllControls());
    this.prepareCategorizedControls(this.controls);
    this.filteredControls.push(...this.controlProviderService.getAllControls());
  }

  removeAllCategorizedKeys() {
    const keys = Object.keys(this.categorizedControls);
    keys.map(v => {
      delete this.categorizedControls[v];
    });
  }

  prepareCategorizedControls(controls: RWElement[]): void {
    this.removeAllCategorizedKeys();
    this.categories.splice(0, this.categories.length);
    const allCategories: string[] = [];
    for (const control of controls) {
      allCategories.push(...control.getCategories());
    }
    const uniqueCategories: Set<string> = new Set(allCategories);
    for (const group of uniqueCategories) {
      this.categories.push(group);
      this.categorizedControls[group] = controls.filter((v: RWElement) => {
        return v.getCategories().indexOf(group) > -1;
      });
    }
    this.categorizedControls['Misc'] = controls.filter((v: RWElement) => {
      return v.getCategories().length === 0;
    });
    this.categories.push('Misc');
  }

  submit(event: any, form: NgForm): void {
    const pageConfig = new ApplicationPageConfig();
    pageConfig.id = this.editingId;
    pageConfig.code = this.model.code;
    pageConfig.name = this.model.name;
    pageConfig.title = this.model.title;
    pageConfig.layout = 'GENERIC';
    pageConfig.designData = [this.model.designData];
    if (this.isEditing && !this.isCopying) {
      this.dataProviderService.updatePage(pageConfig).then(done => {
        alert('Page Updated');
        this.router.navigate(['/pages']);
      }, error => {
        alert('Unable to update page ' + error);
      });
    } else {
      this.dataProviderService.addPage(pageConfig).then(done => {
        alert('Page ' + this.isCopying ? 'Copied' : 'Created');
        this.router.navigate(['/pages']);
      }, error => {
        alert('Unable to create page ' + error);
      });
    }
  }

  registerScrollListener() {
    window.addEventListener('scroll', this.fixToolBoxPosition);
    window.addEventListener('resize', this.fixToolBoxPosition);
    document.querySelector('.main-ui-builder-canvas').addEventListener('DOMSubtreeModified', this.fixToolBoxPosition);
  }

  setDisplayMode(mode: string) {
    this.displayMode = <RWElementRendererDisplayMode>mode;
  }

  toggleCategoryControls(e: any) {
    if (!e.target.parentElement.classList.contains('expanded')) {
      e.target.parentElement.classList.add('expanded');
    } else {
      e.target.parentElement.classList.remove('expanded');
    }
  }

  fixToolBoxPosition() {
    let topScrolled = document.querySelector("body").getBoundingClientRect().top;
    const shouldFix = topScrolled < -230;
    if (shouldFix) {
      document.querySelector(".main-ui-builder").classList.add('fixed-tool-box');
      let footerDistance = document.querySelector("app-footer").getBoundingClientRect().top - 30 - 10 - 8 - 8;
      let newHeight = footerDistance > window.innerHeight ? window.innerHeight : window.innerHeight - (window.innerHeight - footerDistance);
      document.querySelector(".main-ui-builder-toolbox  .toolbox-body .control-items").setAttribute('style', 'height: ' + (newHeight - 25 - 52) + 'px');
      document.querySelector(".main-ui-builder-properties").setAttribute('style', 'height: ' + newHeight + 'px');
    } else {
      document.querySelector(".main-ui-builder").classList.remove('fixed-tool-box');
      const contentHeight = document.querySelector('.main-ui-builder-canvas').clientHeight;
      const val = window.innerHeight - 230 - 56 - 30 - 10 - 8 - 8;
      let newHeight = contentHeight > val ? contentHeight : val;
      const maxHeight = window.innerHeight;
      newHeight = newHeight > maxHeight ? maxHeight : newHeight;
      document.querySelector(".main-ui-builder-toolbox .toolbox-body .control-items").setAttribute('style', 'height: ' + (newHeight - 25 - 52) + 'px');
      document.querySelector(".main-ui-builder-properties").setAttribute('style', 'height: ' + newHeight + 'px');
    }
  }

  deregisterScrollListener() {
    window.removeEventListener('resize', this.fixToolBoxPosition);
    window.removeEventListener('scroll', this.fixToolBoxPosition);
    if (document.querySelector('.main-ui-builder-canvas') !== undefined && document.querySelector('.main-ui-builder-canvas') !== null) {
      document.querySelector('.main-ui-builder-canvas').removeEventListener('DOMSubtreeModified', this.fixToolBoxPosition);
    }
    if (document.querySelector('.control-category-title') !== undefined && document.querySelector('.control-category-title') !== null) {
      document.querySelector('.control-category-title').removeEventListener('onclick', this.toggleCategoryControls);
    }
  }

  ngOnDestroy() {
    this.deregisterScrollListener();
  }

  onSearchByKeywords() {
    this.filteredControls = [];
    for (let i = 0; i < this.controls.length; i++) {
      for (let j = 0; j < this.controls[i].getKeywords().length; j++) {
        if (this.controls[i].getKeywords()[j].toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1) {
          this.filteredControls.push(this.controls[i]);
          break;
        }
      }
    }
    if (this.searchValue === '') {
      this.filteredControls = this.controls;
    }
    this.prepareCategorizedControls(this.filteredControls);
  }
}
