import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar-layout',
  templateUrl: './topbar-layout.component.html',
  styleUrls: ['./topbar-layout.component.scss']
})
export class TopbarLayoutComponent implements OnInit {

  editingMode = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleEditingMode(): void {
    this.editingMode = !this.editingMode;
    this.updateBodyClass();
  }

  updateBodyClass(): void {
    const bodies = document.getElementsByTagName('body');
    if (this.editingMode) {
      bodies[0].classList.remove('editing-mode-off');
      bodies[0].classList.add('editing-mode-on');
    } else {
      bodies[0].classList.remove('editing-mode-on');
      bodies[0].classList.add('editing-mode-off');
    }
  }

}
