import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PagesComponent } from './components/pages/pages.component';
import { NewPageComponent } from './components/pages/new-page/new-page.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AuthGuard } from './keycloak/auth.guard';
import { LogoutComponent } from './components/logout/logout.component';
import { NewDataSourceComponent } from './components/data-sources/new-data-source/new-data-source.component';
import { DataSourcesComponent } from './components/data-sources/data-sources.component';
import { environment } from 'src/environments/environment';
import { MenuComponent } from './components/menu/menu.component';
import { NewMenuComponent } from './components/menu/new-menu/new-menu.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [environment.builderRole] }
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    data: { roles: [environment.builderRole] },
    children: [
      {
        path: '',
        component: PagesComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole] }
      },
      {
        path: 'new',
        component: NewPageComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole] }
      },
      {
        path: 'edit/:id',
        component: NewPageComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole] }
      },
      {
        path: 'copy/:id',
        component: NewPageComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole], copy: true }
      }
    ]
  },
  {
    path: 'menu',
    canActivate: [AuthGuard],
    data: { roles: [environment.builderRole] },
    children: [
      {
        path: '',
        component: MenuComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole] }
      },
      {
        path: 'new',
        component: NewMenuComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole] }
      },
      {
        path: 'edit/:id',
        component: NewMenuComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole] }
      }
    ]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { roles: [environment.builderRole] }
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'data-sources',
    canActivate: [AuthGuard],
    data: { roles: [environment.builderRole] },
    children: [
      {
        path: '',
        component: DataSourcesComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole] }
      },
      {
        path: 'new',
        component: NewDataSourceComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole] }
      },
      {
        path: 'edit/:id',
        component: NewDataSourceComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole] }
      },
      {
        path: 'copy/:id',
        component: NewDataSourceComponent,
        canActivate: [AuthGuard],
        data: { roles: [environment.builderRole],copy: true  }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
