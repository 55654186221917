import { Component, OnInit } from '@angular/core';
import { ElementPropertyService } from 'src/app/services/element-property.service';
import { ElementProperty } from 'src/app/classes/ElementProperty';
import { ElementPropertySchemaProvider } from "src/app/classes/ElementPropertySchemaProvider";
import { ElementPropertyField } from "src/app/classes/ElementPropertyField";
import { ElementPropertyFieldEvent } from "src/app/classes/ElementPropertyFieldEvent";
import { ElementPropertyCategory } from "src/app/classes/ElementPropertyCategory";
import { RWElement } from 'src/app/classes/RWElement';
import { RWElementType } from 'src/app/classes/RWElementType';
import { RWElementControlType } from 'src/app/classes/RWElementControlType';
import { RWActionType } from 'src/app/classes/RWActionType';
import { RWAPIMethod } from 'src/app/classes/RWAPIMethod';
import { RWElementPropertyAction } from 'src/app/classes/RWElementPropertyAction';
import { TtDataType } from 'angular-tree-table';
import { element } from 'protractor';

@Component({
  selector: 'app-element-properties',
  templateUrl: './element-properties.component.html',
  styleUrls: ['./element-properties.component.scss']
})
export class ElementPropertiesComponent implements OnInit {

  properties: ElementProperty[] = [];
  private element: RWElement = undefined;
  categories: ElementPropertyCategory[] = [];
  categorizedProperties: { [key: string]: ElementProperty[] } = {};
  searchValue: string;
  filteredProperties = [];
  constructor(private elementPropertyService: ElementPropertyService) { }

  ngOnInit(): void {
    const dis = this;
    this.populateCategories();
    dis.elementPropertyService.elementChanged.subscribe(resp => {
      dis.element = dis.elementPropertyService.getElement();
      console.log("ELEMWNT",dis.element);
      if (dis.element !== undefined) {
        if (dis.element.getElementType() === RWElementType.CONTROL) {
          dis.properties = ElementPropertySchemaProvider.getProperties(dis.element.getElementType(), dis.element.getControlType());
        } else {
          dis.properties = ElementPropertySchemaProvider.getProperties(dis.element.getElementType());
        }
        dis.updateCategorizedProperties();
      }
    })
  }

  updateCategorizedProperties() {
    for (const cat of this.categories) {
      delete this.categorizedProperties[cat];
      this.categorizedProperties[cat] = this.properties.filter(v => v.category === cat);
    }
  }

  populateCategories() {
    for (const cat in ElementPropertyCategory) {
      this.categories.push(<ElementPropertyCategory>cat);
    }
  }

  getElementInfo() {
    if (this.element === undefined || this.element === null) {
      return '';
    }
    let data = this.element.getElementType() + '';
    if (this.element.getControlType() !== undefined && this.element.getControlType() !== null && this.element.getControlType() !== RWElementControlType.UNDEFINED) {
      data += ' : ' + this.element.getControlType();
    }
    return data;
  }

  fieldValueChanged(data: ElementPropertyFieldEvent) {

    if (data.property.name === 'tableHeaders') {
      this.elementPropertyService.emitTableSettingsChanged();
    }

    if (data.field.name === 'actionType') {
      const actionTypeFields = this.loadActionTypeRelatedFields(data.event.target.value);
      if (data.property.fields[data.property.fields.length - 1].findIndex(v => actionTypeFields.findIndex(v1 => v1.name === v.name) > -1) === -1) {
        data.property.fields[data.property.fields.length - 1].push(...this.loadActionTypeRelatedFields(data.event.target.value));
      }
    }

    if (data.field.name === 'dataType') {
      const actionTypeFields = this.loadDataTypeRelatedFields(data.event.target.value);
      if (data.property.fields[data.property.fields.length - 1].findIndex(v => actionTypeFields.findIndex(v1 => v1.name === v.name) > -1) === -1) {
        data.property.fields[data.property.fields.length - 1].push(...this.loadDataTypeRelatedFields(data.event.target.value));
      }
    }

    if (data.field.name === 'subDataType') {
      const actionTypeFields = this.loadSubDataTypeRelatedFields(data.event.target.value);
      if (data.property.fields[data.property.fields.length - 1].findIndex(v => actionTypeFields.findIndex(v1 => v1.name === v.name) > -1) === -1) {
        data.property.fields[data.property.fields.length - 1].push(...this.loadSubDataTypeRelatedFields(data.event.target.value));
      }
    }

    if (data.field.name === 'method') {
      const actionTypeFields = this.loadApiMethodRelatedFields(data.event.target.value);
      if (data.property.fields[data.property.fields.length - 1].findIndex(v => actionTypeFields.findIndex(v1 => v1.name === v.name) > -1) === -1) {
        data.property.fields[data.property.fields.length - 1].push(...this.loadApiMethodRelatedFields(data.event.target.value));
      }
    }

    if (data.field.name === 'propertyActionType') {
      const actionTypeFields = this.loadUpdatePropertyActionTypesRelatedFields(data.event.target.value);
      if (data.property.fields[data.property.fields.length - 1].findIndex(v => actionTypeFields.findIndex(v1 => v1.name === v.name) > -1) === -1) {
        data.property.fields[data.property.fields.length - 1].push(...this.loadUpdatePropertyActionTypesRelatedFields(data.event.target.value));
      }
    }

    this.element[data.field.setterMethodName](data);
  }

  loadActionTypeRelatedFields(actionType: RWActionType): ElementPropertyField[] {
    return ElementPropertySchemaProvider.getPropertiesByActionType(actionType);
  }

  loadDataTypeRelatedFields(dataType: TtDataType): ElementPropertyField[] {
    return ElementPropertySchemaProvider.getPropertiesByActionDataType(dataType);
  }

  loadSubDataTypeRelatedFields(subDataType: TtDataType): ElementPropertyField[] {
    return ElementPropertySchemaProvider.getPropertiesByActionSubDataType(subDataType);
  }

  loadApiMethodRelatedFields(actionType: RWAPIMethod): ElementPropertyField[] {
    return ElementPropertySchemaProvider.getPropertiesByActionApiType(actionType);
  }

  loadUpdatePropertyActionTypesRelatedFields(propertyActionType: RWElementPropertyAction): ElementPropertyField[] {
    return ElementPropertySchemaProvider.getPropertiesByElementPropertyActionType(propertyActionType);
  }

  getFieldValue(property: ElementProperty, field: ElementPropertyField, fieldSetIndex: number, fieldIndex: number, valueIndex: number): any {
    const value = this.element[field.getterMethodName](valueIndex, fieldIndex, fieldSetIndex);
    if (field.name === 'actionType') {
      const actionTypeFields = this.loadActionTypeRelatedFields(value);
      if (property.fields[property.fields.length - 1].findIndex(v => actionTypeFields.findIndex(v1 => v1.name === v.name) > -1) === -1) {
        property.fields[property.fields.length - 1].push(...actionTypeFields);
      }
    }
    if (field.name === 'dataType') {
      const actionTypeFields = this.loadDataTypeRelatedFields(value);
      if (property.fields[property.fields.length - 1].findIndex(v => actionTypeFields.findIndex(v1 => v1.name === v.name) > -1) === -1) {
        property.fields[property.fields.length - 1].push(...actionTypeFields);
      }
    }
    if (field.name === 'method') {
      const actionTypeFields = this.loadApiMethodRelatedFields(value);
      if (property.fields[property.fields.length - 1].findIndex(v => actionTypeFields.findIndex(v1 => v1.name === v.name) > -1) === -1) {
        property.fields[property.fields.length - 1].push(...actionTypeFields);
      }
    }
    if (field.name === 'propertyActionType') {
      const actionTypeFields = this.loadUpdatePropertyActionTypesRelatedFields(value);
      if (property.fields[property.fields.length - 1].findIndex(v => actionTypeFields.findIndex(v1 => v1.name === v.name) > -1) === -1) {
        property.fields[property.fields.length - 1].push(...actionTypeFields);
      }
    }
    return value;
  }

  getPropertyValuesLength(property: ElementProperty) {
    const vals = [];
    const count = this.element[property.valuesLengthMethodName]();
    for (let val = 0; val < count; val++) {
      vals.push(val);
    }
    return vals;
  }

  addEmptyPropertyValue(property: ElementProperty) {
    this.element[property.addEmptyValueMethodName]();
  }

  removeSelectedPropertyValue(valIndex: number, property: ElementProperty) {
    this.element[property.removeSelectedValueMethodName](valIndex, property);
  }

  onSearchByProperties() {
    this.filteredProperties = [];
     for(var i=0;i<this.properties.length;i++) {
      if(this.properties[i].name.toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) > -1) {
          this.filteredProperties.push(this.properties[i]);
      }
     }
     this.prepareCategorizedProperties(this.filteredProperties);
     if (this.searchValue === '') {
      this.filteredProperties = this.properties;
    }
  }
  
  removeAllCategorizedKeys() {
    const keys = Object.keys(this.categorizedProperties);
    keys.map(v => {
      delete this.categorizedProperties[v];
    });
  }

  prepareCategorizedProperties(properties: ElementProperty[]): void {
    this.removeAllCategorizedKeys();
    this.categories.splice(0, this.categories.length);
    const allCategories: string[] = [];
    for (const property of properties) {
      allCategories.push(property.category);
    }
    const uniqueCategories: Set<string> = new Set(allCategories);
    for (const group of uniqueCategories) {
      this.categories.push(<ElementPropertyCategory>group);
      this.categorizedProperties[group] = properties.filter((v: ElementProperty) => {
        return v.category.indexOf(group) > -1;
      });
    }
  }

}
