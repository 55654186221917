import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/keycloak/auth.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  userDetails: any = {};
  loggedUserName: string = 'Logged in User';

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.userDetails = this.authService.getLoggedUser();
    if (this.userDetails !== undefined && this.userDetails['given_name'] !== undefined && this.userDetails['given_name'] !== null) {
      this.loggedUserName = this.userDetails['given_name'];
    }
  }

  logoutClicked(): void {
    this.authService.logout();
  }
}
