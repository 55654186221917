export enum RWElementControlType {
  UNDEFINED = 'UNDEFINED',
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  PASSWORD = 'PASSWORD',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  EMAIL = 'EMAIL',
  BUTTON = 'BUTTON',
  File ='FILE',
  RANGE = 'RANGE',
  DATE = 'DATE',
  SEARCH = 'SEARCH',
  URL = 'URL',
  COLOR = 'COLOR',
  DATETIME = 'DATETIME',
  TEL = 'TEL',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  TIME = 'TIME',
  SUBMIT = 'SUBMIT',
  RESET = 'RESET',
  TABLE = 'TABLE',
  TEXTAREA = 'TEXTAREA',
  FORM ='FORM'
}
