<ng-container>
    <app-topbar></app-topbar>
    <main role="main" class="flex-grow-1">
        <div class="container d-flex flex-column h-100 shadow-sm">
            <div class="page">
                <div class="page-right-options">
                    <button class="btn btn-sm btn-secondary" (click)="toggleEditingMode()">
                        <i class="fa fa-expand" *ngIf="!editingMode"></i>
                        <i class="fa fa-compress" *ngIf="editingMode"></i>
                    </button>
                </div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </main>
    <app-footer class="footer mt-auto py-3 shadow-sm"></app-footer>
</ng-container>