export class AuditInfo {
    createdAt?: Date;
    createdById?: string;
    createdByName?: string;
    lastModifiedAt?: Date;
    lastModifiedById?: string;
    lastModifiedByName?: string;
    deletedAt?: Date;
    deletedById?: string;
    deletedByName?: string;
}
