import { Directive, Input, HostListener } from '@angular/core';
import { RWElement } from '../classes/RWElement';
import { RWEventType } from '../classes/RWEventType';
import { HttpClient } from '@angular/common/http';
import { DataSourceExecutorService } from '../services/data-source-executor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionExecutorService } from '../services/action-executor.service';
import { RWElementRendererComponent } from '../components/renderers/rw-element-renderer/rw-element-renderer.component';

@Directive({
  selector: '[appEventListener]'
})
export class EventListenerDirective {
  element: RWElement;
  elementContext: RWElementRendererComponent;
  constructor(private http: HttpClient, private dataSourceExecutorService: DataSourceExecutorService, private route: ActivatedRoute, private router: Router, private actionExecutorService: ActionExecutorService) { }

  @Input() set appEventListener(element: {elementData: RWElement, context: RWElementRendererComponent}) {
    this.element = element.elementData;
    this.elementContext = element.context;
  }

  @HostListener('click', ['$event']) onClick() {
    const event = this.element.getEvents().find(v => v.type === RWEventType.CLICK);
    if (event === undefined || event === null || event.action === undefined) {
      return true;
    }
    this.actionExecutorService.execute(event.action, this.element, undefined, undefined, this.elementContext);
  }

  @HostListener('change', ['$event']) onChange(e) {
    const event = this.element.getEvents().find(v => v.type === RWEventType.CHANGE);
    if (event === undefined || event === null || event.action === undefined) {
      return true;
    }
    this.actionExecutorService.execute(event.action, this.element, undefined, e, this.elementContext);

  }

  @HostListener('mouseenter', ['$event']) onHover() {
    const event = this.element.getEvents().find(v => v.type === RWEventType.HOVER);
    if (event === undefined || event === null || event.action === undefined) {
      return true;
    }
    this.actionExecutorService.execute(event.action, this.element, undefined, undefined, this.elementContext);
  }

  @HostListener('keyup', ['$event']) onKeyup() {
    const event = this.element.getEvents().find(v => v.type === RWEventType.KEYUP);
    if (event === undefined || event === null || event.action === undefined) {
      return true;
    }
    this.actionExecutorService.execute(event.action, this.element, undefined, undefined, this.elementContext);
  }

  @HostListener('keydown', ['$event']) onKeydown() {
    const event = this.element.getEvents().find(v => v.type === RWEventType.KEYDOWN);
    if (event === undefined || event === null || event.action === undefined) {
      return true;
    }
    this.actionExecutorService.execute(event.action, this.element, undefined, undefined, this.elementContext);
  }

  @HostListener('keypress', ['$event']) onKeypress() {
    const event = this.element.getEvents().find(v => v.type === RWEventType.KEYPRESS);
    if (event === undefined || event === null || event.action === undefined) {
      return true;
    }
    this.actionExecutorService.execute(event.action, this.element, undefined, undefined, this.elementContext);
  }
}