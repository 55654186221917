<div class="page-title">
    <label class="page-heading" *ngIf="!isEditing">New Page</label>
    <label class="page-heading" *ngIf="isEditing">{{displayMode}} Page: {{editingCode}}</label>
    <div class="page-actions">
        <div class="form-inline versions-selector">
            <label class="ml-1 control-label d-flex" for="versionSelect">Version: </label>
            <select id="versionSelect" class="mx-1 form-control-sm" (change)="versionChanged($event)">
                <option *ngFor="let pageVersion of pageVersions" [selected]="pageVersion == editingVersion">
                    {{pageVersion}}
                </option>
            </select>
        </div>
        <a class="btn btn-sm btn-secondary" routerLink="/pages">Back</a>
    </div>
</div>
<div class="page-content">
    <form method="POST" class="form-inline" #newPageForm="ngForm" (ngSubmit)="submit($event, newPageForm)">
        <label class="sr-only" for="new-page-form-code">Code</label>
        <input type="text" placeholder="Code" class="form-control mr-sm-2" [(ngModel)]="model.code" name="code"
            id="new-page-form-code" />
        <label class="sr-only" for="new-page-form-name">Name</label>
        <input type="text" placeholder="Name" class="form-control mr-sm-2" [(ngModel)]="model.name" name="name"
            id="new-page-form-name" />
        <label class="sr-only" for="new-page-form-name">Title</label>
        <input type="text" placeholder="Title" class="form-control mr-sm-2" [(ngModel)]="model.title" name="title"
            id="new-page-form-title" />
        <button type="submit" class="btn btn-primary">Save</button>
        <div class="flex-fill"></div>
        <div class="btn-group">
            <button class="tool-box-toggle" type="button"
                [ngClass]="showToolbox ? 'tool-box-dark' : 'tool-box-light'"
                (click)="toggleToolbox()"></button>
            <button class="properties-box-toggle" type="button"
                [ngClass]="showPropertiesBox ? 'property-box-dark' : 'property-box-light'"
                (click)="togglePropertiesBox()"></button>
        </div>
        <div class="flex-fill"></div>
        <div class="btn-group">
            <button class="btn btn-sm" type="button"
                [ngClass]="displayMode === 'DESIGN' ? 'btn-secondary' : 'btn-outline-secondary'"
                (click)="setDisplayMode('DESIGN')">Design</button>
            <button class="btn btn-sm" type="button"
                [ngClass]="displayMode === 'VIEW' ? 'btn-secondary' : 'btn-outline-secondary'"
                (click)="setDisplayMode('VIEW')">Preview</button>
        </div>
    </form>
    <div class="main-ui-builder" [ngClass]="getMainUIBuilderClasses()">
        <div class="main-ui-builder-toolbox">
            <div class="toolbox-header">
                <div class="toolbox-title">Toolbox</div>
            </div>
            <div class="toolbox-body">
                <div class="controls-search">
                    <input type="search" class="form-control" placeholder="Search Controls" name="search"
                        [(ngModel)]="searchValue" (ngModelChange)="onSearchByKeywords()" />
                </div>
                <div class="control-items">
                    <div class="control-category-wrapper expanded" *ngFor="let category of categories">
                        <div class="control-category-title" (click)="toggleCategoryControls($event)">
                            <label>{{category}}</label>
                        </div>
                        <div class="control-category-items">
                            <div class="control-item-wrapper" draggable="true"
                                (dragstart)="controlItemDrag($event, control)"
                                *ngFor="let control of categorizedControls[category]">
                                <app-rw-element-renderer displayMode="TOOLBOX" [elementData]="control">
                                </app-rw-element-renderer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-ui-builder-canvas">
            <app-rw-element-renderer [displayMode]="displayMode" [elementData]="model.designData">
            </app-rw-element-renderer>
        </div>
        <app-element-properties class="main-ui-builder-toolbox main-ui-builder-properties"></app-element-properties>
    </div>
</div>

<div class="modal fade" id="modal-design1" tabindex="-1" role="dialog" aria-labelledby="classInfo" aria-hidden="true">
    <div [ngClass]="getModalDialogClasses()" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="classInfo">{{model.popupTitle}}</h5>
                <button type="button" class="close" data-dismiss="modal" (click)="closeModalDialog()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="main-ui-builder-canvas">
                    <app-rw-element-renderer displayMode="VIEW" [elementData]="model.popupDesignData">
                    </app-rw-element-renderer>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeModalDialog()" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>