import { Injectable } from '@angular/core';
import { RWAction } from '../classes/RWAction';
import { RWActionType } from '../classes/RWActionType';
import { Router, ActivatedRoute } from '@angular/router';
import { RWElementPropertyAction } from '../classes/RWElementPropertyAction';
import { RWAPIMethod } from '../classes/RWAPIMethod';
import { HttpClient } from '@angular/common/http';
import { DataSourceExecutorService } from './data-source-executor.service';
import { ElementPropertyService } from './element-property.service';
import { RWElement } from '../classes/RWElement';
import { BehaviorSubject } from 'rxjs';
import { RWActionProperty } from '../classes/RWActionProperty';
import { RWElementType } from '../classes/RWElementType';
import { RWElementControlType } from '../classes/RWElementControlType';
import { TreeTableRow, TreeTableData, TtDataType, TreeTableRowAction } from 'angular-tree-table';
import { RWElementSelectOption } from '../classes/RWElementSelectOption';
import { Util } from '../util/util';
import { RWElementRendererComponent } from '../components/renderers/rw-element-renderer/rw-element-renderer.component';
declare var $: any;
declare const recognize: any;
import { saveAs } from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class ActionExecutorService {

  private selectedElement: RWElement;

  private currentPageDesignDataChangedSubject = new BehaviorSubject('');
  currentPageDesignDataChanged = this.currentPageDesignDataChangedSubject.asObservable();

  popupContextData: any = undefined;
  removedValues = [];


  constructor(private router: Router, private http: HttpClient, private dataSourceExecutorService: DataSourceExecutorService,
    private route: ActivatedRoute, private elementPropertySerice: ElementPropertyService) {
    this.elementPropertySerice.showPopupSettingsChanged.subscribe(resp => {
      this.popupContextData = resp.popupContextData;
    });
  }

  getUrl(e) {
    return this.http.get(e);
  }

  getResponse(e) {
    this.getUrl(e).subscribe((resp) => {
      console.info("getResponse", resp);
    });
  }
  setCurrentPageDesignData(element: RWElement) {
    this.selectedElement = element;
    this.currentPageDesignDataChangedSubject.next('');
  }

  getCurrentPageDesignData(): RWElement {
    return this.selectedElement;
  }

  execute(action: RWAction, element: RWElement, subscriptionCallback?: (resp: any) => void, event?: any, elementContext?: RWElementRendererComponent) {
    if (action === undefined || action === null) {
      return ''
    }
    if (action.type === RWActionType.PAYLOAD_RESET) {
      let resetDataSourceCode = undefined;
      if (element.getPayLoadDataSourceName() !== undefined && element.getPayLoadDataSourceName() !== null && element.getPayLoadDataSourceName() !== '') {
        resetDataSourceCode = element.getPayLoadDataSourceName();
      }
      if (resetDataSourceCode === undefined) {
        const dataSourceProperty = action.properties.find(v => v.key === 'dataSource');
        if (dataSourceProperty !== undefined && dataSourceProperty !== null) {
          resetDataSourceCode = dataSourceProperty.value;
        }
      }
      if (resetDataSourceCode !== undefined && resetDataSourceCode !== null && resetDataSourceCode !== '') {
        this.elementPropertySerice.resetPayload(resetDataSourceCode);
      }
      let rwCallback = action.properties.find(resp => resp.key === 'rw-callback');
      if (rwCallback !== undefined && rwCallback !== null) {
        this.execute(rwCallback.value, element, subscriptionCallback, event, elementContext);
      }
    } else if (action.type === RWActionType.PAGE_RELOAD) {
      this.elementPropertySerice.emitPageReloadChanged();
    } else if (action.type === RWActionType.PAYLOAD_FIELD_RESET) {
      let fieldPath = action.properties.find(resp => resp.key === 'fieldPath');
      let fieldPathValue = fieldPath.value;
      let key = fieldPathValue.replace('$PAYLOAD.', '');
      let fieldPathParts = fieldPathValue.split('.');
      let dataSourceCode = fieldPathParts[1];
      const parameter = key.replace(dataSourceCode + ".", '');

      this.elementPropertySerice.setPayLoadElement("", this.elementPropertySerice.getPayLoadRWelement(dataSourceCode)[parameter].getPayLoadDataSource().payLoadDataSourceName, this.elementPropertySerice.getPayLoadRWelement(dataSourceCode)[parameter].getPayLoadDataSource().payLoadProperty, this.elementPropertySerice.getPayLoadRWelement(dataSourceCode)[parameter].validate(), this.elementPropertySerice.getPayLoadRWelement(dataSourceCode)[parameter]);

      this.elementPropertySerice.getPayLoadRWelement(dataSourceCode)[parameter].setValue("");

      let rwCallback = action.properties.find(resp => resp.key === 'rw-callback');

      if (rwCallback !== undefined && rwCallback !== null) {
        this.execute(rwCallback.value, element, subscriptionCallback, event, elementContext);
      }

    } else if (action.type === RWActionType.VOICE) {
      let transcript = action.properties.find(ts => ts.key === 'transcript');
      recognize(transcript.value, element.getId(), elementContext.tableData);
    } else if (action.type === RWActionType.ALERT) {
      const alertMessage = action.properties.find(resp => resp.key === 'message');
      if (alertMessage.value.indexOf('$DS.') > -1) {
        const queryParamsValue = alertMessage.value;
        const key = queryParamsValue.replace('$DS.', '');
        const varName = '$DS.' + key;
        const dataSourceCode = key.split('.')[0];
        const finalPath = key.replace(dataSourceCode + '.response.', '');
        let value = Util.evaluateConcat(finalPath, this.dataSourceExecutorService.getResponseFor(dataSourceCode));
        alert(value);
      } else {
        alert(alertMessage.value);
      }
      let rwCallback = action.properties.find(resp => resp.key === 'rw-callback');
      if (rwCallback !== undefined && rwCallback !== null) {
        this.execute(rwCallback.value, element, subscriptionCallback, event, elementContext);
      }
    } else if (action.type === RWActionType.NAVIGATE) {
      const navigateUrlProperty = action.properties.find(resp => resp.key === 'navigateUrl');
      const queryParamsProperty = action.properties.find(resp => resp.key === 'queryParam');

      let urlPath = navigateUrlProperty.value;
      let urlParts = navigateUrlProperty.value.split('/');
      for (let [index, urlPart] of urlParts.entries()) {
        if (urlPart === undefined || urlPart === null) {
          continue;
        }
        if (urlPart.indexOf('$ROUTE.PATH.PARAM') > -1) {
          const key = urlPart.replace('$ROUTE.PATH.PARAM', '');
          const varName = '$ROUTE.PATH.PARAM' + key;
          let url = window.location.hash.substr(1).split('/');
          const value = url[key];
          urlParts[index] = value;
        } else if (urlPart.indexOf('$ELEMENT.context.data.') > -1) {
          const key = urlPart.replace('$ELEMENT.context.data.', '');
          const varName = '$ELEMENT.context.data.' + key;
          const value = element.getContext().data[key];
          urlParts[index] = value;
        } else if (urlPart === '$ELEMENT.value') {
          urlParts[index] = event.target.value;
        } else if (urlPart.indexOf('$POPUP_CONTEXT.data.') > -1) {
          const key = urlPart.replace('$POPUP_CONTEXT.data.', '');
          const varName = '$POPUP_CONTEXT.data.' + key;
          let value = '';
          if (this.popupContextData !== undefined && this.popupContextData !== null) {
            value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
          }
          urlParts[index] = value;
        } else if (urlPart.indexOf('$PAYLOAD.') > -1) {
          const key = urlPart.replace('$PAYLOAD.', '');
          const varName = '$PAYLOAD.' + key;
          const parts = urlPart.split('.');
          const dataSourceCode = parts[1];
          const parameter = key.replace(dataSourceCode + ".", '');
          const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
          const value = Util.evaluateConcat(parameter, result);
          urlParts[index] = value;
        }
      }
      urlPath = urlParts.join('/');
      let finalQueryParam = {};
      if (queryParamsProperty !== undefined && queryParamsProperty.value !== undefined) {
        let queryParams = queryParamsProperty.value;
        for (let paramKey of Object.keys(queryParams)) {
          if (queryParams[paramKey].indexOf('$ROUTE.PATH.PARAM') > -1) {
            const queryParamValue = queryParams[paramKey];
            const key = queryParamValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ROUTE.PATH.PARAM' + key;
            let url = window.location.hash.substr(1).split('/');
            const value = url[key];
            finalQueryParam[paramKey] = queryParams[paramKey].replace(varName, value);
          } else if (queryParams[paramKey].indexOf('$ELEMENT.context.data.') > -1) {
            const queryParamValue = queryParams[paramKey];
            const key = queryParamValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ELEMENT.context.data.' + key;
            const value = element.getContext().data[key];
            finalQueryParam[paramKey] = queryParams[paramKey].replace(varName, value);
          } else if (queryParams[paramKey] === '$ELEMENT.value') {
            finalQueryParam[paramKey] = event.target.value;
          } else if (queryParams[paramKey].indexOf('$POPUP_CONTEXT.data') > -1) {
            const queryParamValue = queryParams[paramKey];
            const key = queryParamValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            finalQueryParam[paramKey] = queryParams[paramKey].replace(varName, value);
          } else if (queryParams[paramKey].indexOf('$PAYLOAD.') > -1) {
            const queryParamValue = queryParams[paramKey];
            const key = queryParamValue.replace('$PAYLOAD.', '');
            const varName = '$PAYLOAD.' + key;
            const parts = queryParams[paramKey].split('.');
            const dataSourceCode = parts[1];
            const parameter = key.replace(dataSourceCode + ".", '');
            const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
            const value = Util.evaluateConcat(parameter, result);
            finalQueryParam[paramKey] = queryParams[paramKey].replace(varName, value);
          } else {
            finalQueryParam[paramKey] = queryParams[paramKey]
          }
        }
      }
      this.router.navigate([urlPath], { queryParams: finalQueryParam });
    } else if (action.type === RWActionType.OPEN_TAB) {
      const navigateUrlProperty = action.properties.find(resp => resp.key === 'navigateUrl');
      const queryParamsProperty = action.properties.find(resp => resp.key === 'queryParam');
      let rwCallback = action.properties.find(resp => resp.key === 'rw-callback');


      let urlPath = navigateUrlProperty.value;
      let urlParts = navigateUrlProperty.value.split('/');
      for (let [index, urlPart] of urlParts.entries()) {
        if (urlPart === undefined || urlPart === null) {
          continue;
        }
        if (urlPart.indexOf('$ROUTE.PATH.PARAM') > -1) {
          const key = urlPart.replace('$ROUTE.PATH.PARAM', '');
          const varName = '$ROUTE.PATH.PARAM' + key;
          let url = window.location.hash.substr(1).split('/');
          const value = url[key];
          urlParts[index] = value;
        } else if (urlPart.indexOf('$ELEMENT.context.data.') > -1) {
          const key = urlPart.replace('$ELEMENT.context.data.', '');
          const varName = '$ELEMENT.context.data.' + key;
          const value = element.getContext().data[key];
          urlParts[index] = value;
        } else if (urlPart === '$ELEMENT.value') {
          urlParts[index] = event.target.value;
        } else if (urlPart.indexOf('$POPUP_CONTEXT.data.') > -1) {
          const key = urlPart.replace('$POPUP_CONTEXT.data.', '');
          const varName = '$POPUP_CONTEXT.data.' + key;
          let value = '';
          if (this.popupContextData !== undefined && this.popupContextData !== null) {
            value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
          }
          urlParts[index] = value;
        } else if (urlPart.indexOf('$PAYLOAD.') > -1) {
          const key = urlPart.replace('$PAYLOAD.', '');
          const varName = '$PAYLOAD.' + key;
          const parts = urlPart.split('.');
          const dataSourceCode = parts[1];
          const parameter = key.replace(dataSourceCode + ".", '');
          const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
          const value = Util.evaluateConcat(parameter, result);
          urlParts[index] = value;
        }
      }
      urlPath = urlParts.join('/');
      let finalQueryParam = {};
      if (queryParamsProperty !== undefined && queryParamsProperty.value !== undefined) {
        let queryParams = queryParamsProperty.value;
        for (let paramKey of Object.keys(queryParams)) {
          if (queryParams[paramKey].indexOf('$ROUTE.PATH.PARAM') > -1) {
            const queryParamValue = queryParams[paramKey];
            const key = queryParamValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ROUTE.PATH.PARAM' + key;
            let url = window.location.hash.substr(1).split('/');
            const value = url[key];
            finalQueryParam[paramKey] = queryParams[paramKey].replace(varName, value);
          } else if (queryParams[paramKey].indexOf('$ELEMENT.context.data.') > -1) {
            const queryParamValue = queryParams[paramKey];
            const key = queryParamValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ELEMENT.context.data.' + key;
            const value = element.getContext().data[key];
            finalQueryParam[paramKey] = queryParams[paramKey].replace(varName, value);
          } else if (queryParams[paramKey] === '$ELEMENT.value') {
            finalQueryParam[paramKey] = event.target.value;
          } else if (queryParams[paramKey].indexOf('$POPUP_CONTEXT.data') > -1) {
            const queryParamValue = queryParams[paramKey];
            const key = queryParamValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            finalQueryParam[paramKey] = queryParams[paramKey].replace(varName, value);
          } else if (queryParams[paramKey].indexOf('$PAYLOAD.') > -1) {
            const queryParamValue = queryParams[paramKey];
            const key = queryParamValue.replace('$PAYLOAD.', '');
            const varName = '$PAYLOAD.' + key;
            const parts = queryParams[paramKey].split('.');
            const dataSourceCode = parts[1];
            const parameter = key.replace(dataSourceCode + ".", '');
            const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
            const value = Util.evaluateConcat(parameter, result);
            finalQueryParam[paramKey] = queryParams[paramKey].replace(varName, value);
          } else {
            finalQueryParam[paramKey] = queryParams[paramKey]
          }
        }
      }
      this.router.navigate([urlPath], { queryParams: finalQueryParam });
      if (rwCallback !== undefined && rwCallback !== null) {
        this.execute(rwCallback.value, element, subscriptionCallback, event, elementContext);
      }
    } else if (action.type === RWActionType.SHOWPOPUP) {
      const popupMessage = action.properties.find(resp => resp.key === 'showPopup');
      const popupSize = action.properties.find(resp => resp.key === 'popupSize');
      const popupContextData = element.getContext() !== undefined ? element.getContext().data : undefined;
      this.elementPropertySerice.emitShowPopupSettingsChanged(popupMessage.value, popupSize === undefined ? undefined : popupSize.value, popupContextData);
      $('#modal-design1').modal({ backdrop: 'static' });
    } else if (action.type === RWActionType.API_CALL) {
      const methodType = action.properties.find(v => v.key === 'method');
      if (methodType === undefined || methodType === null) {
        return true;
      }
      const urlValue = action.properties.find(v => v.key === 'url');
      if (urlValue === undefined || urlValue === null) {
        return true;
      }
      if (methodType.value === RWAPIMethod.GET) {
        this.getResponse(urlValue.value);
      }

    } else if (action.type === RWActionType.CONFIRM) {

      let confirmText = action.properties.find(resp => resp.key === 'message');

      let result = confirm(confirmText.value)

      let yesAction = action.properties.find(resp => resp.key === 'yesAction');

      let noAction = action.properties.find(resp => resp.key === 'noAction');
      if (result) {

        this.execute(yesAction.value, element, subscriptionCallback, event, elementContext);
      } else {
        this.execute(noAction.value, element, subscriptionCallback, event, elementContext);
      }

    } else if (action.type === RWActionType.LOAD_SELF_DATASOURCE) {

      let queryParam;
      let queryParam2;
      let finalQueryParam: { [key: string]: string } = {};
      let body;
      let bodyObj;
      let finalBody: { [key: string]: string } = {};
      queryParam = action.properties.find(v => v.key === 'queryParam');
      body = action.properties.find(v => v.key === 'body');

      if (queryParam != undefined) {
        queryParam2 = queryParam.value;
      }
      if (queryParam2 != undefined) {
        for (let paramKey of Object.keys(queryParam2)) {
          if (queryParam2[paramKey].indexOf('$ELEMENT.context.data.') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ELEMENT.context.data.' + key;
            const value = element.getContext().data[key];
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else if (queryParam2[paramKey] === '$ELEMENT.value') {
            finalQueryParam[paramKey] = event.target.value;
          } else if (queryParam2[paramKey].indexOf('$POPUP_CONTEXT.data') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else if (queryParam2[paramKey].indexOf('$PAYLOAD.') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$PAYLOAD.', '');
            const varName = '$PAYLOAD.' + key;
            const parts = queryParam2[paramKey].split('.');
            const dataSourceCode = parts[1];
            const parameter = key.replace(dataSourceCode + ".", '');
            const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
            const value = Util.evaluateConcat(parameter, result);
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else {
            finalQueryParam[paramKey] = queryParam2[paramKey]
          }
        }
      }

      if (body != undefined) {
        bodyObj = body.value;
      }
      if (bodyObj != undefined) {
        for (let bodyKey of Object.keys(bodyObj)) {
          if (bodyObj[bodyKey].indexOf('$ELEMENT.context.data.') > -1) {
            const bodyValue = bodyObj[bodyKey];
            const key = bodyValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ELEMENT.context.data.' + key;
            const value = element.getContext().data[key];
            finalBody[bodyKey] = bodyObj[bodyKey].replace(varName, value);
          } else if (bodyObj[bodyKey] === '$ELEMENT.value') {
            finalBody[bodyKey] = event.target.value;
          } else if (bodyObj[bodyKey].indexOf('$POPUP_CONTEXT.data') > -1) {
            const bodyValue = bodyObj[bodyKey];
            const key = bodyValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            finalBody[bodyKey] = bodyObj[bodyKey].replace(varName, value);
          } else if (bodyObj[bodyKey].indexOf('$PAYLOAD.') > -1) {
            const bodyValue = bodyObj[bodyKey];
            const key = bodyValue.replace('$PAYLOAD.', '');
            const varName = '$PAYLOAD.' + key;
            const parts = bodyObj[bodyKey].split('.');
            const dataSourceCode = parts[1];
            const parameter = key.replace(dataSourceCode + ".", '');
            const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
            const value = Util.evaluateConcat(parameter, result);
            finalBody[bodyKey] = bodyObj[bodyKey].replace(varName, value);
          } else {
            finalBody[bodyKey] = bodyObj[bodyKey];
          }
        }
      }

      let responseProperty = action.properties.find(resp => resp.key === 'loadSelfDataSourceResponseProperty');

      if (element.getValueDataSource() !== undefined) { // status.code, {status: {code: 200, message: "hello"}}
        this.dataSourceExecutorService.load(element.getValueDataSource(), this.route, undefined, finalQueryParam, finalBody).subscribe(resp => {


          if (subscriptionCallback !== undefined && subscriptionCallback !== null) {
            subscriptionCallback(resp);
          }

          if (responseProperty === undefined || responseProperty === null) {
            console.log('responseProperty is not defined', action.properties);
            return;
          }
          const responsePropertyValue = Util.getValueFromObjectByPropertyPath(resp, responseProperty.value);
          const actionProperty = action.properties.find(prop => prop.key === responsePropertyValue);
          if (actionProperty === undefined || actionProperty === null) {
            console.warn('No action defined for Load self datasource');
            return;
          }
          console.log('Executing Action', actionProperty.value);
          this.execute(actionProperty.value, element, subscriptionCallback, event, elementContext);
        });
      }
    }
    else if (action.type === RWActionType.UPDATE_SELF_PROPS) {
      const propertyActionType = action.properties.find(v => v.key === 'propertyActionType');
      if (propertyActionType === undefined || propertyActionType === null) {
        return true;
      }
      const propertyActionValue = action.properties.find(v => v.key === 'propertyActionValue');
      if (propertyActionValue === undefined || propertyActionValue === null) {
        return true;
      }
      this.loadSelfUpdateProperties(propertyActionType, propertyActionValue, element);
    } else if (action.type === RWActionType.UPDATE_OTHER_PROPS) {

      var allPathIds: string[] = [];
      let currentPage: RWElement[] = [];
      var selectedElement: RWElement;

      const elementPath = action.properties.find(v => v.key === 'elementPath');

      elementPath.value.split('.').forEach(v => {
        allPathIds.push(v.substring(1, v.length));
      });

      currentPage = this.getCurrentPageDesignData().getChildren();
      selectedElement = this.loadCurrentPageElement(currentPage, allPathIds);

      const propertyActionType = action.properties.find(v => v.key === 'propertyActionType');
      if (propertyActionType === undefined || propertyActionType === null) {
        return true;
      }

      const propertyActionValue = action.properties.find(v => v.key === 'propertyActionValue');
      if (propertyActionValue === undefined || propertyActionValue === null) {
        return true;
      }

      this.loadSelfUpdateProperties(propertyActionType, propertyActionValue, selectedElement);
    } else if (action.type === RWActionType.LOAD_OTHER_DATASOURCE) {

      let queryParam;
      let queryParam2;
      let finalQueryParam: { [key: string]: string } = {};
      let body;
      let bodyObj;
      let finalBody: { [key: string]: string } = {};
      queryParam = action.properties.find(v => v.key === 'queryParam');
      body = action.properties.find(v => v.key === 'body');

      if (queryParam != undefined) {
        queryParam2 = queryParam.value;
      }
      if (queryParam2 != undefined) {
        for (let paramKey of Object.keys(queryParam2)) {
          if (queryParam2[paramKey].indexOf('$ELEMENT.context.data.') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ELEMENT.context.data.' + key;
            const value = element.getContext().data[key];
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else if (queryParam2[paramKey] === '$ELEMENT.value') {
            finalQueryParam[paramKey] = event.target.value;
          } else if (queryParam2[paramKey].indexOf('$POPUP_CONTEXT.data') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else if (queryParam2[paramKey].indexOf('$PAYLOAD.') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$PAYLOAD.', '');
            const varName = '$PAYLOAD.' + key;
            const parts = queryParam2[paramKey].split('.');
            const dataSourceCode = parts[1];
            const parameter = key.replace(dataSourceCode + ".", '');
            const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
            const value = Util.evaluateConcat(parameter, result);
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else {
            finalQueryParam[paramKey] = queryParam2[paramKey]
          }
        }
      }

      if (body != undefined) {
        bodyObj = body.value;
      }
      if (bodyObj != undefined) {
        for (let bodyKey of Object.keys(bodyObj)) {
          if (bodyObj[bodyKey].indexOf('$ELEMENT.context.data.') > -1) {
            const bodyValue = bodyObj[bodyKey];
            const key = bodyValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ELEMENT.context.data.' + key;
            const value = element.getContext().data[key];
            finalBody[bodyKey] = bodyObj[bodyKey].replace(varName, value);
          } else if (bodyObj[bodyKey] === '$ELEMENT.value') {
            finalBody[bodyKey] = event.target.value;
          } else if (bodyObj[bodyKey].indexOf('$POPUP_CONTEXT.data') > -1) {
            const bodyValue = bodyObj[bodyKey];
            const key = bodyValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            finalBody[bodyKey] = bodyObj[bodyKey].replace(varName, value);
          } else if (bodyObj[bodyKey].indexOf('$PAYLOAD.') > -1) {
            const bodyValue = bodyObj[bodyKey];
            const key = bodyValue.replace('$PAYLOAD.', '');
            const varName = '$PAYLOAD.' + key;
            const parts = bodyObj[bodyKey].split('.');
            const dataSourceCode = parts[1];
            const parameter = key.replace(dataSourceCode + ".", '');
            const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
            const value = Util.evaluateConcat(parameter, result);
            finalBody[bodyKey] = bodyObj[bodyKey].replace(varName, value);
          } else {
            finalBody[bodyKey] = bodyObj[bodyKey];
          }
        }
      }

      let rwCallback = action.properties.find(resp => resp.key === 'rw-callback');
      if (rwCallback !== undefined && rwCallback !== null) {
        this.execute(rwCallback.value, element, subscriptionCallback, event, elementContext);
      }

      const dataSourceCode = action.properties.find(v => v.key === 'dataSource');
      let responseProperty = action.properties.find(resp => resp.key === 'loadOtherDataSourceResponseProperty');

      if (dataSourceCode.value !== undefined && dataSourceCode.value !== null && dataSourceCode.value !== '') {
        this.dataSourceExecutorService.load(
          {
            dataSource: dataSourceCode.value,
            inherited: false,
            property: '',
            shouldUseDataSourceResult: true,
            enableLocalSubscription: false
          },
          this.route, undefined, finalQueryParam, finalBody, undefined, undefined, element
        ).subscribe(resp => {


          if (subscriptionCallback !== undefined && subscriptionCallback !== null) {
            subscriptionCallback(resp);
          }

          if (responseProperty === undefined || responseProperty === null) {
            console.log('responseProperty for other is not defined', action.properties);
            return;
          }
          const responsePropertyValue = Util.getValueFromObjectByPropertyPath(resp, responseProperty.value);

          const actionProperty = action.properties.find(prop => prop.key === responsePropertyValue);

          if (actionProperty === undefined || actionProperty === null) {
            console.warn('No action defined for Load other datasource');
            return;
          }

          this.execute(actionProperty.value, element, subscriptionCallback, event, elementContext);

        });
      }
    } else if (action.type === RWActionType.STORE_OTHER_DATASOURCE) {

      let queryParam;
      let queryParam2;
      let finalQueryParam: { [key: string]: string } = {};
      let body;
      let bodyObj;
      let finalBody: { [key: string]: any } = {};

      queryParam = action.properties.find(v => v.key === 'queryParam');
      body = action.properties.find(v => v.key === 'body');


      if (queryParam != undefined) {
        queryParam2 = queryParam.value;
      }

      if (queryParam2 != undefined) {
        for (let paramKey of Object.keys(queryParam2)) {
          if (queryParam2[paramKey].indexOf('$ELEMENT.context.data.') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ELEMENT.context.data.' + key;
            const value = element.getContext().data[key];
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else if (queryParam2[paramKey] === '$ELEMENT.value') {
            finalQueryParam[paramKey] = event.target.value;
          } else if (queryParam2[paramKey].indexOf('$POPUP_CONTEXT.data') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else if (queryParam2[paramKey].indexOf('$PAYLOAD.') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$PAYLOAD.', '');
            const varName = '$PAYLOAD.' + key;
            const parts = queryParam2[paramKey].split('.');
            const dataSourceCode = parts[1];
            const parameter = key.replace(dataSourceCode + ".", '');
            const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
            const value = Util.evaluateConcat(parameter, result);
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else {
            finalQueryParam[paramKey] = queryParam2[paramKey]
          }
        }
      }

      if (body != undefined) {
        bodyObj = body.value;
      }
      if (bodyObj != undefined) {
        for (let bodyKey of Object.keys(bodyObj)) {
          if (bodyObj[bodyKey].indexOf('$ELEMENT.context.data.') > -1) {
            const bodyValue = bodyObj[bodyKey];
            const key = bodyValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ELEMENT.context.data.' + key;
            const value = element.getContext().data[key];
            finalBody[bodyKey] = bodyObj[bodyKey].replace(varName, value);
          } else if (bodyObj[bodyKey] === '$ELEMENT.value') {
            finalBody[bodyKey] = event.target.value;
          } else if (bodyObj[bodyKey].indexOf('$POPUP_CONTEXT.data') > -1) {
            const bodyValue = bodyObj[bodyKey];
            const key = bodyValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            finalBody[bodyKey] = bodyObj[bodyKey].replace(varName, value);
          } else if (bodyObj[bodyKey].indexOf('$PAYLOAD.') > -1) {
            const bodyValue = bodyObj[bodyKey];
            const key = bodyValue.replace('$PAYLOAD.', '');
            const varName = '$PAYLOAD.' + key;
            const parts = bodyObj[bodyKey].split('.');
            const dataSourceCode = parts[1];
            const parameter = key.replace(dataSourceCode + ".", '');
            const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
            const value = Util.evaluateConcat(parameter, result);
            finalBody[bodyKey] = bodyObj[bodyKey].replace(varName, value);
          } else if (bodyObj[bodyKey].indexOf('$DS.') > -1) {
            const bodyValue = bodyObj[bodyKey];
            const key = bodyValue.replace('$DS.', '');
            const varName = '$DS.' + key;
            const dataSourceCode = key.split('.')[0];
            const finalPath = key.replace(dataSourceCode + '.response.', '');
            if (element.getExtraInfo() === null) {
              element.setExtraInfo('')
            }
            let bodyDataValue = JSON.parse(JSON.stringify(element.getExtraInfo()));
            let value = Util.evaluateConcat(finalPath, bodyDataValue);
            finalBody[bodyKey] = value;
          } else {
            finalBody[bodyKey] = bodyObj[bodyKey];
          }
        }
      }

      const dataSourceCode = action.properties.find(v => v.key === 'dataSource');

      let responseProperty = action.properties.find(resp => resp.key === 'loadStoreOtherDataSoureResponseProperty');

      const validation = this.validationToggle(this.elementPropertySerice.getPayLoadValidations(dataSourceCode.value));
      this.elementPropertySerice.setFormValidation(dataSourceCode.value, validation);
      if (validation) {
        if (dataSourceCode.value !== undefined && dataSourceCode.value !== null && dataSourceCode.value !== '') {
          let bodyData = this.elementPropertySerice.getPayLoadData()[dataSourceCode.value];
          if (bodyData === undefined || bodyData === null) {
            bodyData = JSON.parse('{}');
          }
          let finalBodyData = Object.assign(bodyData, finalBody);

          this.dataSourceExecutorService.load(
            {
              dataSource: dataSourceCode.value,
              inherited: false,
              property: '',
              shouldUseDataSourceResult: true,
              enableLocalSubscription: false
            },
            this.route, undefined, finalQueryParam, finalBodyData
          ).subscribe(resp => {

            if (responseProperty === undefined || responseProperty === null) {
              console.log('responseProperty is not defined', action.properties);
              return;
            }
            const responsePropertyValue = Util.getValueFromObjectByPropertyPath(resp, responseProperty.value);
            const actionProperty = action.properties.find(prop => prop.key === responsePropertyValue);
            if (actionProperty === undefined || actionProperty === null) {
              console.warn('No action defined for Store other datasource');
              return;
            }
            this.execute(actionProperty.value, element, subscriptionCallback, event, elementContext);
          });
        }
      } else {
        console.warn("In StoreOtherDataSource, DataSource code not found or Missing one field in the form");
      }
    } else if (action.type === RWActionType.STORE_SELF_DATASOURCE) {
      let removeKey: string;
      let removeKeySplit;
      let finalRemovedKey;
      let queryParam;
      let queryParam2;
      let finalQueryParam: { [key: string]: string } = {};
      queryParam = action.properties.find(v => v.key === 'queryParam');

      if (queryParam != undefined) {
        queryParam2 = queryParam.value;
      }
      if (queryParam2 != undefined) {
        for (let paramKey of Object.keys(queryParam2)) {
          if (queryParam2[paramKey].indexOf('$ELEMENT.context.data.') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ELEMENT.context.data.' + key;
            const value = element.getContext().data[key];
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else if (queryParam2[paramKey] === '$ELEMENT.value') {
            finalQueryParam[paramKey] = event.target.value;
          } else if (queryParam2[paramKey].indexOf('$POPUP_CONTEXT.data') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else if (queryParam2[paramKey].indexOf('$PAYLOAD.') > -1) {
            const queryParamValue = queryParam2[paramKey];
            const key = queryParamValue.replace('$PAYLOAD.', '');
            const varName = '$PAYLOAD.' + key;
            const parts = queryParam2[paramKey].split('.');
            const dataSourceCode = parts[1];
            const parameter = key.replace(dataSourceCode + ".", '');
            const result = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
            const value = Util.evaluateConcat(parameter, result);
            finalQueryParam[paramKey] = queryParam2[paramKey].replace(varName, value);
          } else {
            finalQueryParam[paramKey] = queryParam2[paramKey]
          }
        }
      }
      this.elementPropertySerice.payLoadElementDeleteChanged.subscribe(resp => {
        if (resp.payloadProperty != undefined) {
          removeKey = resp.payloadProperty;
          removeKeySplit = removeKey.split('.');
          finalRemovedKey = removeKeySplit[removeKeySplit.length - 1];
        }
      })

      // let dataSourceCode = Object.keys(this.elementPropertySerice.getPayLoadData())[0];
      let dataSourceCode = undefined;
      let responseProperty = action.properties.find(resp => resp.key === 'loadStoreSelfDataSourceResponseProperty');
      if (element.getPayLoadDataSourceName() !== undefined && element.getPayLoadDataSourceName() !== null && element.getPayLoadDataSourceName() !== '') {
        dataSourceCode = element.getPayLoadDataSourceName();
      }

      const validation = this.validationToggle(this.elementPropertySerice.getPayLoadValidations(dataSourceCode));
      this.elementPropertySerice.setFormValidation(dataSourceCode, validation);
      if (validation) {
        if (dataSourceCode !== undefined && dataSourceCode !== null && dataSourceCode !== '') {
          let bodyData = this.elementPropertySerice.getPayLoadData()[dataSourceCode];
          console.log('bodyKey', dataSourceCode, 'elementDataSourceName', element.getPayLoadDataSourceName(), 'data', bodyData);

          if (finalRemovedKey !== undefined) {
            this.onFilter(bodyData, finalRemovedKey);
          }

          this.dataSourceExecutorService.load(
            {
              dataSource: dataSourceCode,
              inherited: false,
              property: '',
              shouldUseDataSourceResult: true,
              enableLocalSubscription: false
            },
            this.route, undefined, finalQueryParam, bodyData
          ).subscribe(resp => {

            if (responseProperty === undefined || responseProperty === null) {
              console.log('responseProperty is not defined', action.properties);
              return;
            }
            const responsePropertyValue = Util.getValueFromObjectByPropertyPath(resp, responseProperty.value);
            const actionProperty = action.properties.find(prop => prop.key === responsePropertyValue);
            if (actionProperty === undefined || actionProperty === null) {
              console.warn('No action defined for Store self datasource');
              return;
            }
            this.execute(actionProperty.value, element, subscriptionCallback, event, elementContext);
          });
        }
      } else {
        console.warn("DataSource code not found or Missing one field in the form");

      }
    } else if (action.type === RWActionType.HIDE_POPUP) {
      console.log("HIDEPOPUP", action)
      $('#modal-design1').modal('hide');
      let rwCallback = action.properties.find(resp => resp.key === 'rw-callback');
      if (rwCallback !== undefined && rwCallback !== null) {
        this.execute(rwCallback.value, element, subscriptionCallback, event, elementContext);
      }
    } else if (action.type === RWActionType.SHOW_LOADING) {
      this.elementPropertySerice.showLoading();
    } else if (action.type === RWActionType.HIDE_LOADING) {
      this.elementPropertySerice.hideLoading();
    } else if (action.type === RWActionType.OPEN_MAP) {
      let finalMapParam: { [key: string]: string } = {};
      let mapMarker = action.properties.find(resp => resp.key === 'mapMarker');
      if (mapMarker !== undefined && mapMarker !== null) {
        for (let paramKey of Object.keys(mapMarker.value)) {
          if (mapMarker.value[paramKey].indexOf('$ELEMENT.context.data.') > -1) {
            const queryParamValue = mapMarker.value[paramKey];
            const key = queryParamValue.replace('$ELEMENT.context.data.', '');
            const varName = '$ELEMENT.context.data.' + key;
            const value = element.getContext().data[key];
            finalMapParam[paramKey] = mapMarker.value[paramKey].replace(varName, value);
          }
        }
        let LATITUDE = finalMapParam['LATITUDE'];
        let LONGITUDE = finalMapParam['LONGITUDE'];
        window.open("https://www.google.com/maps/search/?api=1&query=" + LATITUDE + "," + LONGITUDE);
      }
    } else if (action.type === RWActionType.DOWNLOAD_IMAGE) {
      let fileNames
      let fileName = ''
      let finalFileNames = []
      let formatSplit = element.getValue().split('.')
      let formatValue = formatSplit[formatSplit.length - 1]
      fileNames = action.properties.find(v => v.key === 'fileName');
      if (fileNames != undefined) {
        fileNames = fileNames.value;
      }
      for (var index in fileNames) {
        if (fileNames[index].indexOf('$POPUP_CONTEXT.data') > -1) {
          const queryParamValue = fileNames[index];
          const key = queryParamValue.replace('$POPUP_CONTEXT.data.', '');
          const varName = '$POPUP_CONTEXT.data.' + key;
          let value = '';
          if (this.popupContextData !== undefined && this.popupContextData !== null) {
            value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
          }
          finalFileNames[index] = fileNames[index].replace(varName, value);
        }
      }
      fileName = finalFileNames.join('_')
      saveAs(element.getValue(), "QR_"+fileName + "." + formatValue)
    }
  }

  loadCurrentPageElement(element: RWElement[], pathIds: string[]): RWElement {
    let currentPageRows: RWElement;
    if (element.length === 0) {
      return;
    }
    for (var i = 0; i < element.length; i++) {
      for (var j = 0; j < pathIds.length; j++) {
        if (element[i].getId() === pathIds[j]) {
          currentPageRows = element[i];
          if (currentPageRows.getChildren().length > 0) {
            if (this.loadCurrentPageElement(currentPageRows.getChildren(), pathIds) != undefined) {
              currentPageRows = this.loadCurrentPageElement(currentPageRows.getChildren(), pathIds);
            }
          }
        }
      }
    }
    return currentPageRows;
  }

  loadSelfUpdateProperties(propertyActionType: RWActionProperty, propertyActionValue: RWActionProperty, element: RWElement) {
    if (propertyActionType.value === RWElementPropertyAction.AddClass) {
      element.addClass(propertyActionValue.value);
    }
    if (propertyActionType.value === RWElementPropertyAction.RemoveClass) {
      element.removeClass(propertyActionValue.value);
    }
    if (propertyActionType.value === RWElementPropertyAction.ToggleClass) {
      element.toggleClass(propertyActionValue.value);
    }
  }

  validationToggle(validationObj: any): boolean {
    if (validationObj === undefined || Object.keys(validationObj).length === 0) {
      return true;
    }
    for (var key in validationObj) {
      if (!validationObj[key]) return false;
    }
    return true;
  }

  onFilter(finalObj: any, removeKey: string) {
    for (let [key, values] of Object.entries(finalObj)) {
      if (key === removeKey) {
        this.removedValues = finalObj[key];
        if (Array.isArray(finalObj[key])) {
          this.removedValues = this.removedValues.filter(item => item._RW_DELETE_RECORD !== true);
          finalObj[key] = this.removedValues;
        }
      } else {
        try {
          this.onFilter(values, removeKey);
        } catch (e) {
          console.log("erroe", e);
        }
      }
    }
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}
