import { ElementPropertyValueType } from "./ElementPropertyValueType";
export class ElementPropertyField {
    name: string;
    displayName: string;
    description: string;
    setterMethodName: string;
    getterMethodName: string;
    placeholder: string = '';
    value?: any;
    type: ElementPropertyValueType = ElementPropertyValueType.TEXT;
    weight: number = 1;
    possibleValues: any[] = [];
    children: ElementPropertyField[] = [];
    constructor(name?: string, displayName?: string, setterMethodName?: string, getterMethodName?: string, value?: string, possibleValues?: string[], type?: ElementPropertyValueType, placeholder?: string) {
        this.name = name;
        this.displayName = displayName;
        this.setterMethodName = setterMethodName;
        this.getterMethodName = getterMethodName;
        this.value = value;
        this.possibleValues = possibleValues;
        if (type !== undefined && type !== null) {
            this.type = type;
        }
        if (placeholder !== undefined && placeholder !== null) {
            this.placeholder = placeholder;
        }
    }
}
