export class RWActionProperty {
  key: string;
  type: string;
  value: any;
  
  constructor(key: string, type: string, value: any) {
    this.key = key;
    this.type = type;
    this.value = value;
  }
}

