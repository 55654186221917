<ng-container *ngIf="displayMode === 'TOOLBOX'">
    <div class="toolbox-control-item">
        <div class="toolbox-control-item-icon" *ngIf="elementData.getToolboxIcon() !== undefined">
            <img src="{{elementData.getToolboxIcon()}}" />
        </div>
        <div class="toolbox-control-item-text">{{elementData.getToolboxName()}}</div>
    </div>
</ng-container>
<ng-container *ngIf="displayMode !== 'TOOLBOX'">
    <div class="dynamic-action-btns">
        <button class="btn btn-success btn-sm dynamic-action-btn" *ngIf="elementData.getEnableDynamicallyDuplicate()"
            (click)="dynamicAddBtnClicked()">Add</button>
        <button class="btn btn-danger btn-sm dynamic-action-btn" *ngIf="elementData.getEnableDynamicallyRemove()"
            (click)="dynamicRemoveBtnClicked()">Remove</button>
    </div>
    <ng-container *ngIf="elementData.getElementType().toString() !== 'CONTROL'">
        <div class="layout-wrapper-actions" *ngIf="displayMode === 'DESIGN'">
            <div class="btn-group">
                <button class="btn btn-sm btn-primary" (click)="addRowClicked()">+ Row</button>
                <div class="btn btn-sm btn-secondary column-btn" *ngIf="canShowAddCol()">+
                    Column
                    <div class="col-content">
                        <div class="col-item p-2" *ngFor="let col of columns" (click)="addColClicked(col)">{{col}}</div>
                    </div>
                </div>
                <button class="btn btn-sm btn-danger" *ngIf="elementData.getLevel() > 0"
                    (click)="removeClicked()">-</button>
                <button class="fa fa-clone btn btn-sm btn-light help-block code" type="button" (click)="copyClicked()">
                </button>
                <button class="btn btn-sm btn-light help-block code" type="button"
                    (click)="settingsClicked($event, elementData)">
                    <i class="fa fa-cog"></i>
                    <!-- .{{ elementData.getAttribute('class') }}
                #{{ elementData.getAttribute('id') }} -->
                </button>
            </div>
        </div>
        <ng-container *ngIf="elementData.getElementType().toString() === 'DIV'">
            <ng-container
                *ngIf="elementData.getValue() !== undefined && elementData.getValue() !== null && elementData.getValue() !== ''">
                {{elementData.getValue()}}
            </ng-container>
            <ng-container
                *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                {{elementData.getContentText()}}
            </ng-container>
            <ng-container *ngFor="let children of elementData.getChildren()">
                <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                    (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                    (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                </app-rw-element-renderer>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="elementData.getElementType().toString() === 'VERTICALACCORDION'">
            <div [id]="'v_accordion' + elementData.getId()">
                <div class="card">
                    <div class="card-header" [id]="'head' + elementData.getId()">
                        <h5 class="mb-0">
                            <button type="button" class="btn btn-link" data-toggle="collapse"
                                [attr.data-target]="'#body' + elementData.getId()" aria-expanded="true"
                                aria-controls="'body' + elementData.getId()">
                                <ng-container
                                    *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                                    {{elementData.getVerticalAccordionHeaderText()}}
                                </ng-container>
                            </button>
                        </h5>
                    </div>
                    <div [id]="'body' + elementData.getId()" class="collapse show"
                        [attr.aria-labelledby]="'head' + elementData.getId()"
                        [attr.data-parent]="'#v_accordion' + elementData.getId()">
                        <div class="card-body" [id]="elementData.getId()">
                            <ng-container
                                *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                                {{elementData.getContentText()}}
                                <ng-container *ngFor="let children of elementData.getChildren()">
                                    <app-rw-element-renderer [displayMode]="displayMode"
                                        (remove)="removeChildClicked($event)" (copyPath)="copyPathClicked($event)"
                                        (dynamicDuplicate)="dynamicDuplicateAction($event)"
                                        (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                                    </app-rw-element-renderer>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="elementData.getElementType().toString() === 'ANCHOR'">
            <ng-container *ngIf="displayMode === 'DESIGN'">
                <span [class]="elementData.getAttribute('class')">
                    {{elementData.getContentText()}}
                    <ng-container *ngFor="let children of elementData.getChildren()">
                        <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                            (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                            (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                        </app-rw-element-renderer>
                    </ng-container>
                </span>
            </ng-container>
            <ng-container *ngIf="displayMode === 'VIEW'">
                <ng-container *ngIf="elementData.getAnchorType() === 'Internal' || elementData.getAnchorType() === ''|| elementData.getAnchorType() === null || elementData.getAnchorType() === undefined; else elseBlock" >
                <a [class]="elementData.getAttribute('class')" [appEventListener]="getEventListenerInput()" [routerLink]="elementData.getAttribute('href')" >
                    {{elementData.getContentText()}}
                    <ng-container *ngFor="let children of elementData.getChildren()">
                        <app-rw-element-renderer *hasAnyRole="children.getRole()" [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                            (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                            (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                        </app-rw-element-renderer>
                    </ng-container>
                </a>
            </ng-container>

            <ng-template #elseBlock>
                <a [class]="elementData.getAttribute('class')" [href]="elementData.getAttribute('href')" >
                    {{elementData.getContentText()}}
                    <ng-container *ngFor="let children of elementData.getChildren()">
                        <app-rw-element-renderer *hasAnyRole="children.getRole()" [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                            (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                            (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                        </app-rw-element-renderer>
                    </ng-container>
                </a>
            </ng-template>

            </ng-container>
        </ng-container>

        <ng-container *ngIf="elementData.getElementType().toString() === 'H1'">
            <h1 [class]="elementData.getAttribute('class')">
                <ng-container
                    *ngIf="elementData.getValue() !== undefined && elementData.getValue() !== null && elementData.getValue() !== ''">
                    {{elementData.getValue()}}
                </ng-container>
                <ng-container
                    *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                    {{elementData.getContentText()}}
                </ng-container>
                <ng-container *ngFor="let children of elementData.getChildren()">
                    <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                        (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                        (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                    </app-rw-element-renderer>
                </ng-container>
            </h1>
        </ng-container>
        <ng-container *ngIf="elementData.getElementType().toString() === 'H2'">
            <h2 [class]="elementData.getAttribute('class')">
                <ng-container
                    *ngIf="elementData.getValue() !== undefined && elementData.getValue() !== null && elementData.getValue() !== ''">
                    {{elementData.getValue()}}
                </ng-container>
                <ng-container
                    *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                    {{elementData.getContentText()}}
                </ng-container>
                <ng-container *ngFor="let children of elementData.getChildren()">
                    <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                        (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                        (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                    </app-rw-element-renderer>
                </ng-container>
            </h2>
        </ng-container>
        <ng-container *ngIf="elementData.getElementType().toString() === 'H3'">
            <h3 [class]="elementData.getAttribute('class')">
                <ng-container
                    *ngIf="elementData.getValue() !== undefined && elementData.getValue() !== null && elementData.getValue() !== ''">
                    {{elementData.getValue()}}
                </ng-container>
                <ng-container
                    *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                    {{elementData.getContentText()}}
                </ng-container>
                <ng-container *ngFor="let children of elementData.getChildren()">
                    <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                        (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                        (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                    </app-rw-element-renderer>
                </ng-container>
            </h3>
        </ng-container>
        <ng-container *ngIf="elementData.getElementType().toString() === 'H4'">
            <h4 [class]="elementData.getAttribute('class')">
                <ng-container
                    *ngIf="elementData.getValue() !== undefined && elementData.getValue() !== null && elementData.getValue() !== ''">
                    {{elementData.getValue()}}
                </ng-container>
                <ng-container
                    *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                    {{elementData.getContentText()}}
                </ng-container>
                <ng-container *ngFor="let children of elementData.getChildren()">
                    <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                        (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                        (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                    </app-rw-element-renderer>
                </ng-container>
            </h4>
        </ng-container>
        <ng-container *ngIf="elementData.getElementType().toString() === 'H5'">
            <h5 [class]="elementData.getAttribute('class')">
                <ng-container
                    *ngIf="elementData.getValue() !== undefined && elementData.getValue() !== null && elementData.getValue() !== ''">
                    {{elementData.getValue()}}
                </ng-container>
                <ng-container
                    *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                    {{elementData.getContentText()}}
                </ng-container>
                <ng-container *ngFor="let children of elementData.getChildren()">
                    <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                        (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                        (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                    </app-rw-element-renderer>
                </ng-container>
            </h5>
        </ng-container>
        <ng-container *ngIf="elementData.getElementType().toString() === 'H6'">
            <h6 [class]="elementData.getAttribute('class')" [appEventListener]="getEventListenerInput()" >
                <ng-container
                    *ngIf="elementData.getValue() !== undefined && elementData.getValue() !== null && elementData.getValue() !== ''">
                    {{elementData.getValue()}}
                </ng-container>
                <ng-container
                    *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                    {{elementData.getContentText()}}
                </ng-container>
                <ng-container *ngFor="let children of elementData.getChildren()">
                    <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                        (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                        (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                    </app-rw-element-renderer>
                </ng-container>
            </h6>
        </ng-container>
        <ng-container *ngIf="elementData.getElementType().toString() === 'I'">
            <i>
                <ng-container
                    *ngIf="elementData.getValue() !== undefined && elementData.getValue() !== null && elementData.getValue() !== ''">
                    {{elementData.getValue()}}
                </ng-container>
                <ng-container
                    *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                    {{elementData.getContentText()}}
                </ng-container>
                <ng-container *ngFor="let children of elementData.getChildren()">
                    <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                        (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                        (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                    </app-rw-element-renderer>
                </ng-container>
            </i>
        </ng-container>
        <ng-container *ngIf="elementData.getElementType().toString() === 'P'">
            <p>
                <ng-container
                    *ngIf="elementData.getValue() !== undefined && elementData.getValue() !== null && elementData.getValue() !== ''">
                    {{elementData.getValue()}}
                </ng-container>
                <ng-container
                    *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                    {{elementData.getContentText()}}
                </ng-container>
                <ng-container *ngFor="let children of elementData.getChildren()">
                    <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                        (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                        (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                    </app-rw-element-renderer>
                </ng-container>
            </p>
        </ng-container>
        <ng-container *ngIf="elementData.getElementType().toString() === 'IMAGE'">
            <div class="control-wrapper">
                <img [class]="elementData.getAttribute('class')" [src]="elementData.getAttribute('src')"
                    [height]="elementData.getAttribute('height')" [width]="elementData.getAttribute('width')">
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getElementType().toString() === 'LABEL'">
            <ng-container
                *ngIf="elementData.getValue() !== undefined && elementData.getValue() !== null && elementData.getValue() !== ''">
                {{elementData.getValue()}}
            </ng-container>
            <ng-container
                *ngIf="elementData.getValue() === undefined || elementData.getValue() === null || elementData.getValue() === ''">
                {{elementData.getContentText()}}
            </ng-container>
            <ng-container *ngFor="let children of elementData.getChildren()">
                <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                    (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                    (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                </app-rw-element-renderer>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="elementData.getElementType().toString() === 'CONTROL'">
        <div class="layout-wrapper-actions" *ngIf="displayMode === 'DESIGN'">
            <div class="btn-group">
                <button class="btn btn-sm btn-danger" (click)="removeClicked()">-</button>
                <button class="fa fa-clone btn btn-sm btn-light help-block code" type="button" (click)="copyClicked()">
                </button>
                <button class="btn btn-sm btn-light help-block code" type="button"
                    (click)="settingsClicked($event, elementData)">
                    <i class="fa fa-cog"></i>
                    <!-- .{{ elementData.getAttribute('class') }}
                    #{{ elementData.getAttribute('id') }} -->
                </button>
            </div>
        </div>
        <ng-container *ngIf="elementData.getControlType().toString() === 'FORM'">
            <form (ngSubmit)="onFormSubmit($event)">
                {{elementData.getContentText()}}
                <ng-container *ngFor="let children of elementData.getChildren()">
                    <app-rw-element-renderer [displayMode]="displayMode" (remove)="removeChildClicked($event)"
                        (copyPath)="copyPathClicked($event)" (dynamicDuplicate)="dynamicDuplicateAction($event)"
                        (dynamicRemove)="dynamicRemoveAction($event)" [elementData]="children">
                    </app-rw-element-renderer>
                </ng-container>
            </form>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'TEXT'">
            <div class="control-wrapper">
                <input type="text" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')"  [readonly]="elementData.getAttribute('readonly')" [disabled]="elementData.getAttribute('disabled')"
                    [placeholder]="elementData.getAttribute('placeholder')" [name]="elementData.getAttribute('name')" [attr.minlength]="elementData.getAttribute('minlength')" [attr.maxlength]="elementData.getAttribute('maxlength')"
                    [value]="elementData.getValue()" (change)="changedElement($event.target.value,elementData)" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'CHECKBOX'">
            <div class="control-wrapper">
                <input type="checkbox" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')" [readonly]="elementData.getAttribute('readonly')" [disabled]="elementData.getAttribute('disabled')"
                    [checked]="elementData.getAttribute('checked')" [name]="elementData.getAttribute('name')"  [value]="elementData.getCheckboxValue()"
                    (change)="changedElement($event.target.value,elementData,$event)" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'RADIO'">
            <div class="control-wrapper">
                <input type="radio" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')" [readonly]="elementData.getAttribute('readonly')" [disabled]="elementData.getAttribute('disabled')"
                    [value]="elementData.getValue()" [checked]="elementData.getAttribute('checked')"
                    [name]="elementData.getAttribute('name')"
                    (change)="changedElement($event.target.value,elementData,$event)" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'PASSWORD'">
            <div class="control-wrapper">
                <input type="password" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')" [readonly]="elementData.getAttribute('readonly')"
                    [placeholder]="elementData.getAttribute('placeholder')" [name]="elementData.getAttribute('name')"
                    (change)="changedElement($event.target.value,elementData)" [value]="elementData.getValue()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'EMAIL'">
            <div class="control-wrapper">
                <input type="email" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')" [readonly]="elementData.getAttribute('readonly')"
                     [placeholder]="elementData.getAttribute('placeholder')" [disabled]="elementData.getAttribute('disabled')"
                    [name]="elementData.getAttribute('name')" (change)="changedElement($event.target.value,elementData)"
                    [value]="elementData.getValue()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'FILE'">
            <div class="control-wrapper" *ngIf="elementData.getFileType() === 'Single' || elementData.getFileType() === undefined; else elseBlock">
                <input type="file" [id]="elementData.getId()" [appEventListener]="getEventListenerInput()"
                    [class]="elementData.getAttribute('class')" [name]="elementData.getAttribute('name')"
                    (change)="fileSelected($event)" />
            </div>
            <ng-template #elseBlock>
                <div class="control-wrapper" >
                    <input type="file" [id]="elementData.getId()" [appEventListener]="getEventListenerInput()"
                        [class]="elementData.getAttribute('class')" multiple [name]="elementData.getAttribute('name')"
                        (change)="fileSelected($event)" />
                </div> 
            </ng-template>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'RANGE'">
            <div class="control-wrapper">
                <input type="range" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')"
                    [min]="elementData.getAttribute('min')" [max]="elementData.getAttribute('max')"
                    (change)="changedElement($event.target.value,elementData)" [name]="elementData.getAttribute('name')"
                    [value]="elementData.getValue()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'DATE'">
            <div class="control-wrapper">
                <input type="date" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')" [readonly]="elementData.getAttribute('readonly')"
                    [name]="elementData.getAttribute('name')" [value]="elementData.getValue()" [disabled]="elementData.getAttribute('disabled')"
                    (change)="changedElement($event.target.value,elementData)" [min] = "elementData.getMinDate()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'SEARCH'">
            <div class="control-wrapper">
                <input type="search" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')" [readonly]="elementData.getAttribute('readonly')"
                    [name]="elementData.getAttribute('name')" [placeholder]="elementData.getAttribute('placeholder')" [disabled]="elementData.getAttribute('disabled')"
                    (change)="changedElement($event.target.value,elementData)" [value]="elementData.getValue()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'URL'">
            <div class="control-wrapper">
                <input type="url" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')"
                    [placeholder]="elementData.getAttribute('placeholder')" [name]="elementData.getAttribute('name')"
                    (change)="changedElement($event.target.value,elementData)" [value]="elementData.getValue()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'COLOR'">
            <div class="control-wrapper">
                <input type="color" [class]="elementData.getAttribute('class')" [value]="elementData.getValue()" />
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'NUMBER'">
            <div class="control-wrapper">
                <input type="number" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')" [readonly]="elementData.getAttribute('readonly')"
                    [placeholder]="elementData.getAttribute('placeholder')" [name]="elementData.getAttribute('name')" [disabled]="elementData.getAttribute('disabled')"
                    (change)="changedElement($event.target.value,elementData)" [value]="elementData.getValue()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'DATETIME'">
            <div class="control-wrapper">
                <input type="datetime-local" [appEventListener]="getEventListenerInput()" [readonly]="elementData.getAttribute('readonly')"
                    [class]="elementData.getAttribute('class')" [name]="elementData.getAttribute('name')" [disabled]="elementData.getAttribute('disabled')"
                    (change)="changedElement($event.target.value,elementData)" [value]="elementData.getValue()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'TEL'">
            <div class="control-wrapper">
                <input type="tel" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')" [readonly]="elementData.getAttribute('readonly')"
                    [placeholder]="elementData.getAttribute('placeholder')" [name]="elementData.getAttribute('name')" [disabled]="elementData.getAttribute('disabled')"
                    [value]="elementData.getValue()" (change)="changedElement($event.target.value,elementData)"
                     />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'MONTH'">
            <div class="control-wrapper">
                <input type="month" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')"  [readonly]="elementData.getAttribute('readonly')"
                    (change)="changedElement($event.target.value,elementData)" [name]="elementData.getAttribute('name')" [disabled]="elementData.getAttribute('disabled')"
                    [value]="elementData.getValue()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'WEEK'">
            <div class="control-wrapper">
                <input type="week" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')" [readonly]="elementData.getAttribute('readonly')"
                    (change)="changedElement($event.target.value,elementData)" [name]="elementData.getAttribute('name')" [disabled]="elementData.getAttribute('disabled')"
                    [value]="elementData.getValue()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'TIME'">
            <div class="control-wrapper">
                <input type="time" [appEventListener]="getEventListenerInput()" [class]="elementData.getAttribute('class')"  [readonly]="elementData.getAttribute('readonly')"
                    (change)="changedElement($event.target.value,elementData)" [name]="elementData.getAttribute('name')" [disabled]="elementData.getAttribute('disabled')"
                    [value]="elementData.getValue()" />
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'SUBMIT'">
            <div class="control-wrapper">
                <button [class]="elementData.getAttribute('class')" type="submit">
                    {{elementData.getContentText()}}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'RESET'">
            <div class="control-wrapper">
                <button [class]="elementData.getAttribute('class')" [appEventListener]="getEventListenerInput()" type="reset">
                    {{elementData.getContentText()}}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'BUTTON'">
            <div class="control-wrapper">
                <button [class]="elementData.getAttribute('class')" [attr.data-voice-text]="elementData.getVoiceText()" [appEventListener]="getEventListenerInput()" type="button">
                    {{elementData.getContentText()}}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'TABLE'">
            <div class="control-wrapper">
                <angular-tree-table [tableData]="tableData" [class]="elementData.getAttribute('class')">
                </angular-tree-table>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'TEXTAREA'">
            <div class="control-wrapper">
                <textarea [appEventListener]="getEventListenerInput()" [name]="elementData.getAttribute('name')"  [readonly]="elementData.getAttribute('readonly')"
                    [class]="elementData.getAttribute('class')" [placeholder]="elementData.getAttribute('placeholder')" [disabled]="elementData.getAttribute('disabled')"
                    [value]="elementData.getValue()" (change)="changedElement($event.target.value,elementData)">
                </textarea>
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="elementData.getControlType().toString() === 'SELECT'">

            <div class="control-wrapper" *ngIf="elementData.getDropDownType() === 'Single'; else elseBlock">
                <select [class]="elementData.getAttribute('class')" [appEventListener]="getEventListenerInput()" [name]="elementData.getAttribute('name')" [disabled]="elementData.getAttribute('disabled')"
                    (change)="changedElement($event.target.value,elementData)">
                    <ng-container *ngIf="elementData.getOptionsDataSource() != undefined ">
                        <option [value]=null> {{elementData.getPlaceholder()}} </option>
                    </ng-container>
                    <option [value]="val.value" [selected]="val.value === elementData.getValue()"
                        *ngFor="let val of elementData.getOptions()">{{val.displayText}}</option>
                </select>
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </div>
            <ng-template #elseBlock>
                <select [class]="elementData.getAttribute('class')" [appEventListener]="getEventListenerInput()" [name]="elementData.getAttribute('name')"
                    (change)="changedElement($event.target.value,elementData,$event)" [id]="elementData.getId()" multiple>
                    <option [value]="val.value" [selected] ="val.selected"
                        *ngFor="let val of elementData.getOptions()">{{val.displayText}}</option>
                </select>
                <ng-container *ngIf="!formValidation && !elementData.validate()">
                    <div [class]="elementData.getRequiredFiledClass()">
                        {{elementData.getRequiredMessage()}}</div>
                </ng-container>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-container>
