import { Injectable } from '@angular/core';
import { RWElement } from '../classes/RWElement';
import { RWElementType } from '../classes/RWElementType';
import { RWElementControlType } from '../classes/RWElementControlType';
import { NgControlStatus } from '@angular/forms';
import { Button } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class ControlProviderService {

  constructor() { }

  getAllControls(): RWElement[] {
    const controls: RWElement[] = [];
    controls.push(this.getLabel());
    controls.push(this.getFormTextField());
    controls.push(this.getFormCheckboxField());
    controls.push(this.getFormRadioField());
    controls.push(this.getFormPasswordField());
    controls.push(this.getFormEmailField());
    controls.push(this.getFormSubmitField());
    controls.push(this.getFormFileField());
    controls.push(this.getFormRangeField());
    controls.push(this.getFormDateField());
    controls.push(this.getFormSearchField());
    controls.push(this.getFormUrlField());
    controls.push(this.getFormColorField());
    controls.push(this.getFormNumberField());
    controls.push(this.getFormDateTimeField());
    controls.push(this.getFormTelField());
    controls.push(this.getFormMonthField());
    controls.push(this.getFormWeekField());
    controls.push(this.getFormTimeField());
    controls.push(this.getImageField());
    controls.push(this.getFormResetField());
    controls.push(this.getFormButtonField());
    controls.push(this.getWidgetField());
    controls.push(this.getTable());
    controls.push(this.getFormTextArea());
    controls.push(this.getAnchorTag());
    controls.push(this.getDropdownField());
    controls.push(this.getForm());
    controls.push(this.getH1Tag());
    controls.push(this.getH2Tag());
    controls.push(this.getH3Tag());
    controls.push(this.getH4Tag());
    controls.push(this.getH5Tag());
    controls.push(this.getH6Tag());
    controls.push(this.getDivTag());
    controls.push(this.getVerticalAccordion());
    controls.push(this.getRoundWidget());

    return controls;
  }

  private getLabel(){
    const label = new RWElement();
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Label');
    label.setToolboxName('Label');
    label.addCategory('Form');
    label.setToolboxIcon('assets/toolbox/label.png');
    label.addKeyword('label')

    return label; 
  }

  private getFormTextField() {
    const control = new RWElement();
    control.setToolboxName('Text Field');
    control.setToolboxIcon('assets/toolbox/text-field.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement();
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Field');


    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.TEXT);
    input.addClass('form-control');

    const help = new RWElement();
    help.addClass('form-text');
    help.addClass('text-muted');
    help.addClass('small');
    help.setContentTextD('Help Block');

    control.addChild(label);
    control.addChild(input);
    control.addChild(help);
    control.addKeyword('textbox');
    control.addKeyword('field');

    return control;
  }

  private getFormCheckboxField() {
    const control = new RWElement();
    control.setToolboxName('Checkbox');
    control.setToolboxIcon('assets/toolbox/check-box.png');
    control.addClass('form-group');
    control.addClass('form-check');
    control.addCategory('Form');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.CHECKBOX);
    input.addClass('form-check-input');


    const label = new RWElement();
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Checkbox');
    label.addClass('form-check-label');

    control.addChild(input);
    control.addChild(label);
    control.addKeyword('checkbox');

    return control;
  }

  private getFormRadioField() {
    const control = new RWElement();
    control.setToolboxName('Radio Button');
    control.setToolboxIcon('assets/toolbox/radio-button.png');
    control.addClass('form-group');
    control.addClass('form-check');
    control.addCategory('Form');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.RADIO);
    input.addClass('form-check-input');

    const label = new RWElement();
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Radio');
    label.addClass('form-check-label');

    control.addChild(input);
    control.addChild(label);
    control.addKeyword('radio');

    return control;
  }

  private getFormPasswordField() {
    const control = new RWElement();
    control.setToolboxName('Password Field');
    control.setToolboxIcon('assets/toolbox/password-field.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement();
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Password');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.PASSWORD);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('password');

    return control;
  }

  private getFormEmailField() {
    const control = new RWElement();
    control.setToolboxName('Email Field');
    control.setToolboxIcon('assets/toolbox/email-field.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement();
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Email');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.EMAIL);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('email');

    return control;
  }

  private getFormSubmitField() {
    const control = new RWElement();
    control.setToolboxName('Submit Button');
    control.setToolboxIcon('assets/toolbox/submit.png');
    control.addCategory('Form');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.SUBMIT);
    input.addClass('btn btn-primary');

    control.addChild(input);
    control.addKeyword('submit');

    return control;
  }

  private getFormFileField() {
    const control = new RWElement();
    control.setToolboxName('File Upload');
    control.setToolboxIcon('assets/toolbox/file-upload.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement();
    label.setElementType(RWElementType.LABEL);

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.File);

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('file');

    return control;
  }

  private getFormRangeField() {
    const control = new RWElement();
    control.setToolboxName('Range Field');
    control.setToolboxIcon('assets/toolbox/range.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement();
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Range');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.RANGE);
    input.addClass('form-control-range');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('range');

    return control;
  }

  private getFormDateField() {
    const control = new RWElement();
    control.setToolboxName('Date Field');
    control.setToolboxIcon('assets/toolbox/date.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement();
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Date');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.DATE);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('date');

    return control;
  }

  private getFormSearchField() {
    const control = new RWElement();
    control.setToolboxName('Search Field');
    control.setToolboxIcon('assets/toolbox/search.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement;
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Search');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.SEARCH);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('search');

    return control;
  }

  private getFormUrlField() {
    const control = new RWElement();
    control.setToolboxName('URL Field');
    control.setToolboxIcon('assets/toolbox/url.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement;
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Url');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.URL);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('url');

    return control;
  }

  private getFormColorField() {
    const control = new RWElement();
    control.setToolboxName('Color Picker');
    control.setToolboxIcon('assets/toolbox/color-picker.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement;
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Color');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.COLOR);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('color');

    return control;
  }

  private getFormNumberField() {
    const control = new RWElement();
    control.setToolboxName('Number Field');
    control.setToolboxIcon('assets/toolbox/number-field.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement;
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Number');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.NUMBER);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('number');

    return control;
  }

  private getFormDateTimeField() {
    const control = new RWElement();
    control.setToolboxName('Date Time Field');
    control.setToolboxIcon('assets/toolbox/date and time.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement;
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Date and Time');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.DATETIME)
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('date and time');

    return control;
  }

  private getFormTelField() {
    const control = new RWElement();
    control.setToolboxName('Telephone Field');
    control.setToolboxIcon('assets/toolbox/telephone.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement;
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Tel');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.TEL);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('tel');

    return control;
  }

  private getFormMonthField() {
    const control = new RWElement();
    control.setToolboxName('Month Field');
    control.setToolboxIcon('assets/toolbox/Month.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement;
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Months');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.MONTH);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('months');

    return control;
  }

  private getFormWeekField() {
    const control = new RWElement();
    control.setToolboxName('Week Field');
    control.setToolboxIcon('assets/toolbox/week.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement;
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Week');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.WEEK);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('week');

    return control;
  }

  private getFormTimeField() {
    const control = new RWElement();
    control.setToolboxName('Time Field');
    control.setToolboxIcon('assets/toolbox/time.png');
    control.addClass('form-group');
    control.addCategory('Form');

    const label = new RWElement;
    label.setElementType(RWElementType.LABEL);
    label.setContentTextD('Time');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.TIME);
    input.addClass('form-control');

    control.addChild(label);
    control.addChild(input);
    control.addKeyword('time');

    return control;
  }
  

  private getImageField() {
    const image = new RWElement();
    image.setElementType(RWElementType.IMAGE);
    image.setHeightD(100)
    image.setWidthD(100)
    image.setSourceD('assets/images/demo.png')
    image.setToolboxName('Image');
    image.setToolboxIcon('assets/toolbox/image.png');
    image.addCategory('Views');
    image.addKeyword('Image');

    return image;
  }

  private getFormResetField() {
    const control = new RWElement();
    control.setToolboxName('Reset Button');
    control.setToolboxIcon('assets/toolbox/reset.png');
    control.addCategory('Form');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.RESET);
    input.addClass('btn btn-primary');

    control.addChild(input);
    control.addKeyword('reset');

    return control;
  }

  private getFormButtonField() {
    const control = new RWElement();
    control.setToolboxName('Button');
    control.setToolboxIcon('assets/toolbox/button.png'); 
    control.addCategory('Form');

    const input = new RWElement();
    input.setElementType(RWElementType.CONTROL);
    input.setControlType(RWElementControlType.BUTTON);
    input.setContentTextD('Button');

    input.addClass('btn btn-primary');

    control.addChild(input);
    control.addKeyword('button');

    return control;
  }

  private getWidgetField() {
    const control = new RWElement();
    control.setToolboxName('Widget');
    control.setToolboxIcon('assets/toolbox/widget.png');
    control.addCategory('Views');
    control.addCategory('Dashboard');
    control.addKeyword('Widget')

    control.addClass('card');
    control.addClass('text-white');
    control.addClass('text-center');
    control.addClass('h-100');
    control.addClass('blueWidget');

    const cardBody = new RWElement();
    cardBody.setElementType(RWElementType.DIV);
    cardBody.addClass('card-body');
    cardBody.addClass('p-0');

    const h4 = new RWElement();
    h4.setElementType(RWElementType.H4);
    h4.setContentTextD('Widget 1');
    h4.addClass('card-title');
    h4.addClass('pt-2');
    h4.addClass('mb-1');
    h4.addClass('h4');
    cardBody.addChild(h4);

    const cardText = new RWElement();
    cardText.setElementType(RWElementType.DIV);
    cardText.addClass('card-text');
    cardText.addClass('text-color');
    cardText.addClass('pb-3');
    cardBody.addChild(cardText);

    const cardFooter = new RWElement();
    cardFooter.setElementType(RWElementType.DIV);
    cardFooter.addClass('card-footer');
    cardFooter.addClass('p-1');

    const anchor = new RWElement();
    anchor.setElementType(RWElementType.ANCHOR);
    anchor.setContentTextD('More Info')
    anchor.addClass('text-white');
    anchor.addClass('text-decoration-none');

    const icon = new RWElement();
    icon.setElementType(RWElementType.I);
    icon.addClass('fa');
    icon.addClass('fa-arrow-circle-right');
    anchor.addChild(icon);
    cardFooter.addChild(anchor);

    control.addChild(cardBody);
    control.addChild(cardFooter);
    return control;
  }

  private getTable() {
    const control = new RWElement();
    control.setToolboxName('Tree Table');
    control.setToolboxIcon('assets/toolbox/tree-table.png');

    const table = new RWElement();
    table.setElementType(RWElementType.CONTROL);
    table.setControlType(RWElementControlType.TABLE);

    control.addChild(table);
    control.addKeyword('table');

    return control;
  }
  
  private getFormTextArea() {
    const control = new RWElement();
    control.setToolboxName('Text Area');
    control.setToolboxIcon('assets/toolbox/textarea.png');
    control.addClass('form-group');
    control.addCategory('Form');
    
    const textarea = new RWElement();
    textarea.setElementType(RWElementType.CONTROL);
    textarea.setControlType(RWElementControlType.TEXTAREA);
    textarea.addClass('form-control');

    control.addChild(textarea);
    control.addKeyword('textarea');

    return control;
  }

  private getAnchorTag() {
    const anchor = new RWElement();
    anchor.setElementType(RWElementType.ANCHOR);
    anchor.setContentTextD('Link');
    anchor.setToolboxName('Anchor Tag');
    anchor.setToolboxIcon('assets/toolbox/anchor.png');
    anchor.addCategory('Form');
    anchor.addKeyword('Anchor tag');

    return anchor;
  }

  private getH1Tag() {
    const H1 = new RWElement();
    H1.setElementType(RWElementType.H1);
    H1.setToolboxName('Header1');
    H1.setToolboxIcon('assets/toolbox/h1.png');
    H1.addCategory('Form');
    H1.setContentTextD('HeaderTag1')
    H1.addKeyword('H1 tag');

    return H1;
  }

  private getH2Tag() {
    const H2 = new RWElement();
    H2.setElementType(RWElementType.H2);
    H2.setToolboxName('Header2');
    H2.setToolboxIcon('assets/toolbox/h2.png');
    H2.addCategory('Form');
    H2.setContentTextD('HeaderTag2')
    H2.addKeyword('H2 tag');

    return H2;
  }


  private getH3Tag() {
    const H3 = new RWElement();
    H3.setElementType(RWElementType.H3);
    H3.setToolboxName('Header3');
    H3.setToolboxIcon('assets/toolbox/h3.png');
    H3.addCategory('Form');
    H3.setContentTextD('HeaderTag3')
    H3.addKeyword('H3 tag')
    return H3;
  }

  private getH4Tag() {
    const H4 = new RWElement();
    H4.setElementType(RWElementType.H4);
    H4.setToolboxName('Header4');
    H4.setToolboxIcon('assets/toolbox/h4.png');
    H4.addCategory('Form');
    H4.setContentTextD('HeaderTag4')
    H4.addKeyword('H4 tag')
    return H4;
  }

  private getH5Tag() {
    const H5 = new RWElement();
    H5.setElementType(RWElementType.H5);
    H5.setToolboxName('Header5');
    H5.setToolboxIcon('assets/toolbox/h5.png');
    H5.addCategory('Form');
    H5.setContentTextD('HeaderTag5')
    H5.addKeyword('H5 tag')
    return H5;
  }

  private getH6Tag() {
    const H6 = new RWElement();
    H6.setElementType(RWElementType.H6);
    H6.setToolboxName('Header6');
    H6.setToolboxIcon('assets/toolbox/h6.png');
    H6.addCategory('Form');
    H6.setContentTextD('HeaderTag6')
    H6.addKeyword('H6 tag')
    return H6;
  }

  private getDropdownField() {
    const select = new RWElement();
    select.setElementType(RWElementType.CONTROL);
    select.setControlType(RWElementControlType.SELECT);
    select.addClass('form-control');
    select.setToolboxName('Dropdown');
    select.setToolboxIcon('assets/toolbox/dropdown.png');
    select.addCategory('Form');
    select.addCategory('Dashboard')
    select.addKeyword('dropdown');
    
    return select;
  }

  private getForm(){
    const form = new RWElement();
    form.setElementType(RWElementType.CONTROL);
    form.setControlType(RWElementControlType.FORM);
    form.setToolboxName('Form');
    form.setToolboxIcon('assets/toolbox/form.png');
    form.addCategory('Form');
    form.addKeyword('Form');
    form.addClass('form-group');

    return form;
  }

  private getDivTag(){
    const DIV =new RWElement();
    DIV.setElementType(RWElementType.DIV);
    DIV.setToolboxIcon('assets/toolbox/tag.png');
    DIV.setToolboxName('Div');
    DIV.addKeyword('Div');
    DIV.addClass('row');

    return DIV;
  }

  private getVerticalAccordion() {
    const control = new RWElement();
    control.setElementType(RWElementType.VERTICALACCORDION);
    control.setToolboxName('Vertical Accordion');
    control.addKeyword('vertical accordion');
    control.setVerticalAccordionHeaderTextD('Accordion Header');
    control.setContentTextD('Accordion Body');
    return control;
  }

  private getRoundWidget(){
    const control = new RWElement()
    control.setElementType(RWElementType.CONTROL);
    control.setControlType(RWElementControlType.BUTTON)
    control.setToolboxName('RoundWidget');
    control.addKeyword('RoundWidget');
    control.setToolboxIcon('assets/toolbox/Round-widget.png');
    control.addCategory('Views');
    control.addCategory('Dashboard');
    control.addClass('dark-round-widget-1');
    control.addClass('btn');
    control.addClass('btn-circle');
    control.addClass('btn-xl');
    control.setContentTextD('200');

    return control;
  }

}
