import { AuditInfo } from './AuditInfo';

export class ApplicationMenuConfig {
  id: string = undefined;
  code: string;
  title: string;
  pageCode?: string;
  show: boolean = true;
  children?: ApplicationMenuConfig[] = [];
  role: string;
  audit?: AuditInfo = undefined;
  navigationEnvironment: string;
  
  constructor() { }
}
