export enum RequestContentType {
    UNDEFINED = 'UNDEFINED',
    JSON = 'JSON',
    MULTIPART_FORM_DATA = 'MULTIPART_FORM_DATA',
    FORM_URL_ENCODED = 'FORM_URL_ENCODED',
    PLAIN_TEXT = 'PLAIN_TEXT',
    HTML = 'HTML',
    XML = 'XML',
    JAVASCRIPT = 'JAVASCRIPT'
}

export enum RequestContentTypeRaw {
    UNDEFINED = 'UNDEFINED',
    JSON = 'application/json',
    MULTIPART_FORM_DATA = 'multipart/form-data',
    FORM_URL_ENCODED = 'application/x-www-form-urlencoded',
    PLAIN_TEXT = 'text/plain',
    HTML = 'text/html',
    XML = 'text/xml',
    JAVASCRIPT = 'application/javascript'
}