import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ElementProperty } from 'src/app/classes/ElementProperty';
import { ElementPropertyField } from "src/app/classes/ElementPropertyField";
import { ElementPropertyFieldEvent } from "src/app/classes/ElementPropertyFieldEvent";

@Component({
  selector: 'app-element-property-field-renderer',
  templateUrl: './element-property-field-renderer.component.html',
  styleUrls: ['./element-property-field-renderer.component.scss']
})
export class ElementPropertyFieldRendererComponent implements OnInit {

  @Input() field: ElementPropertyField;
  @Input() index: number = 1;
  @Input() property: ElementProperty;
  @Input() value: any;
  @Output() valueChanged = new EventEmitter<ElementPropertyFieldEvent>();

  constructor() { }

  ngOnInit(): void {
  }

  getWeightSum(): number {
    return this.property.getWeightSum(this.index);
  }

  fieldValueChanged(event: any): void {
    this.valueChanged.emit(new ElementPropertyFieldEvent(event, this.property, this.field, this.index));
  }
}
