import { RWDataSourceHttpConfiguration } from './RWDataSourceHttpConfiguration';
import { HttpClient } from '@angular/common/http';
import { RWAPIMethod } from './RWAPIMethod';
import { Observable } from 'rxjs';
import { RequestContentType, RequestContentTypeRaw } from './RequestContentType';
import { AuditInfo } from './AuditInfo';

export enum RWDataSourceResponseType {
    raw = 'raw',
    blob = 'blob'
}
export class RWDataSource{
    id: string;
    code: string;
    name: string;
    type: string;
    http: RWDataSourceHttpConfiguration;
    expectedStatusCode: number;
    validationExpression: string;
    validationErrorMessageProperty: string;
    validationErrorMessageRaw: string;
    showPopupOnError: boolean;
    audit?: AuditInfo = undefined;
    responseType: RWDataSourceResponseType = RWDataSourceResponseType.raw;
    fileName: string;


    private getJsonObject(params?: any) {
        if (params && typeof params === 'string') {
            try {
                return JSON.parse(params);
            } catch(e) {
                return {};
            }
        }
        return params;
    }

    load(httpClient: HttpClient, pathParams?: any, queryParams?: any, body?:any, headers?: any, contentType?: RequestContentType, dataSource?: RWDataSource): Observable<any> {

        // const finalQueryObject: any = Object.assign({}, this.getJsonObject(this.http.defaultQueryParams), this.getJsonObject(queryParams));
        let finalQueryObject: any = Object.assign({}, this.getJsonObject(this.http.defaultQueryParams));
        finalQueryObject = Object.assign(finalQueryObject, this.getJsonObject(queryParams));
        let finalBodyObject: any = undefined;
        if (contentType !== RequestContentType.MULTIPART_FORM_DATA) {
            if (!Array.isArray(body)) {
                finalBodyObject = Object.assign({}, this.getJsonObject(this.http.defaultBody), this.getJsonObject(body));
            } else {
                finalBodyObject = Object.assign({}, this.getJsonObject(this.http.defaultBody), this.getJsonObject(body));
            }
        } else {
            finalBodyObject = body;
        }
        const finalHeaderObject: any = Object.assign({}, this.getJsonObject(this.http.defaultHeaders), this.getJsonObject(headers));
        const finalPathObject: any = Object.assign({}, this.getJsonObject(this.http.defaultPathParams), this.getJsonObject(pathParams));

        /**
         * Path Params Example
         * Scheme = http
         * Host = realreco.com
         * Path = /users/:id
         * Method = get
         * Calculated URL = http://realreco.com/users/:id
         * PathParams = {id: 1}
         * New Path = http://realreco.com/users/1
         */

        const pathKeys = Object.keys(finalPathObject);
        if (pathKeys.length > 0) {
            for (let i = 0; i < pathKeys.length; i++) {
                const key = pathKeys[i];
                const pathVarPrefix = '/';
                const searchKey = pathVarPrefix + ':' + key;
                if (this.http.path.indexOf(searchKey) > -1) {
                    this.http.path = this.http.path.replace(searchKey, pathVarPrefix + pathParams[key]);
                }
            }
        }

        // if (this.http.host === '13.235.249.33:3001') {
        //     this.http.host = 'localhost:3001';
        // }
        let url =  this.http.scheme + '://' + this.http.host;
        if (this.http.port !== undefined && this.http.port !== null) {
            url += ':' + this.http.port;
        }
        url += this.http.path;
        
        let request: Observable<any> = undefined;
        // finalHeaderObject['Content-Type'] = RequestContentTypeRaw.JSON;
        if(this.http.method === RWAPIMethod.GET && dataSource.responseType === RWDataSourceResponseType.raw) {
            request = httpClient.get(url, { headers: finalHeaderObject, params: finalQueryObject });
        } else if(this.http.method === RWAPIMethod.GET && dataSource.responseType === RWDataSourceResponseType.blob) {
            request = httpClient.get(url, { headers: finalHeaderObject, params: finalQueryObject,responseType: dataSource.responseType});
        } else if(this.http.method === RWAPIMethod.POST && contentType === RequestContentType.MULTIPART_FORM_DATA) {
            // finalHeaderObject['Content-Type'] = RequestContentTypeRaw.MULTIPART_FORM_DATA;
            request = httpClient.post(url, finalBodyObject, { headers: finalHeaderObject, params: finalQueryObject });
        } else if(this.http.method === RWAPIMethod.POST && contentType !== RequestContentType.MULTIPART_FORM_DATA) {
            finalHeaderObject['Content-Type'] = RequestContentTypeRaw.JSON;
            request = httpClient.post(url, finalBodyObject, { headers: finalHeaderObject, params: finalQueryObject });
        } else if (this.http.method === RWAPIMethod.PUT) {
            request = httpClient.put(url, finalBodyObject, { headers: finalHeaderObject, params: finalQueryObject });
        } else if (this.http.method === RWAPIMethod.DELETE) {
            request = httpClient.delete(url, { headers: finalHeaderObject, params: finalQueryObject });
        }
        return request;
    }
}
