import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopbarComponent } from './layouts/topbar/topbar.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { TopbarLayoutComponent } from './layouts/topbar-layout/topbar-layout.component';
import { SidebarLayoutComponent } from './layouts/sidebar-layout/sidebar-layout.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PagesComponent } from './components/pages/pages.component';
import { NewPageComponent } from './components/pages/new-page/new-page.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AngularTreeTableModule } from 'angular-tree-table';
import { DataProviderService } from './services/data-provider.service';
import { RWElementRendererComponent } from './components/renderers/rw-element-renderer/rw-element-renderer.component';
import { ElementPropertiesComponent } from './components/renderers/element-properties/element-properties.component';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { AuthService } from './keycloak/auth.service';
import { initializer } from './keycloak/AppInit';
import { LogoutComponent } from './components/logout/logout.component';
import { HttpClientModule } from '@angular/common/http';
import { EventListenerDirective } from './directive/event-listener.directive';
import { ElementPropertyFieldRendererComponent } from './components/renderers/element-property-field-renderer/element-property-field-renderer.component';
import { DataSourcesComponent } from './components/data-sources/data-sources.component';
import { NewDataSourceComponent } from './components/data-sources/new-data-source/new-data-source.component';
import { DataSourceExecutorService } from './services/data-source-executor.service';
import { ActionExecutorService } from './services/action-executor.service';
import { ApplicationService, PageService, MenuService, DataSourceService } from './rest';
import { MenuComponent } from './components/menu/menu.component';
import { NewMenuComponent } from './components/menu/new-menu/new-menu.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';





@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    SidebarComponent,
    TopbarLayoutComponent,
    SidebarLayoutComponent,
    FooterComponent,
    DashboardComponent,
    PagesComponent,
    NewPageComponent,
    SettingsComponent,
    RWElementRendererComponent,
    ElementPropertiesComponent,
    LogoutComponent,
    EventListenerDirective,
    ElementPropertyFieldRendererComponent,
    DataSourcesComponent,
    NewDataSourceComponent,
    NewMenuComponent,
    MenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularTreeTableModule,
    FormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    NgxSpinnerModule,
    BrowserAnimationsModule


  ],
  providers: [ 
    ActionExecutorService,
    DataSourceExecutorService,
    DataProviderService,
    ApplicationService,
    PageService,
    MenuService,
    DataSourceService,
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    }, AuthService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
