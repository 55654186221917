<ng-container *ngIf="field.type === 'SELECT'">
    <label class="m-0" *ngIf="field.displayName != '' ">{{field.displayName}}</label>
    <select type="text" class="property-value" (blur)="fieldValueChanged($event)" (change)="fieldValueChanged($event)" [value]="value">
        <option [selected]="value == val" *ngFor="let val of field.possibleValues">
            {{val}}
        </option>
    </select>
</ng-container>
<ng-container *ngIf="field.type === 'TEXT'">
    <input type="text" class="property-value" [placeholder]="field.placeholder" (blur)="fieldValueChanged($event)"  (change)="fieldValueChanged($event)" [value]="value"/>
</ng-container>
<ng-container *ngIf="field.type === 'TEXTAREA'">
    <textarea  class="property-value" [placeholder]="field.placeholder" (blur)="fieldValueChanged($event)"  (change)="fieldValueChanged($event)" [value]="value"  rows="3"></textarea>
</ng-container>