import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { RWElement } from '../classes/RWElement';
import { RWPayLoadDataSource } from '../classes/RWPayLoadDataSource';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ElementPropertyService {

  private selectedElement: RWElement;
  private payLoadData: any;
  private payLoadValidation: any;
  private currencyInfo: any;
  private payLoadRWelement: any;
  private currentRwElements: any;

  constructor(private spinner: NgxSpinnerService) { }


  private elementChangedSubject = new BehaviorSubject('');
  elementChanged = this.elementChangedSubject.asObservable();

  private tableSettingsChangedSubject = new BehaviorSubject('');
  tableSettingsChanged = this.tableSettingsChangedSubject.asObservable();

  private showPopUpSettingsChangedSubject = new BehaviorSubject({ path: undefined, size: undefined, popupContextData: undefined });
  showPopupSettingsChanged = this.showPopUpSettingsChangedSubject.asObservable();

  private payLoadChangedSubject = new BehaviorSubject({value: undefined, payLoadDataSourceName: undefined, payLoadDataSourceProperty: undefined, validation: undefined, element: undefined});
  payLoadChanged = this.payLoadChangedSubject.asObservable();

  private payLoadElementDeleteSubject = new BehaviorSubject({ payLoadDataSourceName: undefined, payloadProperty: undefined, index: undefined });
  payLoadElementDeleteChanged = this.payLoadElementDeleteSubject.asObservable();

  private payLoadResetSubject = new BehaviorSubject({dataSourceCode: undefined});
  payLoadResetChanged = this.payLoadResetSubject.asObservable();

  private validationChangedSubject = new BehaviorSubject({dataSourceCode: undefined, formValidationValue: undefined});
  validationChanged = this.validationChangedSubject.asObservable();

  private pageReloadChangedSubject = new BehaviorSubject('');
  pageReloadChanged = this.pageReloadChangedSubject.asObservable();

  setElement(element: RWElement) {
    this.selectedElement = element;
    this.elementChangedSubject.next('');
  }

  getElement(): RWElement {
    return this.selectedElement;
  }

  emitTableSettingsChanged() {
    this.tableSettingsChangedSubject.next('');
  }

  emitPageReloadChanged() {
    this.pageReloadChangedSubject.next('');
  }

  emitShowPopupSettingsChanged(path: string, size: string, popupContextData: any) {
    this.showPopUpSettingsChangedSubject.next({ path, size, popupContextData });
  }

  setPayLoadElement(value: any, payLoadDataSourceName: string, payLoadDataSourceProperty: string, validation: boolean, element: RWElement) {
    this.payLoadChangedSubject.next({value, payLoadDataSourceName, payLoadDataSourceProperty, validation, element})
  }

  deletePayLoadElementByPath(payLoadDataSourceName: string, payloadProperty: string, index: number) {
    console.log('deletePayLoadElementByPath started');
    if (payloadProperty.split('[').length == 0) {
      console.warn('Invalid Property name for duplicatable fields');
      return;
    }
    let newPayloadProperty: string = payloadProperty.split('[')[0];
    this.payLoadElementDeleteSubject.next({ payLoadDataSourceName, payloadProperty: newPayloadProperty, index });
  }

  setPayLoadData(payLoadData: any, payLoadValidation?: any,payLoadRWelement?: any) {
      this.payLoadData = payLoadData;
      this.payLoadValidation = payLoadValidation;
      this.payLoadRWelement = payLoadRWelement

  }

  getPayLoadData() {
    return this.payLoadData;
  }

  getPayLoadValidations(dataSourceCode: string) {
    return this.payLoadValidation[dataSourceCode];
  }

  getPayLoadRWelement(dataSourceCode: string) {
    return this.payLoadRWelement[dataSourceCode];
  }

  setFormValidation(dataSourceCode: string, formValidationValue: boolean) {
    this.validationChangedSubject.next({ dataSourceCode, formValidationValue });
  }

  setCurrency(currency: any) {
    this.currencyInfo = currency;
  }

  getCurrency() {
    return this.currencyInfo;
  }

  resetPayload(dataSourceCode: string) {
    this.payLoadResetSubject.next({dataSourceCode});
  }

  showLoading() {
    this.spinner.show();
  }

  hideLoading() {
    this.spinner.hide();
  }

  setCurrentRwElements(currentRwElements) {
    this.currentRwElements = currentRwElements;
  }


  getCurrentRwElements() {
   return this.currentRwElements; 
  }

}
