import { RWElementType } from './RWElementType';
import { RWElementControlType } from './RWElementControlType';
import { ElementProperty, DIVElementProperties, LABELElementProperties, SPANElementProperties, SECTIONElementProperties, H1ElementProperties, H2ElementProperties, H3ElementProperties, H4ElementProperties, H5ElementProperties, H6ElementProperties, ANCHORElementProperties, IMAGEElementProperties, TEXTControlProperties, CHECKBOXControlProperties, PASSWORDControlProperties, EMAILControlProperties, BUTTONControlProperties, SUBMITControlProperties, RANGEControlProperties, DATEControlProperties, SEARCHControlProperties, URLControlProperties, NUMBERControlProperties, TELEPHONEControlProperties, MONTHControlProperties, TIMEControlProperties, RESETControlProperties, SELECTControlProperties, ControlProperties, BaseElementProperties, ALERTActionTypeProperties, ActionTypePropertyFields, TABLEControlProperties, APIMethodsProperties, APIGetMethodProperties,LoadOtherDataSoureProperties, ConfirmActionTypeProperties, UpdateSelfPropsProperties, ElementPropertyActionTypeProperties, NavigateActionProperties, FORMControlProperties, StoreOtherDataSoureProperties, DataTypeActionProperties, ShowPopupProperties, VerticalAccordionProperties, OpenTabActionProperties, UpdateOtherPropsProperties, SubDataTypeActionProperties, LoadSelfDataSoureProperties, StoreSelfDataSoureProperties, RADIOControlProperties, VoiceActionTypeProperties, PayloadFieldResetProperties, DownloadImageProperties, FILEControlProperties} from './ElementProperty';
import { RWActionType } from './RWActionType';
import { ElementPropertyField } from './ElementPropertyField';
import { RWAPIMethod } from './RWAPIMethod';
import { RWElementPropertyAction } from './RWElementPropertyAction';
import { TtDataType } from 'angular-tree-table';
export class ElementPropertySchemaProvider {

    static getPropertiesByActionType(actionType: RWActionType): ElementPropertyField[] {
        if (actionType === RWActionType.ALERT) {
            return new ALERTActionTypeProperties().fields;
        } else  if (actionType === RWActionType.API_CALL) {
            return new APIMethodsProperties().fields;
        } else  if (actionType === RWActionType.LOAD_SELF_DATASOURCE) {
            return new LoadSelfDataSoureProperties().fields;
        }  else  if (actionType === RWActionType.LOAD_OTHER_DATASOURCE) {
            return new LoadOtherDataSoureProperties().fields;
        } else  if (actionType === RWActionType.STORE_OTHER_DATASOURCE) {
            return new StoreOtherDataSoureProperties().fields;
        } else if (actionType === RWActionType.CONFIRM) {
            return new ConfirmActionTypeProperties().fields;
        } else if (actionType === RWActionType.UPDATE_SELF_PROPS) {
            return new UpdateSelfPropsProperties().fields;
        } else if (actionType === RWActionType.UPDATE_OTHER_PROPS) {
            return new UpdateOtherPropsProperties().fields;
        } else if (actionType === RWActionType.NAVIGATE) {
            return new NavigateActionProperties().fields;
        } else if (actionType === RWActionType.SHOWPOPUP) {
            return new ShowPopupProperties().fields;
        }  else if (actionType === RWActionType.OPEN_TAB) {
            return new OpenTabActionProperties().fields;
        } else if(actionType === RWActionType.STORE_SELF_DATASOURCE) {
             return new StoreSelfDataSoureProperties().fields
        } else if(actionType === RWActionType.VOICE) {
            return new VoiceActionTypeProperties().fields
       } else if (actionType === RWActionType.PAYLOAD_FIELD_RESET) {
           return new PayloadFieldResetProperties().fields
       } else if (actionType === RWActionType.DOWNLOAD_IMAGE) {
        return new DownloadImageProperties().fields
    }
        return new ActionTypePropertyFields().fields;
    }

    static getPropertiesByActionApiType(apiMethod: RWAPIMethod): ElementPropertyField[]{
            return new APIGetMethodProperties().fields;
    }

    static getPropertiesByActionDataType(dataType: TtDataType): ElementPropertyField[]{
        if (dataType === TtDataType.ACTIONS) {
            return new DataTypeActionProperties().fields;
        }
        return [];
    }

    static getPropertiesByActionSubDataType(subDataType: TtDataType): ElementPropertyField[]{
        if (subDataType === TtDataType.ACTIONS) {
            return new SubDataTypeActionProperties().fields;
        }
        return [];
    }

    static getPropertiesByElementPropertyActionType(actionType: RWElementPropertyAction): ElementPropertyField[]{
        return new ElementPropertyActionTypeProperties().fields;
    }

    static getProperties(elementType: RWElementType, controlType?: RWElementControlType): ElementProperty[] {
        if (elementType === RWElementType.DIV) {
            return new DIVElementProperties().properties;
        }
        else if (elementType === RWElementType.LABEL) {
            return new LABELElementProperties().properties;
        }
        else if (elementType === RWElementType.SPAN) {
            return new SPANElementProperties().properties;
        }
        else if (elementType === RWElementType.SECTION) {
            return new SECTIONElementProperties().properties;
        }
        else if (elementType === RWElementType.H1) {
            return new H1ElementProperties().properties;
        }
        else if (elementType === RWElementType.H2) {
            return new H2ElementProperties().properties;
        }
        else if (elementType === RWElementType.H3) {
            return new H3ElementProperties().properties;
        }
        else if (elementType === RWElementType.H4) {
            return new H4ElementProperties().properties;
        }
        else if (elementType === RWElementType.H5) {
            return new H5ElementProperties().properties;
        }
        else if (elementType === RWElementType.H6) {
            return new H6ElementProperties().properties;
        }
        else if (elementType === RWElementType.ANCHOR) {
            return new ANCHORElementProperties().properties;
        }
        else if (elementType === RWElementType.IMAGE) {
            return new IMAGEElementProperties().properties;
        }
        else if (elementType === RWElementType.VERTICALACCORDION) {
            return new VerticalAccordionProperties().properties;
        }
        else if (elementType === RWElementType.CONTROL) {
            if (controlType === RWElementControlType.TEXT) {
                return new TEXTControlProperties().properties;
            }
            else if (controlType === RWElementControlType.CHECKBOX) {
                return new CHECKBOXControlProperties().properties;
            }
            else if (controlType === RWElementControlType.PASSWORD) {
                return new PASSWORDControlProperties().properties;
            }
            else if (controlType === RWElementControlType.EMAIL) {
                return new EMAILControlProperties().properties;
            }
            else if (controlType === RWElementControlType.BUTTON) {
                return new BUTTONControlProperties().properties;
            }
            else if (controlType === RWElementControlType.SUBMIT) {
                return new SUBMITControlProperties().properties;
            }
            else if (controlType === RWElementControlType.RANGE) {
                return new RANGEControlProperties().properties;
            }
            else if (controlType === RWElementControlType.DATE) {
                return new DATEControlProperties().properties;
            }
            else if (controlType === RWElementControlType.SEARCH) {
                return new SEARCHControlProperties().properties;
            }
            else if (controlType === RWElementControlType.URL) {
                return new URLControlProperties().properties;
            }
            else if (controlType === RWElementControlType.NUMBER) {
                return new NUMBERControlProperties().properties;
            }
            else if (controlType === RWElementControlType.TEL) {
                return new TELEPHONEControlProperties().properties;
            }
            else if (controlType === RWElementControlType.MONTH) {
                return new MONTHControlProperties().properties;
            }
            else if (controlType === RWElementControlType.TIME) {
                return new TIMEControlProperties().properties;
            }
            else if (controlType === RWElementControlType.RESET) {
                return new RESETControlProperties().properties;
            }
            else if (controlType === RWElementControlType.SELECT) {
                return new SELECTControlProperties().properties;
            }
            else if (controlType === RWElementControlType.TABLE) {
                return new TABLEControlProperties().properties;
            } else if (controlType === RWElementControlType.FORM) {
                return new FORMControlProperties().properties;
            } else if (controlType === RWElementControlType.RADIO) {
                return new RADIOControlProperties().properties;
            } else if (controlType === RWElementControlType.File) {
                return new FILEControlProperties().properties;
            }
            return new ControlProperties().properties;
        }
        return new BaseElementProperties().properties;
    }
}
