import { RWActionType } from "./RWActionType";
import { RWActionProperty } from './RWActionProperty';

export class RWAction {
  type: RWActionType;
  properties: RWActionProperty[] = [];

  constructor(type: RWActionType) {
    this.type = type;
  }
}

