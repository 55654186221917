export enum RWActionType {
    UNDEFINED = 'UNDEFINED',
    ALERT = 'ALERT',
    CONFIRM = 'CONFIRM',
    API_CALL = 'API_CALL',
    UPDATE_SELF_PROPS = 'UPDATE_SELF_PROPS',
    UPDATE_OTHER_PROPS = 'UPDATE_OTHER_PROPS',
    LOAD_SELF_DATASOURCE = 'LOAD_SELF_DATASOURCE',
    RELOAD_SELF_DATASOURCE = 'RELOAD_SELF_DATASOURCE',
    LOAD_OTHER_DATASOURCE = 'LOAD_OTHER_DATASOURCE',
    RELOAD_OTHER_DATASOURCE = 'RELOAD_OTHER_DATASOURCE',
    STORE_SELF_DATASOURCE = 'STORE_SELF_DATASOURCE',
    STORE_OTHER_DATASOURCE = 'STORE_OTHER_DATASOURCE',
    NAVIGATE = 'NAVIGATE',
    SHOWPOPUP = 'SHOWPOPUP',
    HIDE_POPUP = 'HIDE_POPUP',
    OPEN_TAB = 'OPEN_TAB',
    PAYLOAD_RESET = 'PAYLOAD_RESET',
    PAGE_RELOAD = 'PAGE_RELOAD',
    VOICE = 'VOICE',
    SHOW_LOADING = 'SHOW_LOADING',
    HIDE_LOADING = 'HIDE_LOADING',
    PAYLOAD_FIELD_RESET = 'PAYLOAD_FIELD_RESET',
    OPEN_MAP = 'OPEN_MAP',
    DOWNLOAD_IMAGE = 'DOWNLOAD_IMAGE'
}
