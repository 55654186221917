<div class="toolbox-header">
    <div class="toolbox-title">Properties</div>
</div>
<div class="toolbox-body">
    <div class="controls-search">
        <input type="search" class="form-control" placeholder="Search Properties" name="search"
            [(ngModel)]="searchValue" autocomplete="off" (ngModelChange)="onSearchByProperties()" />
    </div>
    <div class="properties-list-wrapper">
        <ul>
            <li class="property-wrapper">
                <div class="property-label property-full-label element-type-heading">
                    {{getElementInfo()}}
                </div>
            </li>
            <ng-container *ngFor="let category of categories">
                <li *ngIf="categorizedProperties[category] !== undefined && categorizedProperties[category].length > 0"
                    class="category-wrapper">
                    <div class="category-label">{{category}}</div>
                    <ul class="category-properties-wrapper">
                        <li *ngFor="let property of categorizedProperties[category]" class="property-wrapper">
                            <div class="property-label">
                                {{property.displayName}}
                                <div class="property-actions" *ngIf="property.multipleValues">
                                    <button class="add-empty-value-button" (click)="addEmptyPropertyValue(property)">+</button>
                                </div>
                            </div>
                            <div class="property-value {{property.type}}">
                                <ul *ngIf="!property.multipleValues">
                                    <li class="property-field-set" *ngFor="let fieldSet of property.fields; let fieldSetIndex = index">
                                        <ul class="property-field-set-item">
                                            <li class="property-field-item" *ngFor="let field of fieldSet;  let fieldIndex = index">
                                                <app-element-property-field-renderer
                                                    [field]="field"
                                                    [index]="fieldSetIndex" 
                                                    [property]="property"
                                                    (valueChanged)="fieldValueChanged($event)"
                                                    [value]="getFieldValue(property, field, fieldSetIndex, fieldIndex, null)">
                                                </app-element-property-field-renderer>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <ul *ngIf="property.multipleValues">
                                    <li class="property-field-set" *ngFor="let fieldSet of property.fields; let fieldSetIndex = index">
                                        <ul class="property-field-set-item" *ngFor="let valIndex of getPropertyValuesLength(property)">
                                            <li>
                                                <button class="remove-selected-value-button" (click)="removeSelectedPropertyValue(valIndex, property)">-</button>
                                            </li>
                                            <li class="property-field-item" *ngFor="let field of fieldSet;  let fieldIndex = index">
                                                <app-element-property-field-renderer 
                                                    [field]="field"
                                                    [index]="valIndex" 
                                                    [property]="property"
                                                    (valueChanged)="fieldValueChanged($event)"
                                                    [value]="getFieldValue(property, field, fieldSetIndex, fieldIndex, valIndex)">
                                                </app-element-property-field-renderer>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
            </ng-container>
        </ul>
    </div>
</div>