import { Component, OnInit } from '@angular/core';
import { TreeTableHeaderObject, TreeTableRow, TreeTableData, TtDataType, TreeTableRowAction, TreeTableDataConfig } from 'angular-tree-table';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { Router } from '@angular/router';
import { ApplicationConfig } from '../../classes/ApplicationConfig';
import { ApplicationPageConfig } from 'src/app/classes/ApplicationPageConfig';
import { ElementPropertyService } from 'src/app/services/element-property.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  jCount = 0;

  tableData = null;
  tableConfig: TreeTableDataConfig = {
    context: this,
    showExpandArrows: true,
    showExpandAllArrows: true,
    excelExportButton: true,
    excelExportFileName: 'Pages',
    excelExportButtonText: 'Excel',
    columnVisibility: true
  };

  tableHeaders: TreeTableHeaderObject[] = [];

  constructor(private dataProviderService: DataProviderService, private router: Router, private elementPropertyService: ElementPropertyService) { }

  ngOnInit() {
    this.tableData = new TreeTableData(this.tableConfig);
    this.tableInit();
    this.loadData();
  }

  loadData() {
    const data = [];
    this.tableData.isLoading = true;
    const groupedData: { [key: string]: ApplicationPageConfig[] } = {};
    this.dataProviderService.getAppConfig().then((appConfig: ApplicationConfig) => {
      this.elementPropertyService.setCurrency({'currencyCode':appConfig.currencyCode, 'locale':appConfig.locale});
      this.tableData.isLoading = false;
      for (const [index, pageConfig] of appConfig.pages.entries()) {
        if (groupedData[pageConfig.code] === undefined || groupedData[pageConfig.code] === null) {
          groupedData[pageConfig.code] = [];
        }
        groupedData[pageConfig.code].push(pageConfig);
      }
      let sno = 0;
      for (const [index, pageConfigs] of Object.entries(groupedData)) {
        const pageConfig = pageConfigs[pageConfigs.length - 1];
        pageConfig['sno'] = ++sno;
        const row = new TreeTableRow(sno + '', pageConfig, true, null);

        const versionTableConfig = this.tableConfig;
        versionTableConfig.showPageLengthDropdown = false;
        versionTableConfig.excelExportButton = false;
        versionTableConfig.commonSearch = false;
        versionTableConfig.showExpandAllArrows = false;
        versionTableConfig.showExpandArrows = false;
        // versionTableConfig.extraInfos = [['Version History']];
        const versionTableData = new TreeTableData(versionTableConfig);
        const versionTableHeaders: TreeTableHeaderObject[] = [];
        versionTableHeaders.push(new TreeTableHeaderObject('Version', 'version', null, true));
        versionTableHeaders.push(new TreeTableHeaderObject('Title', 'title', null, true));
        versionTableHeaders.push(new TreeTableHeaderObject('Layout', 'layout', null, true));
        const actions = new TreeTableHeaderObject('Actions', '', null, true);
        actions.dataType = TtDataType.ACTIONS;
        versionTableHeaders.push(actions);
        versionTableData.headers = versionTableHeaders;

        const subRows: TreeTableRow[] = [];
        for (const versionConfig of pageConfigs) {
          const subRow = new TreeTableRow(sno + '_' + versionConfig.version, versionConfig, false, null);
          const copyAction = new TreeTableRowAction('Copy', 'Copy Page', 'btn btn-sm btn-secondary', this.copyPageClicked);
          copyAction.context = this;
          const editAction = new TreeTableRowAction('Edit', 'Edit Page', 'btn btn-sm btn-secondary', this.editPageClicked);
          editAction.context = this;
          const previewAction = new TreeTableRowAction('Preview', 'Preview Page', 'btn btn-sm btn-info', this.previewPageClicked);
          previewAction.context = this;
          const deleteAction = new TreeTableRowAction('Delete', 'Delete Page ', 'btn btn-sm btn-danger', this.deletePageClicked);
          deleteAction.context = this;
          subRow.actions.push(copyAction);
          subRow.actions.push(editAction);
          subRow.actions.push(previewAction);
          subRow.actions.push(deleteAction);
          subRows.push(subRow);
        }
        versionTableData.data = subRows;

        row.children = versionTableData;
        const latestCopyAction = new TreeTableRowAction('Copy', 'Copy Page', 'btn btn-sm btn-secondary', this.latestCopyPageClicked);
        latestCopyAction.context = this;
        const latestEditAction = new TreeTableRowAction('Edit', 'Edit Page', 'btn btn-sm btn-secondary', this.latestEditPageClicked);
        latestEditAction.context = this;
        const latestPreviewAction = new TreeTableRowAction('Preview', 'Preview Page', 'btn btn-sm btn-info', this.latestPreviewPageClicked);
        latestPreviewAction.context = this;
        const deleteAction = new TreeTableRowAction('Delete', 'Delete Page ', 'btn btn-sm btn-danger', this.deletePageClicked);
        deleteAction.context = this;
        row.actions.push(latestCopyAction);
        row.actions.push(latestEditAction);
        row.actions.push(latestPreviewAction);
        row.actions.push(deleteAction);
        data.push(row);
      }
      this.tableData.data = data;
    }, error => {
      console.error('error fetching data', error);
    });

  }

  deletePageClicked(page: any) {
    let result = confirm('Are you sure, you want delete ' + page.code + '-' + page.version);
    if (result) {
      this.dataProviderService.deletePage(page).then(done => {
        alert('Page Deleted');
        this.loadData();
      }, error => {
        alert('Unable to create page ' + error);
      });
    }
  }

  copyPageClicked(data: any) {
    this.router.navigate(['/pages/copy/' + data['id']]);
  }

  editPageClicked(data: any) {
    this.router.navigate(['/pages/edit/' + data['id']]);
  }

  previewPageClicked(data: any) {
    this.router.navigate(['/pages/edit/' + data['id']], { queryParams: { mode: 'PREVIEW' } });
  }

  latestCopyPageClicked(data: any) {
    this.router.navigate(['/pages/copy/' + data['id']]);
  }

  latestEditPageClicked(data: any) {
    this.router.navigate(['/pages/edit/' + data['id']]);
  }

  latestPreviewPageClicked(data: any) {
    this.router.navigate(['/pages/edit/' + data['id']], { queryParams: { mode: 'PREVIEW' } });
  }

  tableInit() {
    this.tableHeaders.splice(0, this.tableHeaders.length);
    const header = new TreeTableHeaderObject('S.No', 'sno', null, true);
    header.dataType = TtDataType.NUMBER;
    this.tableHeaders.push(header);
    this.tableHeaders.push(new TreeTableHeaderObject('Code', 'code', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Title', 'title', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Version', 'version', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Audit', '=CONCAT($SS:CREATED: |||$VD:audit.createdAt:DD-MM HH-mm|||$SS:<br/> by |||$VS:audit.createdByName|||$SS:<br/>|||$SS:LAST MODIFIED: |||$VD:audit.lastModifiedAt:DD-MM HH-mm|||$SS:<br/> by |||$VS:audit.lastModifiedByName)', null, false));
    const actions = new TreeTableHeaderObject('Actions', '', null, true);
    actions.dataType = TtDataType.ACTIONS;
    this.tableHeaders.push(actions);
    this.tableData.headers = this.tableHeaders;
  }

  openNewPageDialog() {

  }
}
