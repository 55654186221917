import { ApplicationPageConfig } from './ApplicationPageConfig';
import { ApplicationMenuConfig } from './ApplicationMenuConfig';
import { RWDataSource } from './RWDataSource';
import { AuditInfo } from './AuditInfo';


export class ApplicationConfig {
  id: string = undefined;
  code: string;
  title: string;
  name: string;
  url: string;
  description: string;
  favicon: string;
  footerText: string;
  backgroundImage: string;
  fontSize:string;
  externalCss: string;
  logo: string;
  pages: ApplicationPageConfig[] = [];
  menu: ApplicationMenuConfig[] = [];
  dataSources: RWDataSource[] = [];
  audit?: AuditInfo = undefined;
  locale: string;
  currencyCode: string;
  
  constructor() { }
}