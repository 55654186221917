import { RWEventType } from './RWEventType';
import { RWActionType } from './RWActionType';
import { ElementPropertyCategory } from './ElementPropertyCategory';
import { ElementPropertyField } from './ElementPropertyField';
import { ElementPropertyValueType } from './ElementPropertyValueType';
import { RWAPIMethod } from './RWAPIMethod';
import { RWElementPropertyAction } from './RWElementPropertyAction';
import { RequestContentType } from './RequestContentType';
import { TtDataType } from 'angular-tree-table';

export class ElementProperty {
    name: string;
    displayName: string;
    category: ElementPropertyCategory = ElementPropertyCategory.GENERAL;
    multipleValues: boolean = false;
    valuesLengthMethodName: string;
    addEmptyValueMethodName: string;
    removeSelectedValueMethodName: string;
    order: number = 0;

    textPlaceholderTitle: string = '';
    textPlaceholderDataProperty: string = '';
    textPlaceholderStyle: string = '';
    textPlaceholderShow: string = '';
    valuesLengthTable: string = '';
    getterTableTitle: string;
    setterTableTitle: string;
    getterTableDataProprty: string;
    setterTableDataProprty: string;
    setterTableStyle: string;
    getterTableStyle: string;

    fields: ElementPropertyField[][] = [];

    constructor(name: string, displayName: string, setterMethodName?: string, getterMethodName?: string, value?: string, possibleValues?: string[], type?: ElementPropertyCategory) {
        this.name = name;
        this.displayName = displayName;
        
        if (setterMethodName !== undefined || getterMethodName !== undefined) {
            const field = new ElementPropertyField(null, null, setterMethodName, getterMethodName, value, possibleValues);
            if (possibleValues !== undefined && possibleValues !== null) {
                field.type = ElementPropertyValueType.SELECT;
            }
            this.fields.push([field]);
        }
        if (type !== undefined && type !== null) {
            this.category = type;
        }
    }

    getWeightSum(index: number): number {
        let sum = 0;
        if (this.fields[index] !== undefined && this.fields[index] !== null) {
            this.fields[index].forEach(v => sum += v.weight);
        }
        return sum;
    }
}

export class ActionTypePropertyFields {
    fields: ElementPropertyField[] = [];
}

export class ALERTActionTypeProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('message', 'Message', 'setAlertMessage', 'getAlertMessage'));
    }
    
}

export class VoiceActionTypeProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('transcript', 'Transcript', 'setTranscript', 'getTranscript'));
    }
    
}


export class NavigateActionProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('navigateUrl', 'Navigate URL', 'setNavigateUrl', 'getNavigateUrl'));
        this.fields.push(new ElementPropertyField('queryParam', 'QueryParam', 'setNavigateUrlQueryParam', 'getNavigateUrlQueryParam', undefined, undefined, ElementPropertyValueType.TEXTAREA,'QueryParam'));

    }
}

export class OpenTabActionProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('opentab', 'Open Tab', 'setOpenTab', 'getOpenTab'));
        this.fields.push(new ElementPropertyField('queryParam', 'QueryParam', 'setOpenTabQueryParam', 'getOpenTabQueryParam', undefined, undefined, ElementPropertyValueType.TEXTAREA,'QueryParam'));
        this.fields.push(new ElementPropertyField('rw-callback', 'RWCallback', 'setRWCallback', 'getRWCallback', undefined, undefined, ElementPropertyValueType.TEXTAREA,'RWCallback'));


    }
}

export class ShowPopupProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('showPopup', 'Show Popup', 'setShowPopup', 'getShowPopup'));
        this.fields.push(new ElementPropertyField('popupSize', 'Popup Size', 'setPopupSize', 'getPopupSize'));
    }
}

export class HidePopupProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('hidePopup', 'Hide Popup', 'setHidePopup', 'getHidePopup', undefined, undefined, ElementPropertyValueType.TEXTAREA, 'JSON FORMATE'));
    }
}

export class UpdateSelfPropsProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();    
        const possibleValues: string[] = [];
        for (const val in RWElementPropertyAction) {
            possibleValues.push(val);
        }
        const propertyActionType = new ElementPropertyField('propertyActionType', 'Property Action Type', 'setPropertyActionType', 'getPropertyActionType', null , possibleValues , ElementPropertyValueType.SELECT);
        this.fields.push(propertyActionType);
    }
}

export class UpdateOtherPropsProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();   
        this.fields.push(new ElementPropertyField('elementPath', 'Element Path', 'setOtherPropertyElementPath', 'getOtherPropertyElementPath'));
        const possibleValues: string[] = [];
        for (const val in RWElementPropertyAction) {
            possibleValues.push(val);
        }
        const propertyActionType = new ElementPropertyField('propertyActionType', 'Property Action Type', 'setOtherPropertyActionType', 'getOtherPropertyActionType', null , possibleValues , ElementPropertyValueType.SELECT);
        this.fields.push(propertyActionType);
    }
}
export class ElementPropertyActionTypeProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();    
        const actionValue = new ElementPropertyField('propertyActionValue', 'Property Action Value', 'setPropertyActionValue', 'getPropertyActionValue', null , null);
        this.fields.push(actionValue);
    }
}

export class APIMethodsProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();    
        const possibleApiMethods: string[] = [];
        for (const val in RWAPIMethod) {
            possibleApiMethods.push(val);
        }
        const apiMethod = new ElementPropertyField('method', 'Method', 'setApiMethod', 'getApiMethod', null , possibleApiMethods , ElementPropertyValueType.SELECT);
         this.fields.push(apiMethod);
    }
}

export class APIGetMethodProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('url', 'URL', 'setAPIUrl', 'getAPIUrl'));
    }
    
}
export class ConfirmActionTypeProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('confirmMessage', 'Confirm Message', 'setConfirmMessage', 'getConfirmMessage',undefined,undefined,ElementPropertyValueType.TEXTAREA,'JSON FORMATE'));
    }
    
}
export class DataTypeActionProperties extends ActionTypePropertyFields {

    constructor() {
        super();
        this.fields.push(new ElementPropertyField('dataTypeActionMessage', 'DataTypeAction Message', 'setTableHeaderActions', 'getTableHeaderActions', undefined, undefined, ElementPropertyValueType.TEXTAREA, 'JSON FORMAT'));
    }
    
}


export class SubDataTypeActionProperties extends ActionTypePropertyFields {

    constructor() {
        super();
        this.fields.push(new ElementPropertyField('subDataTypeActionMessage', 'SubDataTypeAction Message', 'setSubTableHeaderActions', 'getSubTableHeaderActions', undefined, undefined, ElementPropertyValueType.TEXTAREA, 'JSON FORMAT'));
    }
    
}

export class LoadSelfDataSoureProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('loadSelfDataSourceResponseProperty', 'Response Property Name', 'setLoadSelfDataSourceResponseProperty', 'getLoadSelfDataSourceResponseProperty'));
        this.fields.push(new ElementPropertyField('loadSelfDataSourceResponsePropertyActions', 'Response Property Actions', 'setLoadSelfDataSourceResponsePropertyActions', 'getLoadSelfDataSourceResponsePropertyActions', undefined, undefined, ElementPropertyValueType.TEXTAREA));
        this.fields.push(new ElementPropertyField('queryParam', 'QueryParam', 'setLoadSelfDataSourceQueryParam', 'getLoadSelfDataSourceQueryParam', undefined, undefined, ElementPropertyValueType.TEXTAREA,'QueryParam'));
        this.fields.push(new ElementPropertyField('body', 'Body', 'setLoadSelfDataSourceBody', 'getLoadSelfDataSourceBody', undefined, undefined, ElementPropertyValueType.TEXTAREA,'Body'));
    }
    
}

export class LoadOtherDataSoureProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('dataSource', 'Data Source Name', 'setOtherDataSourceName', 'getOtherDataSourceName'));
        this.fields.push(new ElementPropertyField('loadOtherDataSourceResponseProperty', 'Response Property Name', 'setLoadOtherDataSourceResponseProperty', 'getLoadOtherDataSourceResponseProperty'));
        this.fields.push(new ElementPropertyField('loadOtherDataSourceResponsePropertyActions', 'Response Property Actions', 'setLoadOtherDataSourceResponsePropertyActions', 'getLoadOtherDataSourceResponsePropertyActions', undefined, undefined, ElementPropertyValueType.TEXTAREA));
        this.fields.push(new ElementPropertyField('queryParam', 'QueryParam', 'setQueryParam', 'getQueryParam', undefined, undefined, ElementPropertyValueType.TEXTAREA,'QueryParam'));
        this.fields.push(new ElementPropertyField('body', 'Body', 'setLoadOtherDataSourceBody', 'getLoadOtherDataSourceBody', undefined, undefined, ElementPropertyValueType.TEXTAREA,'Body'));
    }
    
}

export class StoreOtherDataSoureProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('dataSource', 'Data Source Name', 'setStoreDataSourceName', 'getStoreDataSourceName'));
        this.fields.push(new ElementPropertyField('loadStoreOtherDataSoureResponseProperty', 'Response Property', 'setStoreOtherDataSoureResponseProperty', 'getStoreOtherDataSoureResponseProperty'));
        this.fields.push(new ElementPropertyField('loadStoreOtherDataSoureResponsePropertyActions', 'Response Property Actions', 'setStoreOtherDataSoureResponsePropertyActions', 'getStoreOtherDataSoureResponsePropertyActions', undefined, undefined, ElementPropertyValueType.TEXTAREA));
        this.fields.push(new ElementPropertyField('queryParam', 'QueryParam', 'setStoreOtherDataSourceQueryParam', 'getStoreOtherDataSourceQueryParam', undefined, undefined, ElementPropertyValueType.TEXTAREA,'QueryParam'));
        this.fields.push(new ElementPropertyField('body', 'Body', 'setStoreOtherDataSourceBody', 'getStoreOtherDataSourceBody', undefined, undefined, ElementPropertyValueType.TEXTAREA,'Body'));
    }
    
}

export class StoreSelfDataSoureProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('loadStoreSelfDataSourceResponseProperty', 'Response Property', 'setStoreSelfDataSourceResponseProperty', 'getStoreSelfDataSourceResponseProperty'));
        this.fields.push(new ElementPropertyField('loadStoreSelfDataSourceResponsePropertyActions', 'Response Property Actions', 'setStoreSelfDataSourceResponsePropertyActions', 'getStoreSelfDataSourceResponsePropertyActions', undefined, undefined, ElementPropertyValueType.TEXTAREA));
        this.fields.push(new ElementPropertyField('queryParam', 'QueryParam', 'setStoreSelfDataSourceQueryParam', 'getStoreSelfDataSourceQueryParam', undefined, undefined, ElementPropertyValueType.TEXTAREA,'QueryParam'));
        // this.fields.push(new ElementPropertyField('body', 'Body', 'setStoreSelfDataSourceBody', 'getStoreSelfDataSourceBody', undefined, undefined, ElementPropertyValueType.TEXTAREA,'Body'));
    }
    
}

export class PayloadFieldResetProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('fieldPath', 'Field Path', 'setPayloadRestFieldPath', 'getPayloadRestFieldPath', undefined, undefined, ElementPropertyValueType.TEXTAREA,'FieldPath'));
    }
    
}

export class DownloadImageProperties extends ActionTypePropertyFields {
    
    constructor() {
        super();
        this.fields.push(new ElementPropertyField('fileName', 'file Name', 'setFileName', 'getFileName', undefined, undefined, ElementPropertyValueType.TEXT,'File Name'));
    }
    
}


export class BaseElementProperties {
    properties: ElementProperty[] = [];

    constructor() {
        this.properties.push(new ElementProperty('id', 'ID', 'setId', 'getId'));
        this.properties.push(new ElementProperty('class', 'Class', 'setClassesStr', 'getClassesStr'));
        this.properties.push(new ElementProperty('order', 'Order', 'setOrder', 'getOrder'));
        this.properties.push(new ElementProperty('name', 'Name', 'setName', 'getName'));
        this.properties.push(new ElementProperty('href', 'Href', 'setHref', 'getHref'));
        this.properties.push(new ElementProperty('fontSize', 'FontSize', 'setFontSize', 'getFontSize'));
        const inlineStylesProp = new ElementProperty('inlineStyles', 'Inline Styles', 'setInlineStyles', 'getInlineStyles', undefined, undefined);
        inlineStylesProp.fields[0][0].type = ElementPropertyValueType.TEXTAREA;
        this.properties.push(inlineStylesProp);

        const isReadOnly = new ElementProperty('readOnly', 'ReadOnly', undefined, undefined,undefined,undefined);
        const displayIsReadOnly = new ElementPropertyField(null, null,'setIsReadOnly', 'getIsReadOnly', undefined, ['true', 'false'], ElementPropertyValueType.SELECT);
        isReadOnly.fields=[[displayIsReadOnly]];
        this.properties.push(isReadOnly);

        const isDisabled = new ElementProperty('disabled', 'Disabled', undefined, undefined, undefined, undefined);
        const displayIsDisabled = new ElementPropertyField(null, null, 'setIsDisabledOnly', 'getIsDisabledOnly', undefined, ['true', 'false'], ElementPropertyValueType.SELECT);
        isDisabled.fields = [[displayIsDisabled]];
        this.properties.push(isDisabled);


        this.properties.push(new ElementProperty('role', 'Role', 'setRole', 'getRole', undefined, undefined, ElementPropertyCategory.RBAC));
        const dataSourceProperty = new ElementProperty('dataSource','Data Source',undefined,undefined);
        dataSourceProperty.category = ElementPropertyCategory.CONTROL;
        const nameField = new ElementPropertyField(null, null, 'setValueDataSourceName', 'getValueDataSourceName', undefined, undefined, undefined, 'Name');
        const propertyField = new ElementPropertyField(null, null, 'setValueDataSourceProperty', 'getValueDataSourceProperty', undefined, undefined, ElementPropertyValueType.TEXTAREA, 'Property');
        const inheritedField = new ElementPropertyField(null, null, 'setValueDataSourceInherited', 'getValueDataSourceInherited', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT, 'Inherited');
        const shouldDisplayResultField = new ElementPropertyField(null, null, 'setValueDataSourceShouldUseResult', 'getValueDataSourceShouldUseResult', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT, 'Should Use Result');
        dataSourceProperty.fields = [[nameField, propertyField, inheritedField, shouldDisplayResultField]];
        this.properties.push(dataSourceProperty);

        const payloadDataSource = new ElementProperty('payloadDataSource','PayLoad DataSource',undefined,undefined);
        payloadDataSource.category = ElementPropertyCategory.CONTROL;
        const payLoadNameField = new ElementPropertyField(null, null, 'setPayLoadDataSourceName', 'getPayLoadDataSourceName', undefined, undefined, undefined, 'Name');
        const payLoadPropertyField = new ElementPropertyField(null, null, 'setPayLoadDataSourceProperty', 'getPayLoadDataSourceProperty', undefined, undefined, ElementPropertyValueType.TEXTAREA, 'Property');
        payloadDataSource.fields = [[payLoadNameField,payLoadPropertyField]];
        this.properties.push(payloadDataSource);


        const loadingProperty = new ElementProperty('loading','Loading',undefined,undefined);
        loadingProperty.category = ElementPropertyCategory.CONTROL;
        const isLoading = new ElementPropertyField(null, null, 'setIsLoading', 'getIsLoading', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT, 'IsLoading');
        loadingProperty.fields = [[isLoading]];
        this.properties.push(loadingProperty);



        const eventProperty = new ElementProperty('event', 'Events', null, null, ElementPropertyCategory.EVENT);
        eventProperty.multipleValues = true;
        eventProperty.valuesLengthMethodName = 'getEventsLength';
        eventProperty.addEmptyValueMethodName = 'addEmptyEvent';
        eventProperty.removeSelectedValueMethodName = 'removeSelectedEvent';
        
        const possibleEventTypes: string[] = [];
        for (const val in RWEventType) {
            possibleEventTypes.push(val);
        }
        const eventTypeField = new ElementPropertyField(null, null, 'setEventType', 'getEventType', null, possibleEventTypes, ElementPropertyValueType.SELECT);


        const possibleEventActionTypes: string[] = [];
        for (const val in RWActionType) {
            possibleEventActionTypes.push(val);
        }
        const eventActionType = new ElementPropertyField('actionType', null, 'setEventActionType', 'getEventActionType', null, possibleEventActionTypes, ElementPropertyValueType.SELECT);

        eventProperty.fields = [[eventTypeField, eventActionType]];

        this.properties.push(eventProperty);


        const enableDynamicallyDuplicate = new ElementProperty('enableDynamicallyDuplicate', 'EnableDynamicallyDuplicate', 'setEnableDynamicallyDuplicate', 'getEnableDynamicallyDuplicate', undefined, undefined);
        enableDynamicallyDuplicate.fields[0][0].type = ElementPropertyValueType.SELECT;
        enableDynamicallyDuplicate.fields[0][0].possibleValues = [, true];
        this.properties.push(enableDynamicallyDuplicate);

        const enableDynamicallyRemove = new ElementProperty('enableDynamicallyRemove', 'EnableDynamicallyRemove', 'setEnableDynamicallyRemove', 'getEnableDynamicallyRemove', undefined, undefined);
        enableDynamicallyRemove.fields[0][0].type = ElementPropertyValueType.SELECT;
        enableDynamicallyRemove.fields[0][0].possibleValues = [, true];
        this.properties.push(enableDynamicallyRemove);

        const validationProperty = new ElementProperty('required','Validation Required?',undefined,undefined);
        validationProperty.category = ElementPropertyCategory.CONTROL;
        const requiredField = new ElementPropertyField(null, null, 'setRequiredMessage', 'getRequiredMessage', undefined, undefined, undefined, 'Name');
        const requiredFiledType = new ElementPropertyField(null, null, 'setValidationRequired', 'getValidationRequired', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT, 'Should Use Result');
        const requiredFiledClass = new ElementPropertyField(null, null, 'setRequiredFiledClass', 'getRequiredFiledClass', undefined, undefined, undefined, 'Message Class');

        validationProperty.fields = [[requiredFiledType, requiredField,requiredFiledClass]];
        this.properties.push(validationProperty);


        const regularExpressionProperty = new ElementProperty('regularExpression','RegEx Required?',undefined,undefined);
        regularExpressionProperty.category = ElementPropertyCategory.CONTROL;
        const regularExpressionFiledType = new ElementPropertyField(null, null, 'setRegularExpressionFiledType', 'getRegularExpressionFiledType', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const regularExpressionPatternFiled = new ElementPropertyField(null, null, 'setRegularExpressionPatternFiled', 'getRegularExpressionPatternFiled', undefined, undefined, undefined, 'Pattern Expression');

        regularExpressionProperty.fields = [[regularExpressionFiledType, regularExpressionPatternFiled]];
        this.properties.push(regularExpressionProperty);

        this.properties.push(new ElementProperty('min', 'Minimum', 'setMin', 'getMin', undefined, undefined));
        this.properties.push(new ElementProperty('max', 'Maximum', 'setMax', 'getMax', undefined, undefined));

        this.properties.push(new ElementProperty('minLength', 'Min Length', 'setMinLength', 'getMinLength', undefined, undefined));
        this.properties.push(new ElementProperty('maxLength', 'Max Length', 'setMaxLength', 'getMaxLength', undefined, undefined));


    }
}

export class DIVElementProperties extends BaseElementProperties {

    constructor() {
        super();
        this.properties.push(new ElementProperty('contentText', 'Content Text', 'setContentText', 'getContentText'));
    }
}

export class LABELElementProperties extends BaseElementProperties {

    constructor() {
        super();
        this.properties.push(new ElementProperty('contentText', 'Content Text', 'setContentText', 'getContentText'));
    }
}

export class SPANElementProperties extends BaseElementProperties {

    constructor() {
        super();
        this.properties.push(new ElementProperty('contentText', 'Content Text', 'setContentText', 'getContentText'));
    }
}

export class SECTIONElementProperties extends BaseElementProperties {

    constructor() {
        super();
        this.properties.push(new ElementProperty('contentText', 'Content Text', 'setContentText', 'getContentText'));
    }
}

export class HElementProperties extends BaseElementProperties {

    constructor() {
        super();
        this.properties.push(new ElementProperty('contentText', 'Content Text', 'setContentText', 'getContentText'));
    }
}

export class VerticalAccordionProperties extends BaseElementProperties {

    constructor() {
        super();
        this.properties.push(new ElementProperty('contentText', 'Content Text', 'setContentText', 'getContentText'));
        this.properties.push(new ElementProperty('verticalAccordionHeaderText', 'Accordion Header Text', 'setVerticalAccordionHeaderText', 'getVerticalAccordionHeaderText'));
    }
}

export class H1ElementProperties extends HElementProperties {
    constructor() {
        super();
    }
}

export class H2ElementProperties extends HElementProperties {
    constructor() {
        super();
    }
}

export class H3ElementProperties extends HElementProperties {
    constructor() {
        super();
    }
}

export class H4ElementProperties extends HElementProperties {
    constructor() {
        super();
    }
}

export class H5ElementProperties extends HElementProperties {
    constructor() {
        super();
    }
}

export class H6ElementProperties extends HElementProperties {
    constructor() {
        super();
    }
}

export class ControlProperties extends BaseElementProperties {

    constructor() {
        super();
    }
}

export class TEXTControlProperties extends ControlProperties {

    constructor() {
        super();
        const placeholder = new ElementProperty('placeholder', 'Placeholders', 'setPlaceholder', 'getPlaceholder', undefined, undefined, ElementPropertyCategory.TEXT);
        this.properties.push(placeholder);
    }
}

export class CHECKBOXControlProperties extends ControlProperties {
    constructor() {
        super();
        const checked = new ElementProperty('checked', 'Checked', 'setChecked', 'getChecked', undefined, undefined, ElementPropertyCategory.CHECKBOX);
        checked.fields[0][0].type = ElementPropertyValueType.SELECT;
        checked.fields[0][0].possibleValues = ['', 'checked'];
        this.properties.push(checked);
        const checkboxValue = new ElementProperty('checkboxValue', 'Checkbox Value', 'setCheckboxValue', 'getCheckboxValue', undefined, undefined, ElementPropertyCategory.CHECKBOX);
        this.properties.push(checkboxValue);
    }
}

export class RADIOControlProperties extends ControlProperties {

    constructor() {
        super();
        const placeholder = new ElementProperty('radioValue', 'Radio Value', 'setRadioValue', 'getRadioValue', undefined, undefined, ElementPropertyCategory.RADIO);
        this.properties.push(placeholder);
    }
}

export class FILEControlProperties extends ControlProperties {

    constructor() {
        super();
        const fileType = new ElementProperty('fileType', 'File Type', undefined, undefined,undefined,undefined);
        const displayFileType = new ElementPropertyField(null, null,'setFileType', 'getFileType', undefined, ['Single', 'Multiple'],ElementPropertyValueType.SELECT);
        fileType.fields=[[displayFileType]];
        this.properties.push(fileType);
    }
}

export class PASSWORDControlProperties extends ControlProperties {
    constructor() {
        super();
        this.properties.push(new ElementProperty('placeholder', 'Placeholder', 'setPlaceholder', 'getPlaceholder', undefined, undefined, ElementPropertyCategory.TEXT));
    }
}

export class EMAILControlProperties extends ControlProperties {
    constructor() {
        super();
        const placeholder = new ElementProperty('placeholder', 'Placeholders', 'setPlaceholder', 'getPlaceholder', undefined, undefined, ElementPropertyCategory.CONTROL);
        placeholder.category = ElementPropertyCategory.TEXT;
        this.properties.push(placeholder);
        
    }
}

export class BUTTONControlProperties extends ControlProperties {
    constructor() {
        super();
        const contentText = new ElementProperty('contentText', 'Content Text', 'setContentText', 'getContentText', undefined, undefined, ElementPropertyCategory.BUTTON);
        this.properties.push(contentText);
        this.properties.push(new ElementProperty('voiceText', 'Voice Text', 'setVoiceText', 'getVoiceText', undefined, undefined, ElementPropertyCategory.BUTTON));
    }
}

export class SUBMITControlProperties extends BUTTONControlProperties {
    constructor() {
        super();
    }
}

export class RESETControlProperties extends BUTTONControlProperties {
    constructor() {
        super();
    }
}

export class RANGEControlProperties extends ControlProperties {
    constructor() {
        super();
        
        this.properties.push(new ElementProperty('placeholder', 'Placeholder', 'setPlaceholder', 'getPlaceholder', undefined, undefined, ElementPropertyCategory.RANGE));
    }
}

export class DATEControlProperties extends ControlProperties {
    constructor() {
        super();
        this.properties.push(new ElementProperty('placeholder', 'Placeholder', 'setPlaceholder', 'getPlaceholder', undefined, undefined, ElementPropertyCategory.TEXT));
    }
}

export class SEARCHControlProperties extends ControlProperties {
    constructor() {
        super();
        this.properties.push(new ElementProperty('placeholder', 'Placeholder', 'setPlaceholder', 'getPlaceholder', undefined, undefined, ElementPropertyCategory.TEXT));
    }
}

export class URLControlProperties extends ControlProperties {
    constructor() {
        super();
        this.properties.push(new ElementProperty('placeholder', 'Placeholder', 'setPlaceholder', 'getPlaceholder', undefined, undefined, ElementPropertyCategory.TEXT));
    }
}

export class NUMBERControlProperties extends ControlProperties {
    constructor() {
        super();
        this.properties.push(new ElementProperty('placeholder', 'Placeholder', 'setPlaceholder', 'getPlaceholder', undefined, undefined, ElementPropertyCategory.TEXT));
    }
}

export class TELEPHONEControlProperties extends ControlProperties {
    constructor() {
        super();
        this.properties.push(new ElementProperty('placeholder', 'Placeholder', 'setPlaceholder', 'getPlaceholder', undefined, undefined, ElementPropertyCategory.TEXT));
    }
}

export class MONTHControlProperties extends ControlProperties {
    constructor() {
        super();
    }
}

export class TIMEControlProperties extends ControlProperties {
    constructor() {
        super();
    }
}

export class SELECTControlProperties extends ControlProperties {
    constructor() {
        super();
        this.properties.push(new ElementProperty('placeholder', 'Placeholder', 'setPlaceholder', 'getPlaceholder', undefined, undefined, ElementPropertyCategory.SELECT));
        this.properties.push(new ElementProperty('payLoadKey', 'PayLoad Key', 'setPayLoadKey', 'getPayLoadKey', undefined, undefined, ElementPropertyCategory.SELECT));
        const multiple = new ElementProperty('options', 'Options', undefined, undefined);
        multiple.category = ElementPropertyCategory.SELECT;
        multiple.multipleValues = true;
        multiple.valuesLengthMethodName = 'getOptionsLength';
        multiple.addEmptyValueMethodName = 'addEmptyOption';
        multiple.removeSelectedValueMethodName = 'removeSelectedOption';
        const displayTextField = new ElementPropertyField(null, null, 'setOptionDisplayText', 'getOptionDisplayText');
        const valueField = new ElementPropertyField(null, null, 'setOptionValue', 'getOptionValue');
        this.properties.push(new ElementProperty('dropdowntype', 'DropDownType','setDropDownType', 'getDropDownType', undefined, ['Single', 'Multiple'], ElementPropertyCategory.SELECT));
        multiple.fields = [[displayTextField, valueField]];
        this.properties.push(multiple);

        const optionsDataSourceProperty = new ElementProperty('optionsDataSource','Options Data Source',undefined,undefined);
        optionsDataSourceProperty.category = ElementPropertyCategory.CONTROL;
        const nameField = new ElementPropertyField(null, null, 'setOptionsDataSourceName', 'getOptionsDataSourceName', undefined, undefined, undefined, 'Name');
        const propertyField = new ElementPropertyField(null, null, 'setOptionsDataSourceProperty', 'getOptionsDataSourceProperty', undefined, undefined, undefined, 'Property');
        const keyPropertyField = new ElementPropertyField(null, null, 'setOptionsDataSourceKeyProperty', 'getOptionsDataSourceKeyProperty', undefined, undefined, undefined, 'Key Property');
        const valuePropertyField = new ElementPropertyField(null, null, 'setOptionsDataSourceValueProperty', 'getOptionsDataSourceValueProperty', undefined, undefined, undefined, 'Value Property');
        const inheritedField = new ElementPropertyField(null, null, 'setOptionsDataSourceInherited', 'getOptionsDataSourceInherited', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT, 'Inherited');
        optionsDataSourceProperty.fields = [[nameField, propertyField, keyPropertyField, valuePropertyField, inheritedField]];
        this.properties.push(optionsDataSourceProperty);

    }
}

export class IMAGEElementProperties extends BaseElementProperties {
    constructor() {
        super();
        this.properties.push(new ElementProperty('src', 'Source', 'setSource', 'getSource', undefined, undefined, ElementPropertyCategory.IMAGE));
        this.properties.push(new ElementProperty('height', 'Height', 'setHeight', 'getHeight', undefined, undefined, ElementPropertyCategory.IMAGE));
        this.properties.push(new ElementProperty('width', 'Width', 'setWidth', 'getWidth', undefined, undefined, ElementPropertyCategory.IMAGE));
    }
}

export class ANCHORElementProperties extends BaseElementProperties {
    constructor() {
        super();
         this.properties.push(new ElementProperty('href', 'href', 'setHref', 'getHref', undefined, undefined, ElementPropertyCategory.ANCHOR));
         this.properties.push(new ElementProperty('router', 'Router', 'setRouter', 'getRouter', undefined, undefined, ElementPropertyCategory.ANCHOR));
         this.properties.push(new ElementProperty('contentText', 'Content Text', 'setContentText', 'getContentText',undefined, undefined, ElementPropertyCategory.ANCHOR));
         const anchorType = new ElementProperty('anchorType', 'AnchorType', undefined, undefined,undefined,undefined,ElementPropertyCategory.ANCHOR);
         const displayAnchorType = new ElementPropertyField(null, null,'setAnchorType', 'getAnchorType', undefined, ['Internal', 'External'], ElementPropertyValueType.SELECT);
         anchorType.fields=[[displayAnchorType]];
         this.properties.push(anchorType);
    }
}

export class TABLEControlProperties extends ControlProperties {
    constructor() {
        super();

        const possibleDataTypes: string[] = [];
        for (const dataType in TtDataType) {
            possibleDataTypes.push(dataType);
        }
        this.properties.push(new ElementProperty('childernDataProperty', 'ChildernDataProperty', 'setChildernDataProperty', 'getChildernDataProperty', undefined, undefined, ElementPropertyCategory.TABLE));

        const multiple = new ElementProperty('tableHeaders','TableHeaders',undefined,undefined);
        multiple.category = ElementPropertyCategory.TABLE;
        multiple.multipleValues = true;
        multiple.valuesLengthMethodName = 'getTableHeadersLength';
        multiple.addEmptyValueMethodName = 'addEmptyTableHeader';
        multiple.removeSelectedValueMethodName = 'removeSelectedTableHeader';
        const titleField = new ElementPropertyField(null, null, 'setTableHeaderTitle', 'getTableHeaderTitle',  undefined, undefined, undefined, 'Title');
        const dataPropertyField = new ElementPropertyField(null, null, 'setTableHeaderDataProperty', 'getTableHeaderDataProperty',  undefined, undefined, ElementPropertyValueType.TEXTAREA, 'Data Property');
        const styleField = new ElementPropertyField(null, null, 'setTableHeaderStyle', 'getTableHeaderStyle', undefined, undefined, undefined, 'Style');
        const showField = new ElementPropertyField(null, null,'setTableHeaderShow', 'getTableHeaderShow', undefined, ['show', 'hide'], ElementPropertyValueType.SELECT, 'Visibility');
        const clickableField =  new ElementPropertyField(null, null, 'setTableClickableField', 'getTableClickableField', undefined, undefined, ElementPropertyValueType.TEXTAREA, 'JSON FORMAT');
        const role = new ElementPropertyField(null, null, 'setTableHeaderRole', 'getTableHeaderRole', undefined, undefined, undefined, 'Role');
        const dataType = new ElementPropertyField('dataType', 'Data Type', 'setTableHeaderDataType', 'getTableHeaderDataType', undefined, possibleDataTypes, ElementPropertyValueType.SELECT)
        const columnOrder = new ElementPropertyField(null, null, 'setColumnOrder', 'getColumnOrder',  undefined, undefined, undefined, 'ColumnOrder');
       
        multiple.fields = [[titleField, dataPropertyField, styleField, showField, clickableField, role, dataType,columnOrder]];
        this.properties.push(multiple);

        const multiple2 = new ElementProperty('subTableHeaders','SubTableHeaders',undefined,undefined);
        multiple2.category = ElementPropertyCategory.TABLE;
        multiple2.multipleValues = true;
        multiple2.valuesLengthMethodName = 'getSubTableHeadersLength';
        multiple2.addEmptyValueMethodName = 'addEmptySubTableHeader';
        multiple2.removeSelectedValueMethodName = 'removeSelectedSubTableHeader';
        const subtitleField = new ElementPropertyField(null, null, 'setSubTableHeaderTitle', 'getSubTableHeaderTitle',  undefined, undefined, undefined, 'Title');
        const subdataPropertyField = new ElementPropertyField(null, null, 'setSubTableHeaderDataProperty', 'getSubTableHeaderDataProperty',  undefined, undefined, ElementPropertyValueType.TEXTAREA, 'Data Property');
        const substyleField = new ElementPropertyField(null, null, 'setSubTableHeaderstyle', 'getSubTableHeaderstyle', undefined, undefined, undefined, 'Style');
        const subshowField = new ElementPropertyField(null, null,'setSubTableHeadershow', 'getSubTableHeadershow', undefined, ['show', 'hide'], ElementPropertyValueType.SELECT, 'Visibility');
        const subclickableField =  new ElementPropertyField(null, null, 'setSubTableClickableField', 'getSubTableClickableField', undefined, undefined, ElementPropertyValueType.TEXTAREA, 'JSON FORMAT');
        const subrole = new ElementPropertyField(null, null, 'setSubTableHeaderRole', 'getSubTableHeaderRole', undefined, undefined, undefined, 'Role');
        const subdataType = new ElementPropertyField('subDataType', 'SubData Type', 'setSubTableHeaderDataType', 'getSubTableHeaderDataType', undefined, possibleDataTypes, ElementPropertyValueType.SELECT)
        const subColumnOrder = new ElementPropertyField(null, null, 'setSubTableColumnOrder', 'getSubTableColumnOrder',  undefined, undefined, undefined, 'SubColumnOrder');
        
        multiple2.fields = [[subtitleField, subdataPropertyField, substyleField, subshowField, subclickableField, subrole, subdataType,subColumnOrder]];
        this.properties.push(multiple2);

        const tableConfig = new ElementProperty('tableConfig','Table Config');
        tableConfig.category = ElementPropertyCategory.TABLE;
        const columnVisibilityDropDown = new ElementPropertyField(null, 'ColumnVisibility :', 'setColumnVisibilityDropDown', 'getColumnVisibilityDropDown', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const commonSearch = new ElementPropertyField(null, 'CommonSearch :', 'setCommonSearch', 'getCommonSearch', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const excelExportButton = new ElementPropertyField(null, 'ExcelExport :', 'setExcelExportButton', 'getExcelExportButton', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const excelExportFileName = new ElementPropertyField(null, null, 'setExcelExportFileName', 'getExcelExportFileName', undefined, undefined, undefined, 'ExcelExportFileName');
        const excelExportButtonText = new ElementPropertyField(null, null, 'setExcelExportButtonText', 'getExcelExportButtonText', undefined, undefined, undefined, 'ExcelExportButtonText');
        const showExpandArrows = new ElementPropertyField(null, 'ShowExpandArrows:', 'setShowExpandArrows', 'getShowExpandArrows', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const showExpandAllArrows = new ElementPropertyField(null, 'ShowExpandAllArrows :', 'setShowExpandAllArrows', 'getShowExpandAllArrows', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const showPageLengthDropdown = new ElementPropertyField(null, 'showPageLength :', 'setShowPageLengthDropdown', 'getShowPageLengthDropdown', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const showTableHeaders = new ElementPropertyField(null, 'showTableHeaders :', 'setShowTableHeaders', 'getShowTableHeaders', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const visibleColumnFiltersVisibility = new ElementPropertyField(null, 'VisibleColumnFiltersVisibility', 'setVisibleColumnFiltersVisibility', 'getVisibleColumnFiltersVisibility', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        tableConfig.fields=[[columnVisibilityDropDown,commonSearch,showPageLengthDropdown,showTableHeaders,excelExportButton,excelExportFileName,excelExportButtonText,showExpandArrows,showExpandAllArrows,visibleColumnFiltersVisibility]];
        this.properties.push(tableConfig);


        const subTableConfig = new ElementProperty('subtableConfig','SubTableConfig');
        subTableConfig.category = ElementPropertyCategory.TABLE;
        const subTableColumnVisibilityDropDown = new ElementPropertyField(null, 'ColumnVisibility :', 'setSubTableColumnVisibilityDropDown', 'getSubTableColumnVisibilityDropDown', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const subTableCommonSearch = new ElementPropertyField(null, 'CommonSearch :', 'setSubTableCommonSearch', 'getSubTableCommonSearch', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const subTableExcelExportButton = new ElementPropertyField(null, 'ExcelExport :', 'setSubTableExcelExportButton', 'getSubTableExcelExportButton', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const subTableExcelExportFileName = new ElementPropertyField(null, null, 'setSubTableExcelExportFileName', 'getSubTableExcelExportFileName', undefined, undefined, undefined, 'ExcelExportFileName');
        const subTableExcelExportButtonText = new ElementPropertyField(null, null, 'setSubTableExcelExportButtonText', 'getSubTableExcelExportButtonText', undefined, undefined, undefined, 'ExcelExportButtonText');
        const subTableShowExpandArrows = new ElementPropertyField(null, 'ShowExpandArrows:', 'setSubTableShowExpandArrows', 'getSubTableShowExpandArrows', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const subTableShowExpandAllArrows = new ElementPropertyField(null, 'ShowExpandAllArrows :', 'setSubTableShowExpandAllArrows', 'getSubTableShowExpandAllArrows', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const subTableShowPageLengthDropdown = new ElementPropertyField(null, 'showPageLength :', 'setSubTableShowPageLengthDropdown', 'getSubTableShowPageLengthDropdown', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const subTableShowTableHeaders = new ElementPropertyField(null, 'showTableHeaders :', 'setSubTableShowTableHeaders', 'getSubTableShowTableHeaders', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);
        const subTableVisibleColumnFiltersVisibility = new ElementPropertyField(null, 'VisibleColumnFiltersVisibility', 'setSubTableVisibleColumnFiltersVisibility', 'getSubTableVisibleColumnFiltersVisibility', undefined, ['Yes', 'No'], ElementPropertyValueType.SELECT);

        subTableConfig.fields=[[subTableColumnVisibilityDropDown,subTableCommonSearch,subTableShowPageLengthDropdown,subTableShowTableHeaders,subTableExcelExportButton,subTableExcelExportFileName,subTableExcelExportButtonText,subTableShowExpandArrows,subTableShowExpandAllArrows,subTableVisibleColumnFiltersVisibility]];
        this.properties.push(subTableConfig);

    }
}

export class FORMControlProperties extends ControlProperties {
    constructor() {
        super();

        const possibleContentTypes: string[] = [];
        for (const contentType in RequestContentType) {
            possibleContentTypes.push(contentType);
        }

        const contentType = new ElementProperty('contentType','Content Type', 'setFormContentType', 'getFormContentType', undefined, possibleContentTypes, ElementPropertyCategory.FORM);
        this.properties.push(contentType);
        const fileType = new ElementProperty('fileType', 'File Type', undefined, undefined,undefined,undefined);
        const displayFileType = new ElementPropertyField(null, null,'setFileType', 'getFileType', undefined, ['Single', 'Multiple'],ElementPropertyValueType.SELECT);
        fileType.fields=[[displayFileType]];
        this.properties.push(fileType);
    }
}
