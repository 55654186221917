import { Component, OnInit } from '@angular/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ApplicationMenuConfig } from 'src/app/classes/ApplicationMenuConfig';
import { ApplicationPageConfig } from 'src/app/classes/ApplicationPageConfig';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationConfig } from 'src/app/classes/ApplicationConfig';

@Component({
  selector: 'app-new-menu',
  templateUrl: './new-menu.component.html',
  styleUrls: ['./new-menu.component.scss']
})
export class NewMenuComponent implements OnInit {

  model = { name: undefined, page: undefined, path: undefined, show: true, role: undefined, navigationEnvironment: undefined };
  menu: ApplicationMenuConfig[] = [];
  pages: ApplicationPageConfig[] = [];
  titleToggle: boolean;
  isErrorLoading = false;
  errorMessage = null;
  editingId: string = undefined;


  constructor(private dataProviderService: DataProviderService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    this.editingId = this.route.snapshot.params['id'];
    if (this.editingId === undefined) {
      this.titleToggle = true;
    } else {
      this.titleToggle = false;
      this.loadData(this.editingId)
    }

    this.loadPageList();

  }

  submit(event: any, form: NgForm) {
    if (form.invalid) {
      return;
    }
    const menuConfig = new ApplicationMenuConfig();
    menuConfig.code = this.model.path;
    menuConfig.id = this.editingId;
    menuConfig.title = this.model.name;
    menuConfig.pageCode = this.model.page;
    menuConfig.show = this.model.show;
    menuConfig.role = this.model.role;
    menuConfig.navigationEnvironment = this.model.navigationEnvironment;
    if (this.editingId === undefined) {
      this.dataProviderService.addMenu(menuConfig).then(done => {
        alert('Menu Created');
        this.router.navigate(['/menu']);
        form.reset();
      }, error => {
        alert('Unable to create menu ' + error);
      });
    } else {
      console.log("Calling", menuConfig)
      this.dataProviderService.updateMenu(menuConfig).then(done => {
        alert('Updated Menu');
        this.router.navigate(['/menu']);
      }, error => {
        alert('Unable to update menu ' + error);
      });
    }

  }

  loadData(id: string) {
    this.dataProviderService.getMenuConfig(id).then(menuConfig => {
      this.model.name = menuConfig.title;
      this.model.path = menuConfig.code;
      this.model.page = menuConfig.pageCode;
      this.model.role = menuConfig.role;
      this.model.show = menuConfig.show;
      this.model.navigationEnvironment = menuConfig.navigationEnvironment;
    }, error => {
      this.isErrorLoading = true;
      this.errorMessage = 'Unable to retrieve the menu: ' + error;
    });
  }

  loadPageList() {
    this.dataProviderService.getAppConfig().then((appConfig: ApplicationConfig) => {
      this.pages.splice(0, this.pages.length);
      appConfig.pages.forEach(v => {
        this.pages.push(v);
      })
    });
  }
}
