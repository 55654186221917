import { Injectable } from '@angular/core';
import { DataProviderService } from './data-provider.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { RWElementBaseDataSource } from '../classes/RWElementBaseDataSource';
import { RequestContentType } from '../classes/RequestContentType';
import { ElementPropertyService } from './element-property.service';
import { Util } from '../util/util';
import { RWDataSource, RWDataSourceResponseType } from '../classes/RWDataSource';
import { RWElement } from '../classes/RWElement';
import { RWDataSourceHttpConfiguration } from '../classes/RWDataSourceHttpConfiguration';
import { saveAs } from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class DataSourceExecutorService {
  dataSourceResponses: { [key: string]: any } = {};
  dataSourceSubjects: { [key: string]: { config: RWElementBaseDataSource, subject: Subject<any> }[] } = {};
  dataSourceSubscribers: { [key: string]: { config: RWElementBaseDataSource, observable: Observable<any> }[] } = {};
  inProcessCalls: { [key: string]: boolean } = {};
  popupContextData: any = undefined;

  constructor(private dataProviderService: DataProviderService,
    private http: HttpClient, private elmentPropertyService: ElementPropertyService, private route: ActivatedRoute) {
    this.elmentPropertyService.showPopupSettingsChanged.subscribe(resp => {
      this.popupContextData = resp.popupContextData;
    });
  }

  private configSubscriberExistsCheck(dataSourceConfig: RWElementBaseDataSource) {
    if (this.dataSourceSubscribers[dataSourceConfig.dataSource] === undefined) {
      return false;
    }
    return this.dataSourceSubscribers[dataSourceConfig.dataSource].filter(v => v.config.enableLocalSubscription === dataSourceConfig.enableLocalSubscription).length > 0;
  }

  private getConfigBasedSubscribers(dataSourceConfig: RWElementBaseDataSource) {
    if (this.dataSourceSubscribers[dataSourceConfig.dataSource] === undefined) {
      return undefined;
    }
    const subscriptions = this.dataSourceSubscribers[dataSourceConfig.dataSource].filter(v => v.config.enableLocalSubscription === dataSourceConfig.enableLocalSubscription);
    return subscriptions;
  }

  private getConfigBasedSubjects(dataSourceConfig: RWElementBaseDataSource) {
    if (this.dataSourceSubjects[dataSourceConfig.dataSource] === undefined) {
      return undefined;
    }
    const subjects = this.dataSourceSubjects[dataSourceConfig.dataSource].filter(v => v.config.enableLocalSubscription === dataSourceConfig.enableLocalSubscription);
    return subjects;
  }

  private getConfigBasedSubscriberObservable(dataSourceConfig: RWElementBaseDataSource) {
    if (this.dataSourceSubscribers[dataSourceConfig.dataSource] === undefined) {
      return undefined;
    }
    const subscription = this.dataSourceSubscribers[dataSourceConfig.dataSource].find(v => v.config.enableLocalSubscription === dataSourceConfig.enableLocalSubscription);
    if (subscription === undefined) {
      console.warn(`Warning, subscription should not be undefined - ${dataSourceConfig.dataSource} - ${dataSourceConfig.enableLocalSubscription}`);
      return undefined;
    }
    return subscription.observable;
  }

  load(dataSourceConfig: RWElementBaseDataSource, route: ActivatedRoute, pathParams?: any, queryParams?: any, body?: any, headers?: any, contentType?: RequestContentType, element?: RWElement): Observable<any> {
    if (this.dataSourceSubscribers[dataSourceConfig.dataSource] === undefined) {
      this.dataSourceSubjects[dataSourceConfig.dataSource] = [];
      this.dataSourceSubscribers[dataSourceConfig.dataSource] = [];
    }
    if (!this.configSubscriberExistsCheck(dataSourceConfig)) {
      const newSubjectObject = { config: dataSourceConfig, subject: new Subject<any>() };
      this.dataSourceSubjects[dataSourceConfig.dataSource].push(newSubjectObject);
      this.dataSourceSubscribers[dataSourceConfig.dataSource].push({ config: dataSourceConfig, observable: newSubjectObject.subject.asObservable() });
    }
    this.loadDataSource(dataSourceConfig, route, pathParams, queryParams, body, headers, contentType, element);
    return this.getConfigBasedSubscriberObservable(dataSourceConfig);
  }

  private unSubscribe(dataSourceConfig: RWElementBaseDataSource) {
    this.dataSourceSubjects[dataSourceConfig.dataSource].forEach(v => v.subject.unsubscribe());
    delete this.dataSourceSubjects[dataSourceConfig.dataSource];
    delete this.dataSourceSubscribers[dataSourceConfig.dataSource];
  }

  private informSubscribers(dataSourceConfig: RWElementBaseDataSource) {
    this.dataSourceSubjects[dataSourceConfig.dataSource].forEach(v => v.subject.next(this.dataSourceResponses[dataSourceConfig.dataSource]));
    if (this.dataSourceSubjects[dataSourceConfig.dataSource] !== undefined && this.dataSourceSubjects[dataSourceConfig.dataSource] !== null) {
      for (let i = 0; i < this.dataSourceSubjects[dataSourceConfig.dataSource].length; i++) {
        if (!this.dataSourceSubjects[dataSourceConfig.dataSource][i].config.enableLocalSubscription) {
          this.dataSourceSubjects[dataSourceConfig.dataSource].splice(i, 1);
        }
      }
    }
    if (this.dataSourceSubscribers[dataSourceConfig.dataSource] !== undefined && this.dataSourceSubscribers[dataSourceConfig.dataSource] !== null) {
      for (let i = 0; i < this.dataSourceSubscribers[dataSourceConfig.dataSource].length; i++) {
        if (!this.dataSourceSubscribers[dataSourceConfig.dataSource][i].config.enableLocalSubscription) {
          this.dataSourceSubscribers[dataSourceConfig.dataSource].splice(i, 1);
        }
      }
    }
  }

  private loadDataSource(dataSourceConfig: RWElementBaseDataSource, route: ActivatedRoute, pathParams?: any, queryParams?: any, body?: any, headers?: any, contentType?: RequestContentType, element?: RWElement): void {
    const dataSourceCode = dataSourceConfig.dataSource;
    const dis = this;
    if (dataSourceConfig.inherited) {
      if (dis.dataSourceResponses[dataSourceConfig.dataSource] !== undefined) {
        dis.informSubscribers(dataSourceConfig);
      }
      console.warn('Not calling it is inherited', dataSourceCode);
      return;
    } else if (this.inProcessCalls[dataSourceCode]) {
      console.warn('Not loading call in progress', dataSourceCode);
      return;
    }

    dis.dataProviderService.getAppConfig().then(resp => {
      console.log("resp-data", resp)
      const dsObject = resp.dataSources.find(c => c.code === dataSourceCode);

      this.getResolvedDataSource(dsObject, route, pathParams, queryParams, body, headers, contentType, element).then(dataSourceObject => {
        dis.inProcessCalls[dataSourceCode] = true;
        dataSourceObject.load(dis.http, pathParams, queryParams, body, headers, contentType, dsObject).subscribe(resp => {
          delete dis.inProcessCalls[dataSourceCode];
          dis.setResponseFor(dataSourceCode, resp);
          dis.informSubscribers(dataSourceConfig);
          if (dsObject.responseType === RWDataSourceResponseType.blob) {
            let fileName: string[];
            let finalFileName: string;

            const blob = new Blob([resp], { type: "application/pdf;charset=utf-8" });

            if (dsObject.fileName !== '') {
              fileName = JSON.parse(dsObject.fileName);
            } else {
              fileName = [];
            }

            if (fileName.length > 0) {
              if (fileName[1].indexOf('$ELEMENT.context.data.') > -1) {
                const key = fileName[1].replace('$ELEMENT.context.data.', '');
                const varName = '$ELEMENT.context.data.' + key;
                const value = Util.getValueFromObjectByPropertyPath(element.getContext().data, key);
                fileName[1] = value;
              } else if (fileName[1].indexOf('$POPUP_CONTEXT.data.') > -1) {
                const key = fileName[1].replace('$POPUP_CONTEXT.data.', '');
                const varName = '$POPUP_CONTEXT.data.' + key;
                let value = ''
                if (this.popupContextData !== undefined && this.popupContextData !== null) {
                  value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
                }
                fileName[1] = value;
              } else if (fileName[1].indexOf('$PAYLOAD.') > -1) {
                const key = fileName[1].replace('$PAYLOAD.', '');
                const varName = '$PAYLOAD.' + key;
                const parts = fileName[1].split('.');
                const dataSourceCode = parts[1];
                const parameter = key.replace(dataSourceCode + ".", '');
                const result = this.elmentPropertyService.getPayLoadData()[dataSourceCode];
                const value = Util.evaluateConcat(parameter, result);
                fileName[1] = value;
              } else {
                fileName[1] = fileName[1];
              }
            } else {
              fileName[0] = dsObject.code;
            }

            finalFileName = fileName.join("");

            saveAs(blob, finalFileName);

          }
        })
      })
    });
  }

  setResponseFor(dataSourceCode: string, response: any): void {
    this.dataSourceResponses[dataSourceCode] = response;
  }

  getResponseFor(dataSourceCode: string): void {
    return this.dataSourceResponses[dataSourceCode]
  }

  getResolvedDataSource(dataSourceObject: RWDataSource, route: ActivatedRoute, pathParams?: any, queryParams?: any, body?: any, headers?: any, contentType?: RequestContentType, element?: RWElement): Promise<RWDataSource> {
    return new Promise<RWDataSource>(async (resolve, reject) => {
      let finalBody: { [key: string]: any } = {};
      let copyBody: { [key: string]: any } = {};
      let finalQueryParams: { [key: string]: any } = {};
      let copyQueryParams: { [key: string]: any } = {};

      if (dataSourceObject.http.defaultBody === undefined || dataSourceObject.http.defaultBody === '' || this.isJson(dataSourceObject.http.defaultBody) === false) {
        dataSourceObject.http.defaultBody = '{}';
      }
      copyBody = JSON.parse(dataSourceObject.http.defaultBody);

      if (dataSourceObject.http.defaultQueryParams === undefined || dataSourceObject.http.defaultQueryParams === '' || this.isJson(dataSourceObject.http.defaultQueryParams) === false) {
        dataSourceObject.http.defaultQueryParams = '{}';
      }

      copyQueryParams = JSON.parse(dataSourceObject.http.defaultQueryParams);

      if (dataSourceObject.http.path.indexOf('$ROUTE.PATH.PARAM') > -1) {
        const pathParts = dataSourceObject.http.path.split('/');
        const pathVariablePathParts = pathParts.filter(v => v.indexOf('$ROUTE.PATH.PARAM') > -1);
        for (const pathVariablePathPart of pathVariablePathParts) {
          const key = pathVariablePathPart.replace('$ROUTE.PATH.PARAM', '');
          const varName = '$ROUTE.PATH.PARAM' + key;
          let url = window.location.hash.substr(1).split('/');
          const value = url[key];
          dataSourceObject.http.path = dataSourceObject.http.path.replace(varName, value);
        }
      }

      if (dataSourceObject.http.path.indexOf('$ROUTE.QUERY.') > -1) {
        const pathParts = dataSourceObject.http.path.split('/');
        const pathVariablePathParts = pathParts.filter(v => v.indexOf('$ROUTE.QUERY.') > -1);
        for (const pathVariablePathPart of pathVariablePathParts) {
          const key = pathVariablePathPart.replace('$ROUTE.QUERY.', '');
          const varName = '$ROUTE.QUERY.' + key;
          const value = route.snapshot.queryParams[key];
          dataSourceObject.http.path = dataSourceObject.http.path.replace(varName, value);
        }
      }

      if (pathParams != undefined) {
        for (var k of Object.keys(pathParams)) {
          if (pathParams[k].indexOf('$ROUTE.PATH.PARAM') > -1) {
            const pathParamsValue = pathParams[k];
            const key = pathParamsValue.replace('$ROUTE.PATH.PARAM', '');
            const varName = '$ROUTE.PATH.PARAM' + key;
            let url = window.location.hash.substr(1).split('/');
            const value = url[key];
            pathParams[k] = pathParams[k].replace(varName, value);
          } else if (pathParams[k].indexOf('$ROUTE.QUERY.') > -1) {
            const pathParamsValue = pathParams[k];
            const key = pathParamsValue.replace('$ROUTE.QUERY.', '');
            const varName = '$ROUTE.QUERY.' + key;
            const value = route.snapshot.queryParams[key];
            pathParams[k] = pathParams[k].replace(varName, value);
          } else if (pathParams[k].indexOf('$POPUP_CONTEXT.data') > -1) {
            console.log('POPUP_DATA_VAR_PATH', pathParams[k])
            const pathParamsValue = pathParams[k];
            const key = pathParamsValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            pathParams[k] = pathParams[k].replace(varName, value);
          }
        }
      }

      if (queryParams != undefined) {
        for (var k of Object.keys(queryParams)) {
          if (queryParams[k].indexOf('$ROUTE.PATH.PARAM') > -1) {
            const queryParamsValue = queryParams[k];
            const key = queryParamsValue.replace('$ROUTE.PATH.PARAM', '');
            const varName = '$ROUTE.PATH.PARAM' + key;
            let url = window.location.hash.substr(1).split('/');
            const value = url[key];
            queryParams[k] = queryParams[k].replace(varName, value);
          } else if (queryParams[k].indexOf('$ROUTE.QUERY.') > -1) {
            const queryParamsValue = queryParams[k];
            const key = queryParamsValue.replace('$ROUTE.QUERY.', '');
            const varName = '$ROUTE.QUERY.' + key;
            const value = route.snapshot.queryParams[key];
            queryParams[k] = queryParams[k].replace(varName, value);
          } else if (queryParams[k].indexOf('$POPUP_CONTEXT.data') > -1) {
            console.log('POPUP_DATA_VAR_QUERY', queryParams[k])
            const queryParamsValue = queryParams[k];
            const key = queryParamsValue.replace('$POPUP_CONTEXT.data.', '');
            const varName = '$POPUP_CONTEXT.data.' + key;
            let value = '';
            if (this.popupContextData !== undefined && this.popupContextData !== null) {
              value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
            }
            queryParams[k] = queryParams[k].replace(varName, value);
          }
        }
      }

      if (headers != undefined) {
        for (var k of Object.keys(headers)) {
          if (headers[k].indexOf('$ROUTE.PATH.PARAM') > -1) {
            const headersValue = headers[k];
            const key = headersValue.replace('$ROUTE.PATH.PARAM', '');
            const varName = '$ROUTE.PATH.PARAM' + key;
            let url = window.location.hash.substr(1).split('/');
            const value = url[key];
            headers[k] = headers[k].replace(varName, value);
          } else if (headers[k].indexOf('$ROUTE.QUERY.') > -1) {
            const headersValue = headers[k];
            const key = headersValue.replace('$ROUTE.QUERY.', '');
            const varName = '$ROUTE.QUERY.' + key;
            const value = route.snapshot.queryParams[key];
            headers[k] = headers[k].replace(varName, value);
          }
        }
      }

      if (dataSourceObject.http !== undefined && dataSourceObject.http !== null) {
        if (dataSourceObject.http.defaultQueryParams !== undefined && dataSourceObject.http.defaultQueryParams !== null) {
          try {
            console.log('dataSourceObject.http.defaultQueryParams', dataSourceObject.http.defaultQueryParams);
            for (var k of Object.keys(copyQueryParams)) {
              if (copyQueryParams[k].indexOf('$ROUTE.PATH.PARAM') > -1) {
                const queryParamsValue = copyQueryParams[k];
                const key = queryParamsValue.replace('$ROUTE.PATH.PARAM', '');
                const varName = '$ROUTE.PATH.PARAM' + key;
                let url = window.location.hash.substr(1).split('/');
                const value = url[key];
                finalQueryParams[k] = copyQueryParams[k].replace(varName, value);
              } else if (copyQueryParams[k].indexOf('$ROUTE.QUERY.') > -1) {
                const queryParamsValue = copyQueryParams[k];
                const key = queryParamsValue.replace('$ROUTE.QUERY.', '');
                const varName = '$ROUTE.QUERY.' + key;
                const value = route.snapshot.queryParams[key];
                finalQueryParams[k] = copyQueryParams[k].replace(varName, value);
              } else if (copyQueryParams[k].indexOf('$POPUP_CONTEXT.data') > -1) {
                console.log('POPUP_DATA_VAR_QUERY', copyQueryParams[k])
                const queryParamsValue = copyQueryParams[k];
                const key = queryParamsValue.replace('$POPUP_CONTEXT.data.', '');
                const varName = '$POPUP_CONTEXT.data.' + key;
                let value = '';
                if (this.popupContextData !== undefined && this.popupContextData !== null) {
                  value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
                }
                finalQueryParams[k] = copyQueryParams[k].replace(varName, value);
              } else if (copyQueryParams[k].indexOf('$PAYLOAD.') > -1) {
                const queryParamsValue = copyQueryParams[k];
                const key = queryParamsValue.replace('$PAYLOAD.', '');
                const varName = '$PAYLOAD.' + key;
                const parts = copyQueryParams[k].split('.');
                const dataSourceCode = parts[1];
                const parameter = key.replace(dataSourceCode + ".", '');
                const result = this.elmentPropertyService.getPayLoadData()[dataSourceCode];
                const value = Util.evaluateConcat(parameter, result);
                let objArrayToString = []
                if (Array.isArray(value)) {
                  for(let values of Object.values(value)) {
                       objArrayToString.push(Object.values(values)[0])
                  }
                  console.log("$objArrayToString",objArrayToString)
                  finalQueryParams[k] = objArrayToString.toString();
                } else {
                  finalQueryParams[k] = copyQueryParams[k].replace(varName, value);
                }
              } else {
                finalQueryParams[k] = copyQueryParams[k];
              }
            }
          } catch (e) {
            console.error('Error converting defaultQueryParams', e);
          }
        }

        if (dataSourceObject.http.defaultBody !== undefined && dataSourceObject.http.defaultBody !== null) {
          try {
            console.log('DataSourceDefaultBody', dataSourceObject.http)
            for (var k of Object.keys(copyBody)) {
              if (copyBody[k].indexOf('$ROUTE.PATH.PARAM') > -1) {
                const queryParamsValue = copyBody[k];
                const key = queryParamsValue.replace('$ROUTE.PATH.PARAM', '');
                const varName = '$ROUTE.PATH.PARAM' + key;
                let url = window.location.hash.substr(1).split('/');
                const value = url[key];
                finalBody[k] = copyBody[k].replace(varName, value);
              } else if (copyBody[k].indexOf('$ROUTE.QUERY.') > -1) {
                const queryParamsValue = copyBody[k];
                const key = queryParamsValue.replace('$ROUTE.QUERY.', '');
                const varName = '$ROUTE.QUERY.' + key;
                const value = route.snapshot.queryParams[key];
                finalBody[k] = copyBody[k].replace(varName, value);
              } else if (copyBody[k].indexOf('$ELEMENT.context.data.') > -1) {
                const queryParamValue = copyBody[k];
                const key = queryParamValue.replace('$ELEMENT.context.data.', '');
                const varName = '$ELEMENT.context.data.' + key;
                const value = Util.getValueFromObjectByPropertyPath(element.getContext().data, key);
                finalBody[k] = copyBody[k].replace(varName, value);
              } else if (copyBody[k].indexOf('$POPUP_CONTEXT.data') > -1) {
                const queryParamsValue = copyBody[k];
                const key = queryParamsValue.replace('$POPUP_CONTEXT.data.', '');
                const varName = '$POPUP_CONTEXT.data.' + key;
                let value = '';

                if (this.popupContextData !== undefined && this.popupContextData !== null) {
                  value = Util.getValueFromObjectByPropertyPath(this.popupContextData, key);
                }
                finalBody[k] = copyBody[k].replace(varName, value);
              } else if (copyBody[k].indexOf('$PAYLOAD.') > -1) {
                const queryParamsValue = copyBody[k];
                const key = queryParamsValue.replace('$PAYLOAD.', '');
                const varName = '$PAYLOAD.' + key;
                const parts = copyBody[k].split('.');
                const dataSourceCode = parts[1];
                const parameter = key.replace(dataSourceCode + ".", '');
                const result = this.elmentPropertyService.getPayLoadData()[dataSourceCode];
                const value = Util.evaluateConcat(parameter, result);
                if(Array.isArray(value)) {
                  finalBody[k] = value;
                } else {
                  finalBody[k] = copyBody[k].replace(varName, value);
                }
              } else if (copyBody[k].indexOf('$DS.') > -1) {
                console.log("dataSourceObject.http.defaultBody[k]", dataSourceObject.http.defaultBody[k], dataSourceObject)
                const queryParamsValue = copyBody[k];
                const key = queryParamsValue.replace('$DS.', '');
                const varName = '$DS.' + key;
                const dataSourceCode = key.split('.')[0];
                const finalPath = key.replace(dataSourceCode + '.response.', '');
                let value = ''
                try {

                  let val = await new Promise((res, rej) => {
                    this.load({
                      dataSource: dataSourceCode,
                      inherited: false,
                      property: '',
                      shouldUseDataSourceResult: true,
                      enableLocalSubscription: false
                    }, this.route).subscribe(rep => {
                      value = Util.evaluateConcat(finalPath, rep);
                      res(value);
                    });
                  });
                  finalBody[k] = val;
                } catch (e) {
                  console.error('Error converting defaultBody with DS', e);
                }
              } else {
                finalBody[k] = copyBody[k];
              }
            }
          } catch (e) {
            console.error('Error converting defaultBody', e);
          }
        }
      }
      const ds = new RWDataSource();
      ds.code = dataSourceObject.code;
      ds.id = dataSourceObject.id;
      ds.name = dataSourceObject.name;
      ds.type = dataSourceObject.type;
      ds.expectedStatusCode = dataSourceObject.expectedStatusCode;
      ds.validationExpression = dataSourceObject.validationExpression;
      ds.validationErrorMessageRaw = dataSourceObject.validationErrorMessageRaw;
      ds.validationErrorMessageProperty = dataSourceObject.validationErrorMessageProperty;
      const httpConfig = new RWDataSourceHttpConfiguration();
      httpConfig.defaultBody = finalBody;
      httpConfig.defaultHeaders = dataSourceObject.http.defaultHeaders;
      httpConfig.defaultPathParams = dataSourceObject.http.defaultPathParams;
      httpConfig.defaultQueryParams = finalQueryParams;
      httpConfig.host = dataSourceObject.http.host;
      httpConfig.method = dataSourceObject.http.method;
      httpConfig.path = dataSourceObject.http.path;
      httpConfig.port = dataSourceObject.http.port;
      httpConfig.scheme = dataSourceObject.http.scheme;
      ds.http = httpConfig;
      return resolve(ds);
    });
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}
