import { RWElementTableHeaderAction } from './RWElementTableHeaderAction';
import { RWElementTableHeaderClickableAction } from './RWElementTableHeaderClickableAction';
import { RWAction } from './RWAction';

export class RWElementTableHeader {
    title: string;
    dataProperty: string;
    style: string;
    show: boolean;
    role: string;
    dataType: string;
    actions: RWElementTableHeaderAction[];
    clickableAction: RWAction;
    subClickableAction: RWAction;
    columnOrder: number;

    constructor(title: string, dataProperty: string, style: string, show: boolean, role: string, dataType: string, columnOrder: number) {
        this.title = title;
        this.dataProperty = dataProperty;
        this.style = style;
        this.show = show;
        this.role = role;
        this.dataType = dataType;
        this.columnOrder = columnOrder;
    }
}

