import { RWElement } from './RWElement';
import { AuditInfo } from './AuditInfo';

export class ApplicationPageConfig {
  id: string;
  code: string;
  name: string;
  title: string;
  layout: string;
  version: number;
  designData: RWElement[] = [];
  audit?: AuditInfo = undefined;
  
  constructor() { }
}
