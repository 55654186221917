import { Injectable } from '@angular/core';
import { ApplicationConfig } from '../classes/ApplicationConfig';
import { RWElementParser } from '../classes/RWElementParser';
import { ApplicationPageConfig } from '../classes/ApplicationPageConfig';
import { HttpClient } from '@angular/common/http';
import { ApplicationMenuConfig } from '../classes/ApplicationMenuConfig';
import { RWDataSource } from '../classes/RWDataSource';
import { environment } from '../../environments/environment';
import { ApplicationService, MenuService, PageService, DataSourceService } from '../rest/api/api';
import { RWElement } from '../classes/RWElement';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService {

  private static APPLICATION_ID_KEY = 'APPLICATION_ID';
  private applicationId: string = undefined;
  private latestAppConfig: ApplicationConfig = undefined;

  constructor(private http: HttpClient,
    private applicationService: ApplicationService,
    private menuService: MenuService,
    private pageService: PageService,
    private dataSourceService: DataSourceService) {
    this.applicationId = environment.applicationId;
  }

  getAppConfig(): Promise<ApplicationConfig> {
    return new Promise((resolve, reject) => {
      console.info('Application ID', this.applicationId);
      this.applicationService.getApplicationByID(this.applicationId).subscribe((resp: ApplicationConfig) => {
        return resolve(RWElementParser.jsonToApplicationConfig(resp));
      }, error => {
        return reject(error);
      });
    });
  }

  updateConfig(appConfig: ApplicationConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.applicationService.updateApplication(this.applicationId, appConfig).subscribe(resp => {
        return resolve(RWElementParser.jsonToApplicationConfig(resp));
      }, error => {
        return reject(error);
      })
    });
  }

  getAuditInfo(isCreate: boolean = false) {

    if (isCreate) {

    }
  }

  addMenu(menuConfig: ApplicationMenuConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.menuService.createMenuForAnApplication(this.applicationId, menuConfig).subscribe(resp => {
        resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  getMenu(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.menuService.getMenusByApplicationId(this.applicationId).subscribe(resp => {
        resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  deleteMenu(menu: ApplicationMenuConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.menuService.deleteMenuByApplicationAndMenuIDs(this.applicationId, menu.id).subscribe(resp => {
        resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  getPageConfig(pageId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageService.getPageByApplicationAndPageIDs(this.applicationId, pageId).subscribe(resp => {
        return resolve(RWElementParser.jsonToPageConfig(resp));
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  getDataSourceConfig(dataSourceId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataSourceService.getDataSourceByApplicationAndDataSourceIDs(this.applicationId, dataSourceId).subscribe(resp => {
        return resolve(RWElementParser.jsonToRWDataSource(resp));
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  getDataSources(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataSourceService.getDataSourcesByApplicationId(this.applicationId).subscribe(resp => {
        return resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  deletePage(page: ApplicationPageConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageService.deletePageByApplicationAndPageIDs(this.applicationId, page.id).subscribe(resp => {
        resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  deleteDataSource(dataSource: RWDataSource): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataSourceService.deleteDataSourceByApplicationAndDataSourceIDs(this.applicationId, dataSource.id).subscribe(resp => {
        resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  addPage(pageConfig: ApplicationPageConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageService.createPageForAnApplication(this.applicationId, pageConfig).subscribe(resp => {
        resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  sanitizePageWithoutValues(pageConfig: ApplicationPageConfig): ApplicationPageConfig {
    pageConfig.designData.forEach(v => {
      v = this.sanitizeElementWithoutValues(v);
      return v;
    });
    return pageConfig;
  }

  sanitizeElementWithoutValues(element: RWElement): RWElement {
    element.setExtraInfo(undefined);
    element.setValue(undefined);
    if (element.getChildren() !== undefined && element.getChildren() !== null) {
      const children = element.getChildren();
      children.forEach(v => {
        return this.sanitizeElementWithoutValues(v)
      });
      for (let [index, child] of children.entries()) {
        element.setChildren(index, child);
      }
    }
    return element;
  }

  updatePage(pageConfig: ApplicationPageConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageService.updatePageByApplicationIdAndPageID(this.applicationId, pageConfig.id, this.sanitizePageWithoutValues(pageConfig)).subscribe(resp => {
        resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  updateDataSource(dataSourceConfig: RWDataSource, dataSourceId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataSourceService.updateDataSourceByApplicationIdAndDataSourceID(this.applicationId, dataSourceConfig.id, dataSourceConfig).subscribe(resp => {
        resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  addDataSource(dataSource: RWDataSource): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataSourceService.createDataSourceForAnApplication(this.applicationId, dataSource).subscribe(resp => {
        resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  getMenuConfig(menuId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.menuService.getMenuByApplicationAndMenuIDs(this.applicationId, menuId).subscribe(resp => {
        return resolve(RWElementParser.jsonToMenuConfig(resp));
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }

  updateMenu(menuConfig: ApplicationMenuConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.menuService.updateMenuByApplicationIdAndMenuID(this.applicationId, menuConfig.id, menuConfig).subscribe(resp => {
        resolve(resp);
      }, error => {
        console.warn('Error Config Loading', error);
        reject(error);
      });
    });
  }


  getAppSettingsConfig(): Promise<ApplicationConfig> {
    return new Promise((resolve, reject) => {
      console.info('Application ID', this.applicationId);
      this.applicationService.getApplicationSettingsByID(this.applicationId).subscribe((resp: ApplicationConfig) => {
        return resolve(RWElementParser.jsonToApplicationConfig(resp));
      }, error => {
        return reject(error);
      });
    });
  }

  updateSettingConfig(appConfig: ApplicationConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.applicationService.updateSettingsApplication(this.applicationId, appConfig).subscribe(resp => {
        return resolve(RWElementParser.jsonToApplicationConfig(resp));
      }, error => {
        return reject(error);
      })
    });
  }

}

