export enum ElementPropertyCategory {
    SELECT = 'SELECT',
    CHECKBOX = 'CHECKBOX',
    RADIO = 'RADIO',
    BUTTON = 'BUTTON',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    RANGE = 'RANGE',
    RBAC ='RBAC',
    CONTROL = 'CONTROL',
    FORM = 'FORM',
    EVENT = 'EVENT',
    IMAGE = 'IMAGE',
    ANCHOR = 'ANCHOR',
    TABLE = 'TABLE',
    GENERAL = 'GENERAL',
}
