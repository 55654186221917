export const environment = {
  production: false,
  keycloakConfig: {
    clientId: 'rw_builder',
    realm: 'RR_DLR_1',
    url: 'https://auth.realreco.demo.realware.app/auth'
  },
  apiBaseUrl: 'https://api.ui.realreco.demo.realware.app',
  applicationId: 'dad707c1-bae2-4daa-8143-5b33f2ccc397',
  builderRole: 'RW_BUILDER'
};
