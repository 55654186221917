import { Component, OnInit } from '@angular/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { TreeTableData, TreeTableHeaderObject, TtDataType, TreeTableRow, TreeTableRowAction } from 'angular-tree-table';
import { ApplicationConfig } from 'src/app/classes/ApplicationConfig';
import { ApplicationMenuConfig } from 'src/app/classes/ApplicationMenuConfig';
import { ApplicationPageConfig } from 'src/app/classes/ApplicationPageConfig';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {


  tableConfig: any = {
    context: this,
    showExpandArrows: false,
    showExpandAllArrows: false,
    excelExportButton: true,
    excelExportFileName: 'Menu',
    excelExportButtonText: 'Excel'
  };

  tableData: TreeTableData;
  tableHeaders: TreeTableHeaderObject[] = [];
  menu: ApplicationMenuConfig[] = [];
  pages: ApplicationPageConfig[] = [];

  constructor(private dataProviderService: DataProviderService, private router: Router) { }

  ngOnInit(): void {
    this.tableData = new TreeTableData(this.tableConfig);
    this.tableInit();
    this.loadData();
  }

  loadData() {
    const rows = [];
    this.tableData.isLoading = true;
    this.dataProviderService.getAppConfig().then((appConfig: ApplicationConfig) => {
      this.tableData.isLoading = false;
      this.pages.splice(0, this.pages.length);
      appConfig.pages.forEach(v => {
        this.pages.push(v);
      })
      for (const [index, menuConfig] of Object.entries(appConfig.menu)) {
        menuConfig['sno'] = parseInt(index) + 1;
        menuConfig['pageWithVersion'] = menuConfig.pageCode.split('^')[0] + ' v' + menuConfig.pageCode.split('^')[1];
        const row = new TreeTableRow(menuConfig.id + '_' + index, menuConfig, false, null);

        const editAction = new TreeTableRowAction('Edit', 'Edit Menu ' + menuConfig.title, 'btn btn-sm btn-secondary', this.editMenuClicked);
        editAction.context = this;
        row.actions.push(editAction);

        const deleteAction = new TreeTableRowAction('Delete', 'Delete Menu ' + menuConfig.title, 'btn btn-sm btn-danger', this.deleteMenuClicked);
        deleteAction.context = this;
        row.actions.push(deleteAction);

        rows.push(row);
      }
      this.tableData.data = rows;
    });
  }

  deleteMenuClicked(menu: any) {
    let result = confirm('Are you sure, you want delete ' + menu.title);
    if (result) {
      this.dataProviderService.deleteMenu(menu).then(done => {
        alert('Menu Deleted');
        this.loadData();
      }, error => {
        alert('Unable to create page ' + error);
      });
    }
  }

  tableInit() {
    this.tableHeaders.splice(0, this.tableHeaders.length);
    const header = new TreeTableHeaderObject('S.No', 'sno', null, true);
    header.dataType = TtDataType.NUMBER;
    this.tableHeaders.push(header);

    this.tableHeaders.push(new TreeTableHeaderObject('Title', 'title', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Path', 'code', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Page', 'pageWithVersion', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Show', 'show', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Role', 'role', null, true));
    const actions = new TreeTableHeaderObject('Actions', '', null, true);
    actions.dataType = TtDataType.ACTIONS;
    this.tableHeaders.push(actions);
    this.tableData.headers = this.tableHeaders;
  }

  editMenuClicked(menu: any) {
    this.router.navigate(['/menu/edit/' + menu['id']]);
  }
}
