export enum RWElementType {
  CONTROL = 'CONTROL',
  DIV = 'DIV',
  SPAN = 'SPAN',
  SECTION = 'SECTION',
  LABEL = 'LABEL',
  BUTTON = 'BUTTON',
  I = 'I',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
  ANCHOR = 'ANCHOR',
  P = 'P',
  SELECT = 'SELECT',
  IMAGE = 'IMAGE',
  VERTICALACCORDION = 'VERTICALACCORDION'
}
