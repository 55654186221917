import { RWEventType } from './RWEventType';
import { RWAction } from './RWAction';
import { RWAPIMethod } from './RWAPIMethod';

export class RWEvent {
  type: RWEventType;
  action: RWAction;

  constructor(type: RWEventType) {
    this.type = type;
  }
}
