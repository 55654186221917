import { RWAction } from './RWAction';

export class RWElementTableHeaderAction {
    text: string;
    title: string;
    action: RWAction;
    constructor(text: string, title: string, action: RWAction) {
        this.text = text;
        this.title = title;
        this.action = action;
    }
}

