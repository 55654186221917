import { RWElementControlType } from './RWElementControlType';
import { RWElementType } from './RWElementType';
import { RWElementSelectOption } from './RWElementSelectOption';
import { RWEvent } from './RWEvent';
import { ElementPropertyFieldEvent } from "./ElementPropertyFieldEvent";
import { RWAction } from './RWAction';
import { RWActionProperty } from './RWActionProperty';
import { RWElementTableHeader } from './RWTableHeader';
import { RWEventType } from './RWEventType';
import { RWAPIMethod } from './RWAPIMethod';
import { RWElementBaseDataSource } from './RWElementBaseDataSource';
import { RWElementOptionsDataSource } from './RWElementOptionsDataSource';
import { RWActionType } from './RWActionType';
import { RequestContentType } from './RequestContentType';
import { RWElementParser } from './RWElementParser';
import { RWElementTableHeaderAction } from './RWElementTableHeaderAction';
import { RWTableConfig } from './RWTableConfig';
import { RWPayLoadDataSource } from './RWPayLoadDataSource';
import { RWValidations } from './RWValidations';

export class RWElement {

  private classes: string[] = [];
  private keywords: string[] = [];
  private id: string | undefined = undefined;
  private attributes: {
    [key: string]: any;
  } = {};
  private categories: string[] = [];
  private elementType: RWElementType = RWElementType.DIV;
  private controlType: RWElementControlType | undefined = undefined;
  private controlOptions: {
    [key: string]: any;
  } = {};
  private contentText: string = '';
  private role: string = '';
  private childrenDataProperty: string = ''
  private level = 0;
  private children: RWElement[] = [];

  private toolboxName?: string;
  private toolboxIcon?: string;

  private fontSize: string = '';
  private name: string = '';
  private title: string = '';
  private placeholder: string = '';
  private radioValue: string = '';
  private checked: boolean;
  private type: string;
  private min: string = '';
  private max: number;
  private format: string;
  private order: number = 0;
  private options: RWElementSelectOption[] = [];
  private tableHeaders: RWElementTableHeader[] = [];
  private subTableHeaders: RWElementTableHeader[] = [];
  private src: string;
  private height: number = 100;
  private width: number = 100;
  private href: string;
  private events: RWEvent[] = [];
  private verticalAccordionHeaderText: string = '';
  private router: string = undefined;
  private inlineStyles: { [key: string]: string } = undefined;

  // DataSources
  private valueDataSource?: RWElementBaseDataSource;
  private optionsDataSource?: RWElementOptionsDataSource;
  private payLoadDataSource?: RWPayLoadDataSource;

  // Form
  private formContentType?: RequestContentType;

  public value: string = '';

  private extraInfo: any = undefined;
  private dropDownType: string = 'Single';

  private enableDynamicallyDuplicate = false;
  private enableDynamicallyRemove = false;

  private tableConfig: RWTableConfig;
  private subTableConfig: RWTableConfig;


  private context: { data: any, action: any } = undefined;
  private validations: RWValidations;

  private isReadOnly = false;
  private isDisabled = false;
  private checkboxValue: string = '';
  private voiceText: string = '';
  private payLoadKey: string = '';
  private anchorType: string = 'Internal';
  private isLoading: boolean = false;

  private minDate: string = '';

  private maxLength: string;
  private minLength: string;
  
  private fileType: string = 'Single';








  constructor() {
    this.setNewId();
  }

  setToolboxIcon(icon: string): void {
    this.toolboxIcon = icon;
  }

  getToolboxIcon(): string {
    return this.toolboxIcon;
  }

  setExtraInfo(data: any): void {
    this.extraInfo = data;
  }

  getExtraInfo(): any {
    return this.extraInfo;
  }

  setToolboxName(toolboxName: string): void {
    this.toolboxName = toolboxName;
  }

  getToolboxName(): string {
    return this.toolboxName !== undefined ? this.toolboxName! : this.name;
  }

  setKeywords(keywords: string[]): void {
    for (const keyword of keywords) {
      this.addKeyword(keyword);
    }
  }

  getKeywords(): string[] {
    return this.keywords;
  }

  addKeyword(keyword: string) {
    this.keywords.push(keyword);
  }

  removeKeyword(keyword: string) {
    const index = this.keywords.indexOf(keyword);
    if (index > -1) {
      this.keywords.splice(index, 1);
    }
  }

  setCategories(categories: string[]) {
    for (const category of categories) {
      this.addKeyword(category);
    }
  }

  addCategory(category: string) {
    this.categories.push(category);
  }

  getCategories() {
    return this.categories;
  }

  removeCategory(category: string) {
    const index = this.categories.indexOf(category);
    if (index > -1) {
      this.categories.splice(index, 1);
    }
  }

  setNewId() {
    this.id = 'rw-le-' + Math.floor((Math.random() * 99999) + 1);
    this.attributes['id'] = this.id;
  }

  setName(data: ElementPropertyFieldEvent) {
    if (data.event !== undefined) {
      this.name = data.event.target.value;
      this.attributes['name'] = data.event.target.value;
    }
  }

  setNameD(name: string) {
    this.name = name;
    this.attributes['name'] = name;
  }

  getName() {
    return this.name;
  }

  setTitle(data: ElementPropertyFieldEvent) {
    if (data.event !== undefined) {
      this.title = data.event.target.value;
      this.attributes['title'] = data.event.target.value;
    }
  }

  setTitleD(title: string) {
    this.title = title;
    this.attributes['title'] = title;
  }

  getTitle(): string {
    return this.title;
  }

  setLevel(level: number) {
    this.level = level;
  }

  getLevel(): number {
    return this.level;
  }

  getChecked(): boolean {
    return this.checked;
  }

  setChecked(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.checked = data.event.target.value;
      this.attributes['checked'] = data.event.target.value;
    }
  }

  setCheckedD(checked: boolean) {
    this.checked = checked;
    this.attributes['checked'] = checked;
  }

  getCheckboxValue(): string {
    return this.checkboxValue;
  }

  setCheckboxValue(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.checkboxValue = data.event.target.value;
    }
  }

  setCheckboxValueD(checkboxValue: string) {
    this.checkboxValue = checkboxValue;
  }

  setElementType(type: RWElementType) {
    if (this.elementType !== RWElementType.CONTROL) {
      this.setControlType(RWElementControlType.UNDEFINED)
    }
    this.elementType = type;
  }

  getElementType(): RWElementType {
    return this.elementType;
  }

  getControlType(): RWElementControlType {
    return this.controlType;
  }

  getValue(): string {
    return this.value;
  }

  setValue(val: string) {
    // setTimeout(() => {
    this.value = val;
    // }, 0)
  }

  setControlType(type: RWElementControlType) {
    this.controlType = type;
  }

  getControlOptions(): {
    [key: string]: any;
  } {
    return this.controlOptions;
  }

  setControlOptions(controlOptions: {
    [key: string]: any;
  }): void {
    this.controlOptions = controlOptions;
  }

  getContentText(): string {
    return this.contentText;
  }

  setContentText(data: ElementPropertyFieldEvent): void {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.contentText = data.event.target.value;
    }
  }

  getVoiceText(): string {
    return this.voiceText
  }

  setVoiceText(data: ElementPropertyFieldEvent): void {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.voiceText = data.event.target.value.toLowerCase();;
    }
  }

  setVoiceTextD(voiceText: string) {
    this.voiceText = voiceText;
  }

  setRouter(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.router = data.event.target.value;
    }
  }

  setRouterD(router: string) {
    this.router = router;
  }

  getRouter(): string {
    return this.router;
  }

  getVerticalAccordionHeaderText(): string {
    return this.verticalAccordionHeaderText;
  }

  setVerticalAccordionHeaderText(data: ElementPropertyFieldEvent): void {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.verticalAccordionHeaderText = data.event.target.value;
    }
  }

  setVerticalAccordionHeaderTextD(verticalAccordionHeaderText: string): void {
    this.verticalAccordionHeaderText = verticalAccordionHeaderText;
  }


  setContentTextD(contentText: string) {
    this.contentText = contentText;
  }

  setClasses(data: ElementPropertyFieldEvent): void {
    if (data == undefined) {
      return;
    }
    let classes = [];
    if (data.event !== undefined) {
      classes = data.event.target.value.split(' ');
      for (const cls of classes) {
        this.addClass(cls);
      }
    } else {
      this.removeAllClasses();
    }
  }

  setClassesD(classes: string[] | undefined | null) {
    if (classes !== undefined && classes !== null) {
      for (const cls of classes) {
        this.addClass(cls);
      }
    }
  }

  addClass(className: string): void {
    if (this.classes.indexOf(className) === -1) {
      this.classes.push(className);
    }
    this.attributes['class'] = this.classes.join(' ');
  }

  removeClass(className: string): void {
    if (this.classes.indexOf(className) > -1) {
      this.classes.splice(this.classes.indexOf(className), 1);
    }
    this.attributes['class'] = this.classes.join(' ');
  }

  toggleClass(className: string): void {
    if (this.classes.indexOf(className) > -1) {
      this.removeClass(className);
    } else {
      this.addClass(className);
    }
  }

  getClasses() {
    return this.classes;
  }

  getClassesStr() {
    return this.classes.join(' ');
  }

  setClassesStr(data: ElementPropertyFieldEvent) {
    this.removeAllClasses();
    this.setClasses(data);
  }

  getRole() {
    if (this.role === undefined) {
      return '';
    }
    return this.role;
  }

  setRole(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }

    if (data.event !== undefined) {
      this.role = data.event.target.value;
    }
  }

  setRoleD(role: string) {
    this.role = role;
    if (this.attributes === undefined) {
      this.attributes = {};
    }
    this.attributes['role'] = role;
  }

  getChildernDataProperty() {
    if (this.childrenDataProperty === undefined) {
      return '';
    }
    return this.childrenDataProperty;
  }

  setChildernDataProperty(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }

    if (data.event !== undefined) {
      this.childrenDataProperty = data.event.target.value;
    }
  }

  setChildrenDataPropertyD(childrenDataProperty: string) {
    this.childrenDataProperty = childrenDataProperty;
    this.attributes['childrenDataProperty'] = childrenDataProperty;
  }

  removeClasses(classes: string[]) {
    for (const cls of classes) {
      this.removeClass(cls);
    }
  }

  removeAllClasses() {
    for (const cls of this.classes) {
      this.removeClass(cls);
    }
  }

  replaceClass(newClassName: string, oldClassName: string): void {
    this.removeClass(oldClassName);
    this.addClass(newClassName);
  }

  setId(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.id = data.event.target.value;
      this.attributes['id'] = data.event.target.value;
    }
  }

  setIdD(id: string) {
    this.id = id;
    this.attributes['id'] = id;
  }

  getId() {
    return this.id;
  }

  getPlaceholder(): string {
    return this.placeholder;
  }

  setPlaceholder(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.placeholder = data.event.target.value;
      this.attributes['placeholder'] = data.event.target.value;
    }
  }

  setPlaceholderD(placeholder: string) {
    this.placeholder = placeholder;
    this.attributes['placeholder'] = placeholder;
  }


  getRadioValue(): string {
    return this.radioValue;
  }

  setRadioValue(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.radioValue = data.event.target.value;
    }
  }

  setRadioValueD(radioValue: string) {
    this.radioValue = radioValue;
  }

  setType(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.type = data.event.target.value;
    }
  }

  setTypeD(type: string) {
    this.type = type;
  }

  getType(): string {
    return this.type;
  }

  setMin(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.min = data.event.target.value;
      this.attributes['min'] = data.event.target.value;
    }
  }

  setMinD(min: string) {
    this.min = min;
    this.attributes['min'] = min;
  }

  getMin(): string {
    return this.min;
  }

  setMax(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.max = data.event.target.value;
      this.attributes['max'] = data.event.target.value;
    }
  }

  setMaxD(max: number) {
    this.max = max;
    this.attributes['max'] = max;
  }

  getMax(): number {
    return this.max;
  }

  setFormat(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.format = data.event.target.value;
    }
  }

  setFormatD(format: string) {
    this.format = format;
  }

  getFormat(): string {
    return this.format;
  }

  setOrder(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.order = data.event.target.value;
    }
  }

  setOrderD(order: number) {
    this.order = order;
  }

  getOrder(): number {
    if (this.order === undefined) {
      this.order = 0;
    }
    return this.order;
  }

  setOptions(options: RWElementSelectOption[]): void {
    for (const option of options) {
      this.addOption(option);
    }
  }

  getOptionsLength(): number {
    return this.options.length;
  }

  removeAllOptions(): void {
    this.options.splice(0, this.options.length);
  }

  getOption(index: number): RWElementSelectOption {
    return this.options[index];
  }

  setOption(index: number, value: RWElementSelectOption): void {
    this.options[index] = value;
  }

  getOptionDisplayText(index: number): string {
    if (this.options[index] === undefined || this.options[index] === null) {
      return '';
    }
    return this.options[index].displayText;
  }

  setOptionDisplayText(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.options[data.index] !== undefined && this.options[data.index] !== null) {
        this.options[data.index].displayText = data.event.target.value;
      }
    }
  }

  getOptionValue(index: number): string {
    if (this.options[index] === undefined || this.options[index] === null) {
      return '';
    }
    return this.options[index].value;
  }

  setOptionValue(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.options[data.index] !== undefined && this.options[data.index] !== null) {
        this.options[data.index].value = data.event.target.value;
      }
    }
  }



  setDropDownTypeD(dropDownType: string) {
    this.dropDownType = dropDownType;
  }

  getDropDownType(): string {
    return this.dropDownType;
  }


  setDropDownType(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.dropDownType = data.event.target.value;
    }
  }

  getTableHeaders(): RWElementTableHeader[] {
    return this.tableHeaders;
  }

  getTableHeadersLength(): number {
    return this.tableHeaders.length;
  }

  getTableHeaderTitle(index: number): string {
    if (this.tableHeaders[index] === undefined || this.tableHeaders[index] === null) {
      return '';
    }
    return this.tableHeaders[index].title;
  }

  setTableHeaderTitle(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.tableHeaders[data.index] !== undefined && this.tableHeaders[data.index] !== null) {
        this.tableHeaders[data.index].title = data.event.target.value;
      }
    }
  }
  getTableHeaderDataProperty(index: number): string {
    if (this.tableHeaders[index] === undefined || this.tableHeaders[index] === null) {
      return '';
    }
    return this.tableHeaders[index].dataProperty;
  }

  setColumnOrder(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.tableHeaders[data.index] !== undefined && this.tableHeaders[data.index] !== null) {
        this.tableHeaders[data.index].columnOrder = data.event.target.value;
      }
    }
  }
  getColumnOrder(index: number): number {
    if (this.tableHeaders[index] === undefined || this.tableHeaders[index] === null) {
      return 0;
    }
    return this.tableHeaders[index].columnOrder;
  }

  setTableHeaderDataProperty(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.tableHeaders[data.index] !== undefined && this.tableHeaders[data.index] !== null) {
        this.tableHeaders[data.index].dataProperty = data.event.target.value;
      }
    }
  }
  getTableHeaderStyle(index: number): string {
    if (this.tableHeaders[index] === undefined || this.tableHeaders[index] === null) {
      return '';
    }
    return this.tableHeaders[index].style;
  }

  setTableHeaderStyle(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.tableHeaders[data.index] !== undefined && this.tableHeaders[data.index] !== null) {
        this.tableHeaders[data.index].style = data.event.target.value;
      }
    }
  }

  getTableHeaderShow(index: number): string {
    if (this.tableHeaders[index] === undefined || this.tableHeaders[index] === null) {
      return;
    }
    return this.tableHeaders[index].show ? 'show' : 'hide';
  }

  setTableHeaderShow(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.tableHeaders[data.index] !== undefined && this.tableHeaders[data.index] !== null) {
        this.tableHeaders[data.index].show = data.event.target.value === 'show';
      }
    }
  }

  getTableClickableField(index: number): string {
    if (this.tableHeaders[index] === undefined || this.tableHeaders[index] === null) {
      return '';
    }
    if (this.tableHeaders[index].clickableAction === undefined || this.tableHeaders[index].clickableAction === null) {
      return '';
    }
    return JSON.stringify(this.tableHeaders[index].clickableAction, null, 2);
  }

  setTableClickableField(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.tableHeaders[data.index] !== undefined && this.tableHeaders[data.index] !== null) {
        let rawData = data.event.target.value;
        if (Object.keys(rawData).length === 0) {
          rawData = '{}';
        }
        const jsonAction = JSON.parse(rawData);
        this.tableHeaders[data.index].clickableAction = RWElementParser.jsonToRWAction(jsonAction);
      }
    }
  }

  getTableHeaderDataType(index: number): string {
    if (this.tableHeaders[index] === undefined || this.tableHeaders[index] === null) {
      return;
    }
    return this.tableHeaders[index].dataType;
  }

  setTableHeaderDataType(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.tableHeaders[data.index] !== undefined && this.tableHeaders[data.index] !== null) {
        this.tableHeaders[data.index].dataType = data.event.target.value;
      }
    }
  }

  getTableHeaderActionsRaw(index: number): RWElementTableHeaderAction[] {
    if (this.tableHeaders[index] === undefined || this.tableHeaders[index] === null) {
      return [];
    }
    if (this.tableHeaders[index].actions === undefined || this.tableHeaders[index].actions === null) {
      return [];
    }
    return this.tableHeaders[index].actions;
  }

  getTableHeaderActions(index: number): string {
    if (this.tableHeaders[index] === undefined || this.tableHeaders[index] === null) {
      return '';
    }
    if (this.tableHeaders[index].actions === undefined || this.tableHeaders[index].actions === null) {
      return '';
    }
    return JSON.stringify(this.tableHeaders[index].actions, null, 2);
  }

  setTableHeaderActions(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.tableHeaders[data.index] !== undefined && this.tableHeaders[data.index] !== null) {
        this.tableHeaders[data.index].actions = [];
        const rawData = data.event.target.value;
        const jsonActions = JSON.parse(rawData);
        if (Array.isArray(jsonActions)) {
          for (const action of jsonActions) {
            this.tableHeaders[data.index].actions.push(RWElementParser.jsonToRWTableHeaderAction(action));
          }
        }
      }
    }
  }

  getSubTableHeaderActions(index: number): string {
    if (this.subTableHeaders[index] === undefined || this.subTableHeaders[index] === null) {
      return '';
    }
    if (this.subTableHeaders[index].actions === undefined || this.subTableHeaders[index].actions === null) {
      return '';
    }
    return JSON.stringify(this.subTableHeaders[index].actions, null, 2);
  }

  setSubTableHeaderActions(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.subTableHeaders[data.index] !== undefined && this.subTableHeaders[data.index] !== null) {
        this.subTableHeaders[data.index].actions = [];
        const rawData = data.event.target.value;
        const jsonActions = JSON.parse(rawData);
        if (Array.isArray(jsonActions)) {
          for (const action of jsonActions) {
            this.subTableHeaders[data.index].actions.push(RWElementParser.jsonToRWSubTableHeaderAction(action));
          }
        }
      }
    }
  }

  setTableHeaderRole(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.tableHeaders[data.index] !== undefined && this.tableHeaders[data.index] !== null) {
        this.tableHeaders[data.index].role = data.event.target.value;
      }
    }
  }

  getTableHeaderRole(index: number): string {
    if (this.tableHeaders[index] === undefined || this.tableHeaders[index] === null) {
      return '';
    }
    return this.tableHeaders[index].role;
  }

  getSubTableHeaders(): RWElementTableHeader[] {
    return this.subTableHeaders;
  }

  getSubTableHeadersLength(): number {
    return this.subTableHeaders.length;
  }

  addEmptySubTableHeader() {
    this.subTableHeaders.push(new RWElementTableHeader('', '', '', true, '', 'TEXT', 0));
  }

  setSubTableHeaderTitle(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.subTableHeaders[data.index] !== undefined && this.subTableHeaders[data.index] !== null) {
        this.subTableHeaders[data.index].title = data.event.target.value;
      }
    }
  }

  getSubTableHeaderTitle(index: number): string {
    if (this.subTableHeaders[index] === undefined || this.subTableHeaders[index] === null) {
      return '';
    }
    return this.subTableHeaders[index].title;
  }

  setSubTableHeaderDataProperty(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.subTableHeaders[data.index] !== undefined && this.subTableHeaders[data.index] !== null) {
        this.subTableHeaders[data.index].dataProperty = data.event.target.value;
      }
    }
  }

  getSubTableHeaderDataProperty(index: number): string {
    if (this.subTableHeaders[index] === undefined || this.subTableHeaders[index] === null) {
      return '';
    }
    return this.subTableHeaders[index].dataProperty;
  }

  setsubTableHeaderstyle(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.subTableHeaders[data.index] !== undefined && this.subTableHeaders[data.index] !== null) {
        this.subTableHeaders[data.index].style = data.event.target.value;
      }
    }
  }

  getSubTableHeaderstyle(index: number): string {
    if (this.subTableHeaders[index] === undefined || this.subTableHeaders[index] === null) {
      return '';
    }
    return this.subTableHeaders[index].style;
  }

  setSubTableHeadershow(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.subTableHeaders[data.index] !== undefined && this.subTableHeaders[data.index] !== null) {
        this.subTableHeaders[data.index].show = data.event.target.value === 'show';
      }
    }
  }

  getSubTableHeadershow(index: number): string {
    if (this.subTableHeaders[index] === undefined || this.subTableHeaders[index] === null) {
      return;
    }
    return this.subTableHeaders[index].show ? 'show' : 'hide';
  }

  setSubTableClickableField(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.subTableHeaders[data.index] !== undefined && this.subTableHeaders[data.index] !== null) {
        let rawData = data.event.target.value;
        if (Object.keys(rawData).length === 0) {
          rawData = '{}';
        }
        const jsonAction = JSON.parse(rawData);
        this.subTableHeaders[data.index].subClickableAction = RWElementParser.jsonToRWAction(jsonAction);
      }
    }
  }

  getSubTableClickableField(index: number): string {
    if (this.subTableHeaders[index] === undefined || this.subTableHeaders[index] === null) {
      return '';
    }
    if (this.subTableHeaders[index].subClickableAction === undefined || this.subTableHeaders[index].subClickableAction === null) {
      return '';
    }
    return JSON.stringify(this.subTableHeaders[index].subClickableAction, null, 2);
  }

  setTableSubHeaderRole(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.subTableHeaders[data.index] !== undefined && this.subTableHeaders[data.index] !== null) {
        this.subTableHeaders[data.index].role = data.event.target.value;
      }
    }
  }

  getSubTableHeaderRole(index: number): string {
    if (this.subTableHeaders[index] === undefined || this.subTableHeaders[index] === null) {
      return '';
    }
    return this.subTableHeaders[index].role;
  }

  getSubTableHeaderDataType(index: number): string {
    if (this.subTableHeaders[index] === undefined || this.subTableHeaders[index] === null) {
      return;
    }
    return this.subTableHeaders[index].dataType;
  }

  setSubTableHeaderDataType(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.subTableHeaders[data.index] !== undefined && this.tableHeaders[data.index] !== null) {
        this.subTableHeaders[data.index].dataType = data.event.target.value;
      }
    }
  }

  setSubTableColumnOrder(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.subTableHeaders[data.index] !== undefined && this.subTableHeaders[data.index] !== null) {
        this.subTableHeaders[data.index].columnOrder = data.event.target.value;
      }
    }
  }

  getSubTableColumnOrder(index: number): number {
    if (this.subTableHeaders[index] === undefined || this.subTableHeaders[index] === null) {
      return 0;
    }
    return this.subTableHeaders[index].columnOrder;
  }

  removeSelectedSubTableHeader(valIndex: number) {
    this.subTableHeaders.splice(valIndex, 1);
  }

  addHeader(tableHeader: RWElementTableHeader) {
    this.tableHeaders.push(tableHeader);
  }

  addSubHeader(SubtableHeader: RWElementTableHeader) {
    this.subTableHeaders.push(SubtableHeader);
  }

  addEmptyTableHeader() {
    this.tableHeaders.push(new RWElementTableHeader('', '', '', true, '', 'TEXT', 0));
  }

  removeSelectedTableHeader(valIndex: number) {
    this.tableHeaders.splice(valIndex, 1);
  }


  addEmptyOption() {
    this.options.push({ displayText: '', value: '' });
  }

  removeSelectedOption(valIndex: number) {
    this.options.splice(valIndex, 1);
  }

  getOptions(): RWElementSelectOption[] {
    return this.options;
  }

  addOption(option: RWElementSelectOption) {
    this.options.push(option);
  }

  removeOption(option: RWElementSelectOption) {
    const index = this.options.findIndex(v => v.value === option.value);
    if (index > -1) {
      this.options.splice(index, 1);
    }
  }

  setSource(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.src = data.event.target.value;
      this.attributes['src'] = data.event.target.value;
    }
  }

  setSourceD(src: string) {
    this.src = src;
    this.attributes['src'] = src;
  }

  getSource() {
    return this.src;
  }

  setHeight(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.height = data.event.target.value;
      this.attributes['height'] = data.event.target.value;
    }
  }

  setHeightD(height: number) {
    this.height = height;
    this.attributes['height'] = height;
  }

  getHeight() {
    return this.height
  }

  setWidth(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.width = data.event.target.value;
      this.attributes['width'] = data.event.target.value;
    }
  }

  setWidthD(width: number) {
    this.width = width;
    this.attributes['width'] = width;
  }

  getWidth() {
    return this.width;
  }

  setHref(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.href = data.event.target.value;
      this.attributes['href'] = data.event.target.value;
    }
  }

  setHrefD(href: string) {
    this.href = href;
    this.attributes['href'] = href;
  }

  getHref(): string {
    return this.href;
  }

  setAttribute(key: string, value: string): void {
    if (key === 'class') {
      console.warn('Use addClass | removeClass | replaceClass methods instead of setting class attribute directly');
      return;
    }
    if (key === 'id') {
      console.warn('Use setId method instead of setting id attribute directly');
      return;
    }
    this.attributes[key] = value;
  }

  setAttributes(attributes: {
    [key: string]: any;
  }) {
    this.attributes = attributes;
  }

  getAttribute(key: string): string {
    return this.attributes[key];
  }

  getAttributes() {
    return this.attributes;
  }

  removeChild(element: RWElement) {
    const index = this.children.findIndex(v => v.id === element.id);
    if (index === -1) {
      return;
    }
    this.children.splice(index, 1);
  }

  addChild(element: RWElement) {
    this.children.push(element);
  }

  getChildren(): RWElement[] {
    return this.children.sort((a: RWElement, b: RWElement) => {
      return a.getOrder() - b.getOrder();
    });
  }

  getChildrenRaw(): RWElement[] {
    return this.children;
  }

  setChildren(index: number, data: RWElement) {
    this.children[index] = data;
  }

  getEventsLength(): number {
    return this.events.length;
  }

  addEvent(event: RWEvent) {
    this.events.push(event);
  }

  addEmptyEvent() {
    this.events.push(new RWEvent(RWEventType.UNDEFINED));
  }

  removeSelectedEvent(valIndex: number) {
    this.events.splice(valIndex, 1);
  }

  getEvents(): RWEvent[] {
    return this.events;
  }

  getEventType(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    return this.events[index].type;
  }

  setEventType(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        this.events[data.index].type = data.event.target.value;
      }
    }
  }


  getApiMethod(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'method');
    if (property == undefined || property === null) {
      return ''
    }
    return property.value;
  }

  setApiMethod(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'method');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('method', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('method', 'string', data.event.target.value);
          }
        }
      }
    }

  }

  getEventActionType(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    return this.events[index].action.type;
  }

  setEventActionType(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action === undefined || this.events[data.index].action === null) {
          this.events[data.index].action = new RWAction(data.event.target.value);
        }
        this.events[data.index].action.type = data.event.target.value;
      }
    }
  }

  setAlertMessage(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          if (this.events[data.index].action.properties.length === 0) {
            this.events[data.index].action.properties.push(new RWActionProperty('message', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[0] = new RWActionProperty('message', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getAlertMessage(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    if (this.events[index].action.properties[0] === undefined || this.events[index].action.properties[0] === null) {
      return '';
    }
    return this.events[index].action.properties[0].value;
  }



  setNavigateUrl(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          if (this.events[data.index].action.properties.length === 0) {
            this.events[data.index].action.properties.push(new RWActionProperty('navigateUrl', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[0] = new RWActionProperty('navigateUrl', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getNavigateUrl(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    if (this.events[index].action.properties[0] === undefined || this.events[index].action.properties[0] === null) {
      return '';
    }
    return this.events[index].action.properties[0].value;
  }

  setOpenTab(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          if (this.events[data.index].action.properties.length === 0) {
            this.events[data.index].action.properties.push(new RWActionProperty('navigateUrl', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[0] = new RWActionProperty('navigateUrl', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getOpenTab(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    if (this.events[index].action.properties[0] === undefined || this.events[index].action.properties[0] === null) {
      return '';
    }
    return this.events[index].action.properties[0].value;
  }


  setAPIUrl(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'url');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('url', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('url', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  setShowPopup(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'url');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('showPopup', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('showPopup', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getShowPopup(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'showPopup');
    if (property === undefined || property === null) {
      return ''
    }
    return property.value;
  }

  setPopupSize(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          if (this.events[data.index].action.properties.length === 0) {
            this.events[data.index].action.properties.push(new RWActionProperty('popupSize', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[0] = new RWActionProperty('popupSize', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getPopupSize(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'popupSize');
    if (property === undefined || property === null) {
      return '';
    }
    return property.value;
  }

  getAPIUrl(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    if (this.events[index].action.properties[0] === undefined || this.events[index].action.properties[0] === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'url');
    if (property == undefined || property === null) {
      return ''
    }
    return property.value;
  }

  // setStoreDataSourceName(data: ElementPropertyFieldEvent) {
  //   if (data == undefined) {
  //     return;
  //   }
  //   if (data.event !== undefined) {
  //     if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
  //       if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
  //         if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
  //           this.events[data.index].action.properties = [];
  //         }
  //         if (this.events[data.index].action.properties.length === 0) {
  //           this.events[data.index].action.properties.push(new RWActionProperty('dataSource', 'string', data.event.target.value));
  //         } else {
  //           this.events[data.index].action.properties[0] = new RWActionProperty('dataSource', 'string', data.event.target.value);
  //         }
  //       }
  //     }
  //   }
  // }

  // getStoreDataSourceName(index: number): string {
  //   if (this.events[index] === undefined || this.events[index] === null) {
  //     return '';
  //   }
  //   if (this.events[index].action === undefined || this.events[index].action === null) {
  //     return '';
  //   }
  //   if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
  //     return '';
  //   }
  //   if (this.events[index].action.properties[0] === undefined || this.events[index].action.properties[0] === null) {
  //     return '';
  //   }
  //   return this.events[index].action.properties[0].value;
  // }

  // setOtherDataSourceName(data: ElementPropertyFieldEvent) {
  //   if (data == undefined) {
  //     return;
  //   }
  //   if (data.event !== undefined) {
  //     if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
  //       if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
  //         if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
  //           this.events[data.index].action.properties = [];
  //         }
  //         if (this.events[data.index].action.properties.length === 0) {
  //           this.events[data.index].action.properties.push(new RWActionProperty('dataSource', 'string', data.event.target.value));
  //         } else {
  //           this.events[data.index].action.properties[0] = new RWActionProperty('dataSource', 'string', data.event.target.value);
  //         }
  //       }
  //     }
  //   }
  // }

  // getOtherDataSourceName(index: number): string {
  //   if (this.events[index] === undefined || this.events[index] === null) {
  //     return '';
  //   }
  //   if (this.events[index].action === undefined || this.events[index].action === null) {
  //     return '';
  //   }
  //   if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
  //     return '';
  //   }
  //   if (this.events[index].action.properties[0] === undefined || this.events[index].action.properties[0] === null) {
  //     return '';
  //   }
  //   return this.events[index].action.properties[0].value;
  // }


  setStoreDataSourceName(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'dataSource');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('dataSource', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('dataSource', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getStoreDataSourceName(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'dataSource');
    if (property == undefined || property === null) {
      return '';
    }
    return property.value;
  }

  setOtherDataSourceName(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'dataSource');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('dataSource', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('dataSource', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getOtherDataSourceName(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'dataSource');
    if (property == undefined || property === null) {
      return '';
    }
    return property.value;
  }


  getOtherDataSourceNameForOnLoadWithoutIndex(): string {
    const event = this.events.find(v => v.type === RWEventType.ON_LOAD);
    if (event === undefined) {
      return '';
    }
    if (event.action.type !== RWActionType.LOAD_OTHER_DATASOURCE) {
      return '';
    }
    return event.action.properties[0].value;
  }


  setValueDataSource(dataSource: RWElementBaseDataSource) {
    this.valueDataSource = dataSource;
  }

  getFormContentType(): RequestContentType {
    return this.formContentType;
  }

  setFormContentType(data: ElementPropertyFieldEvent): void {
    this.formContentType = data.event.target.value;
  }

  setFormContentTypeD(type: RequestContentType): void {
    this.formContentType = type;
  }

  getValueDataSource(): RWElementBaseDataSource {
    return this.valueDataSource;
  }

  getValueDataSourceName(index: number): string {
    if (this.valueDataSource === undefined || this.valueDataSource === null) {
      return '';
    }
    return this.valueDataSource.dataSource;
  }

  setValueDataSourceName(data: ElementPropertyFieldEvent) {
    if (this.valueDataSource == undefined) {
      this.valueDataSource = new RWElementBaseDataSource();
    }
    this.valueDataSource.dataSource = data.event.target.value;
  }

  getValueDataSourceInherited(index: number): string {
    if (this.valueDataSource === undefined || this.valueDataSource === null) {
      return 'No';
    }
    return this.valueDataSource.inherited ? 'Yes' : 'No';
  }

  setValueDataSourceInherited(data: ElementPropertyFieldEvent) {
    if (this.valueDataSource == undefined) {
      this.valueDataSource = new RWElementBaseDataSource();
    }
    this.valueDataSource.inherited = data.event.target.value === 'Yes' ? true : false;
  }

  getValueDataSourceShouldUseResult(index: number): string {
    if (this.valueDataSource === undefined || this.valueDataSource === null) {
      return 'No';
    }
    return this.valueDataSource.shouldUseDataSourceResult ? 'Yes' : 'No';
  }

  setValueDataSourceShouldUseResult(data: ElementPropertyFieldEvent) {
    if (this.valueDataSource == undefined) {
      this.valueDataSource = new RWElementBaseDataSource();
    }
    this.valueDataSource.shouldUseDataSourceResult = data.event.target.value === 'Yes' ? true : false;
  }

  getValueDataSourceProperty(index: number): string {
    if (this.valueDataSource === undefined || this.valueDataSource === null) {
      return '';
    }
    return this.valueDataSource.property;
  }

  setValueDataSourceProperty(data: ElementPropertyFieldEvent) {
    if (this.valueDataSource == undefined) {
      this.valueDataSource = new RWElementBaseDataSource();
    }
    this.valueDataSource.property = data.event.target.value;
  }

  setOptionsDataSource(dataSource: RWElementOptionsDataSource) {
    this.optionsDataSource = dataSource;
  }

  getOptionsDataSource() {
    return this.optionsDataSource;
  }

  getOptionsDataSourceName(index: number): string {
    if (this.optionsDataSource === undefined || this.optionsDataSource === null) {
      return '';
    }
    return this.optionsDataSource.dataSource;
  }

  setOptionsDataSourceName(data: ElementPropertyFieldEvent) {
    if (this.optionsDataSource == undefined) {
      this.optionsDataSource = new RWElementOptionsDataSource();
    }
    this.optionsDataSource.dataSource = data.event.target.value;
  }

  getOptionsDataSourceInherited(index: number): string {
    if (this.optionsDataSource === undefined || this.optionsDataSource === null) {
      return 'No';
    }
    return this.optionsDataSource.inherited ? 'Yes' : 'No';
  }

  setOptionsDataSourceInherited(data: ElementPropertyFieldEvent) {
    if (this.optionsDataSource == undefined) {
      this.optionsDataSource = new RWElementOptionsDataSource();
    }
    this.optionsDataSource.inherited = data.event.target.value === 'Yes' ? true : false;
  }

  getOptionsDataSourceProperty(index: number): string {
    if (this.optionsDataSource === undefined || this.optionsDataSource === null) {
      return '';
    }
    return this.optionsDataSource.property;
  }

  setOptionsDataSourceProperty(data: ElementPropertyFieldEvent) {
    if (this.optionsDataSource == undefined) {
      this.optionsDataSource = new RWElementOptionsDataSource();
    }
    this.optionsDataSource.property = data.event.target.value;
  }

  getOptionsDataSourceKeyProperty(index: number): string {
    if (this.optionsDataSource === undefined || this.optionsDataSource === null) {
      return '';
    }
    return this.optionsDataSource.keyProperty;
  }

  setOptionsDataSourceKeyProperty(data: ElementPropertyFieldEvent) {
    if (this.optionsDataSource == undefined) {
      this.optionsDataSource = new RWElementOptionsDataSource();
    }
    this.optionsDataSource.keyProperty = data.event.target.value;
  }

  getOptionsDataSourceValueProperty(index: number): string {
    if (this.optionsDataSource === undefined || this.optionsDataSource === null) {
      return '';
    }
    return this.optionsDataSource.valueProperty;
  }

  setOptionsDataSourceValueProperty(data: ElementPropertyFieldEvent) {
    if (this.optionsDataSource == undefined) {
      this.optionsDataSource = new RWElementOptionsDataSource();
    }
    this.optionsDataSource.valueProperty = data.event.target.value;
  }

  setPropertyActionType(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'propertyActionType');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('propertyActionType', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('propertyActionType', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getPropertyActionType(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'propertyActionType');
    if (property == undefined || property === null) {
      return ''
    }
    return property.value;
  }

  setOtherPropertyActionType(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }

          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'propertyActionType');

          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('propertyActionType', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('propertyActionType', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getOtherPropertyActionType(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'propertyActionType');
    if (property == undefined || property === null) {
      return ''
    }
    return property.value;
  }

  setOtherPropertyElementPath(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          if (this.events[data.index].action.properties.length === 0) {
            this.events[data.index].action.properties.push(new RWActionProperty('elementPath', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[0] = new RWActionProperty('elementPath', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getOtherPropertyElementPath(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    if (this.events[index].action.properties[0] === undefined || this.events[index].action.properties[0] === null) {
      return '';
    }
    return this.events[index].action.properties[0].value;
  }

  setPropertyActionValue(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'propertyActionValue');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('propertyActionValue', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('propertyActionValue', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getPropertyActionValue(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'propertyActionValue');
    if (property == undefined || property === null) {
      return ''
    }
    return property.value;
  }


  setConfirmMessage(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          if (this.events[data.index].action.properties.length === 0) {
            this.events[data.index].action.properties.push(new RWActionProperty('msg', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[0] = new RWActionProperty('msg', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getConfirmMessage(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    if (this.events[index].action.properties[0] === undefined || this.events[index].action.properties[0] === null) {
      return '';
    }
    return JSON.stringify(this.events[index].action.properties[0].value, null, 2);
  }

  getEnableDynamicallyDuplicate(): any {
    return this.enableDynamicallyDuplicate ? true : '';
  }

  setEnableDynamicallyDuplicateD(data: boolean): void {
    this.enableDynamicallyDuplicate = data;
  }

  setEnableDynamicallyDuplicate(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.enableDynamicallyDuplicate = data.event.target.value === 'true' ? true : false;
    }
  }

  getEnableDynamicallyRemove(): any {
    return this.enableDynamicallyRemove ? true : '';
  }

  setEnableDynamicallyRemoveD(data: boolean): void {
    this.enableDynamicallyRemove = data;
  }

  setEnableDynamicallyRemove(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.enableDynamicallyRemove = data.event.target.value === 'true' ? true : false;
    }
  }
  setFontSize(data: ElementPropertyFieldEvent) {
    if (data.event !== undefined) {
      this.fontSize = data.event.target.value;
      if (this.inlineStyles === undefined || this.inlineStyles === null) {
        this.inlineStyles = {};
      }
      this.inlineStyles['font-size'] = data.event.target.value;
    }
  }

  getFontSize() {
    if (this.inlineStyles === undefined || this.inlineStyles === null) {
      return '';
    }
    if (this.inlineStyles['font-size'] === undefined || this.inlineStyles['font-size'] === null) {
      return '';
    }
    return this.inlineStyles['font-size'];
  }

  setFontSizeD(fontSize: string) {
    if (this.inlineStyles === undefined || this.inlineStyles === null) {
      this.inlineStyles = {};
    }
    this.inlineStyles['font-size'] = fontSize;
  }

  setInlineStyles(data: ElementPropertyFieldEvent) {
    if (data.event !== undefined) {
      this.fontSize = data.event.target.value;
      try {
        this.inlineStyles = JSON.parse(data.event.target.value);
      } catch (e) {
        console.warn('Invalid CSS JSON Object');
      }
    }
  }

  setInlineStylesD(styles: any) {
    if (this.inlineStyles === undefined || this.inlineStyles === null) {
      return;
    }
    try {
      this.inlineStyles = styles;
    } catch (e) {
      console.warn('Invalid CSS JSON Object from Parser');
    }
  }

  getInlineStyles() {
    if (this.inlineStyles === undefined || this.inlineStyles === null) {
      return '';
    }
    return JSON.stringify(this.inlineStyles, null, 2);
  }


  setLoadSelfDataSourceResponseProperty(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'loadSelfDataSourceResponseProperty');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('loadSelfDataSourceResponseProperty', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('loadSelfDataSourceResponseProperty', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getLoadSelfDataSourceResponseProperty(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'loadSelfDataSourceResponseProperty');
    if (property == undefined || property === null) {
      return '';
    }
    return property.value;
  }

  setLoadOtherDataSourceResponseProperty(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'loadOtherDataSourceResponseProperty');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('loadOtherDataSourceResponseProperty', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('loadOtherDataSourceResponseProperty', 'string', data.event.target.value);
          }
        }
      }
    }
  }

  getLoadOtherDataSourceResponseProperty(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'loadOtherDataSourceResponseProperty');
    if (property == undefined || property === null) {
      return '';
    }
    return property.value;
  }

  setLoadSelfDataSourceResponsePropertyActions(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'loadSelfDataSourceResponseProperty');
          let responseProperty = undefined;
          if (index > -1) {
            responseProperty = this.events[data.index].action.properties[index];
          }
          this.events[data.index].action.properties = [];
          if (responseProperty !== undefined && responseProperty !== null) {
            this.events[data.index].action.properties.push(responseProperty);
          }
          this.events[data.index].action.properties.push(...JSON.parse(data.event.target.value));
        }
      }
    }
  }

  getLoadSelfDataSourceResponsePropertyActions(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const allProps = JSON.parse(JSON.stringify(this.events[index].action.properties));

    const propIndex = this.events[index].action.properties.findIndex(v => v.key === 'loadSelfDataSourceResponseProperty');
    if (propIndex > -1) {
      allProps.splice(propIndex, 1);
    }
    return JSON.stringify(allProps, null, 2);
  }

  setLoadOtherDataSourceResponsePropertyActions(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'loadOtherDataSourceResponseProperty');
          let responseProperty = undefined;
          if (index > -1) {
            responseProperty = this.events[data.index].action.properties[index];
          }
          this.events[data.index].action.properties = [];
          if (responseProperty !== undefined && responseProperty !== null) {
            this.events[data.index].action.properties.push(responseProperty);
          }
          this.events[data.index].action.properties.push(...JSON.parse(data.event.target.value));
        }
      }
    }
  }

  getLoadOtherDataSourceResponsePropertyActions(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const allProps = JSON.parse(JSON.stringify(this.events[index].action.properties));

    const propIndex = this.events[index].action.properties.findIndex(v => v.key === 'loadOtherDataSourceResponseProperty');
    if (propIndex > -1) {
      allProps.splice(propIndex, 1);
    }
    return JSON.stringify(allProps, null, 2);
  }

  getTableConfig(): RWTableConfig {
    return this.tableConfig;
  }

  setTableConfig(tableConfig: RWTableConfig) {
    this.tableConfig = tableConfig;
  }
  setColumnVisibilityDropDown(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.columnVisibilityDropDown = data.event.target.value === 'Yes' ? true : false;
  }

  getColumnVisibilityDropDown(index: number): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return 'No';
    }
    return this.tableConfig.columnVisibilityDropDown ? 'Yes' : 'No';
  }

  setCommonSearch(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.commonSearch = data.event.target.value === 'Yes' ? true : false;
  }

  getCommonSearch(index: number): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return 'No';
    }
    return this.tableConfig.commonSearch ? 'Yes' : 'No';
  }

  setExcelExportButton(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.excelExportButton = data.event.target.value === 'Yes' ? true : false;
  }

  getExcelExportButton(index: number): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return 'No';
    }
    return this.tableConfig.excelExportButton ? 'Yes' : 'No';
  }

  setExcelExportFileName(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.excelExportFileName = data.event.target.value
  }

  getExcelExportFileName(): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return '';
    }
    return this.tableConfig.excelExportFileName;
  }

  setExcelExportButtonText(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.excelExportButtonText = data.event.target.value
  }

  getExcelExportButtonText(): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return '';
    }
    return this.tableConfig.excelExportButtonText;
  }

  setShowExpandArrows(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.showExpandArrows = data.event.target.value === 'Yes' ? true : false;
  }

  getShowExpandArrows(index: number): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return 'No';
    }
    return this.tableConfig.showExpandArrows ? 'Yes' : 'No';
  }

  setShowExpandAllArrows(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.showExpandAllArrows = data.event.target.value === 'Yes' ? true : false;
  }

  getShowExpandAllArrows(index: number): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return 'No';
    }
    return this.tableConfig.showExpandAllArrows ? 'Yes' : 'No';
  }


  setShowPageLengthDropdown(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.showPageLengthDropdown = data.event.target.value === 'Yes' ? true : false;
  }

  getShowPageLengthDropdown(index: number): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return 'No';
    }
    return this.tableConfig.showPageLengthDropdown ? 'Yes' : 'No';
  }

  setShowTableHeaders(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.showTableHeaders = data.event.target.value === 'Yes' ? true : false;
  }

  getShowTableHeaders(index: number): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return 'No';
    }
    return this.tableConfig.showTableHeaders ? 'Yes' : 'No';
  }



  setVisibleColumnFiltersVisibility(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.visibleColumnFiltersVisibility = data.event.target.value === 'Yes' ? true : false;
  }

  getVisibleColumnFiltersVisibility(index: number): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return 'No';
    }

    return this.tableConfig.visibleColumnFiltersVisibility ? 'Yes' : 'No';
  }



  setSubTableVisibleColumnFiltersVisibility(data: ElementPropertyFieldEvent) {
    if (this.tableConfig == undefined) {
      this.tableConfig = new RWTableConfig();
    }
    this.tableConfig.visibleColumnFiltersVisibility = data.event.target.value === 'Yes' ? true : false;
  }

  getSubTableVisibleColumnFiltersVisibility(index: number): string {
    if (this.tableConfig === undefined || this.tableConfig === null) {
      return 'No';
    }

    return this.tableConfig.visibleColumnFiltersVisibility ? 'Yes' : 'No';
  }


  getQueryParam(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'queryParam');
    if (property == undefined || property === null) {
      return ''
    }
    return JSON.stringify(property.value);
  }

  setQueryParam(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'queryParam');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }

  }

  getContext(): any {
    return this.context;
  }

  setContext(context: any): void {
    if (context === undefined) {
      return
    }
    this.context = context;
  }

  getSubTableConfig(): RWTableConfig {
    return this.subTableConfig;
  }
  setSubTableConfig(subTableConfig: RWTableConfig) {

    this.subTableConfig = subTableConfig;
  }
  setSubTableColumnVisibilityDropDown(data: ElementPropertyFieldEvent) {
    if (this.subTableConfig == undefined) {
      this.subTableConfig = new RWTableConfig();
    }
    this.subTableConfig.columnVisibilityDropDown = data.event.target.value === 'Yes' ? true : false;
  }

  getSubTableColumnVisibilityDropDown(index: number): string {
    if (this.subTableConfig === undefined || this.subTableConfig === null) {
      return 'No';
    }
    return this.subTableConfig.columnVisibilityDropDown ? 'Yes' : 'No';
  }

  setSubTableCommonSearch(data: ElementPropertyFieldEvent) {
    if (this.subTableConfig == undefined) {
      this.subTableConfig = new RWTableConfig();
    }
    this.subTableConfig.commonSearch = data.event.target.value === 'Yes' ? true : false;
  }

  getSubTableCommonSearch(index: number): string {
    if (this.subTableConfig === undefined || this.subTableConfig === null) {
      return 'No';
    }
    return this.subTableConfig.commonSearch ? 'Yes' : 'No';
  }

  setSubTableExcelExportButton(data: ElementPropertyFieldEvent) {
    if (this.subTableConfig == undefined) {
      this.subTableConfig = new RWTableConfig();
    }
    this.subTableConfig.excelExportButton = data.event.target.value === 'Yes' ? true : false;
  }

  getSubTableExcelExportButton(index: number): string {
    if (this.subTableConfig === undefined || this.subTableConfig === null) {
      return 'No';
    }
    return this.subTableConfig.excelExportButton ? 'Yes' : 'No';
  }

  setSubTableExcelExportFileName(data: ElementPropertyFieldEvent) {
    if (this.subTableConfig == undefined) {
      this.subTableConfig = new RWTableConfig();
    }
    this.subTableConfig.excelExportFileName = data.event.target.value
  }

  getSubTableExcelExportFileName(): string {
    if (this.subTableConfig === undefined || this.subTableConfig === null) {
      return '';
    }
    return this.subTableConfig.excelExportFileName;
  }

  setSubTableExcelExportButtonText(data: ElementPropertyFieldEvent) {
    if (this.subTableConfig == undefined) {
      this.subTableConfig = new RWTableConfig();
    }
    this.subTableConfig.excelExportButtonText = data.event.target.value
  }

  getSubTableExcelExportButtonText(): string {
    if (this.subTableConfig === undefined || this.subTableConfig === null) {
      return '';
    }
    return this.subTableConfig.excelExportButtonText;
  }

  setSubTableShowExpandArrows(data: ElementPropertyFieldEvent) {
    if (this.subTableConfig == undefined) {
      this.subTableConfig = new RWTableConfig();
    }
    this.subTableConfig.showExpandArrows = data.event.target.value === 'Yes' ? true : false;
  }

  getSubTableShowExpandArrows(index: number): string {
    if (this.subTableConfig === undefined || this.subTableConfig === null) {
      return 'No';
    }
    return this.subTableConfig.showExpandArrows ? 'Yes' : 'No';
  }

  setSubTableShowExpandAllArrows(data: ElementPropertyFieldEvent) {
    if (this.subTableConfig == undefined) {
      this.subTableConfig = new RWTableConfig();
    }
    this.subTableConfig.showExpandAllArrows = data.event.target.value === 'Yes' ? true : false;
  }

  getSubTableShowExpandAllArrows(index: number): string {
    if (this.subTableConfig === undefined || this.subTableConfig === null) {
      return 'No';
    }
    return this.subTableConfig.showExpandAllArrows ? 'Yes' : 'No';
  }


  setSubTableShowPageLengthDropdown(data: ElementPropertyFieldEvent) {
    if (this.subTableConfig == undefined) {
      this.subTableConfig = new RWTableConfig();
    }
    this.subTableConfig.showPageLengthDropdown = data.event.target.value === 'Yes' ? true : false;
  }

  getSubTableShowPageLengthDropdown(index: number): string {
    if (this.subTableConfig === undefined || this.subTableConfig === null) {
      return 'No';
    }
    return this.subTableConfig.showPageLengthDropdown ? 'Yes' : 'No';
  }

  setSubTableShowTableHeaders(data: ElementPropertyFieldEvent) {
    if (this.subTableConfig == undefined) {
      this.subTableConfig = new RWTableConfig();
    }
    this.subTableConfig.showTableHeaders = data.event.target.value === 'Yes' ? true : false;
  }

  getSubTableShowTableHeaders(index: number): string {
    if (this.subTableConfig === undefined || this.subTableConfig === null) {
      return 'No';
    }
    return this.subTableConfig.showTableHeaders ? 'Yes' : 'No';
  }

  getPayLoadDataSourceName(): string {
    if (this.payLoadDataSource === undefined || this.payLoadDataSource === null) {
      return '';
    }
    return this.payLoadDataSource.payLoadDataSourceName;
  }

  setPayLoadDataSourceName(data: ElementPropertyFieldEvent) {
    if (this.payLoadDataSource == undefined) {
      this.payLoadDataSource = new RWPayLoadDataSource();
    }
    this.payLoadDataSource.payLoadDataSourceName = data.event.target.value;
  }

  getPayLoadDataSourceProperty(): string {
    if (this.payLoadDataSource === undefined || this.payLoadDataSource === null) {
      return '';
    }
    return this.payLoadDataSource.payLoadProperty;
  }

  setPayLoadDataSourcePropertyD(propertyName: string) {
    this.payLoadDataSource.payLoadProperty = propertyName;
  }

  setPayLoadDataSourceProperty(data: ElementPropertyFieldEvent) {
    if (this.payLoadDataSource == undefined) {
      this.payLoadDataSource = new RWPayLoadDataSource();
    }
    this.payLoadDataSource.payLoadProperty = data.event.target.value;
  }

  setPayLoadDataSource(payLoadDataSource: RWPayLoadDataSource) {
    this.payLoadDataSource = payLoadDataSource;
  }

  getPayLoadDataSource(): RWPayLoadDataSource {
    return this.payLoadDataSource;
  }

  getRequiredMessage(): string {
    if (this.validations === undefined || this.validations === null) {
      return '';
    }
    return this.validations.requiredMessage;
  }

  setRequiredMessage(data: ElementPropertyFieldEvent) {
    if (this.validations == undefined) {
      this.validations = new RWValidations();
    }
    this.validations.requiredMessage = data.event.target.value;
  }

  getRegularExpressionPatternFiled(): string {
    if (this.validations === undefined || this.validations === null) {
      return '';
    }
    return this.validations.regexPattern;
  }

  setRegularExpressionPatternFiled(data: ElementPropertyFieldEvent) {
    if (this.validations == undefined) {
      this.validations = new RWValidations();
    }
    this.validations.regexPattern = data.event.target.value;
  }


  getRequiredFiledClass(): string {
    if (this.validations === undefined || this.validations === null) {
      return '';
    }
    return this.validations.requiredClass;
  }

  setRequiredFiledClass(data: ElementPropertyFieldEvent) {
    if (this.validations == undefined) {
      this.validations = new RWValidations();
    }
    this.validations.requiredClass = data.event.target.value;
  }

  getValidationRequired(index: number): string {
    if (this.validations === undefined || this.validations === null) {
      return 'No';
    }
    return this.validations.required ? 'Yes' : 'No';
  }

  setValidationRequired(data: ElementPropertyFieldEvent) {
    if (this.validations == undefined) {
      this.validations = new RWValidations();
    }
    this.validations.required = data.event.target.value === 'Yes' ? true : false;
  }

  getRegularExpressionFiledType(index: number): string {
    if (this.validations === undefined || this.validations === null) {
      return 'No';
    }
    return this.validations.regexRequired ? 'Yes' : 'No';
  }

  setRegularExpressionFiledType(data: ElementPropertyFieldEvent) {
    if (this.validations == undefined) {
      this.validations = new RWValidations();
    }
    this.validations.regexRequired = data.event.target.value === 'Yes' ? true : false;
  }

  getValidations(): any {
    return this.validations;
  }

  setValidations(validations: any): void {
    if (validations === undefined) {
      return
    }
    this.validations = validations;
  }

  validate() {

    if (this.validations === undefined) {
      return true;
    }
    if (this.validations.required === true && this.getValue() === '') {
      return false;
    }

    if (this.validations.regexRequired === true && this.regExValidate()) {
      return false;
    }

    return true;
  }

  regExValidate() {

    let patterToRegx = new RegExp(this.validations.regexPattern);
    if (patterToRegx.test(this.getValue()) === false) {
      return true;
    }

    return false;
  }

  setHidePopup(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          if (this.events[data.index].action.properties.length === 0) {
            this.events[data.index].action.properties.push(new RWActionProperty('rw-callback', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[0] = new RWActionProperty('rw-callback', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getHidePopup(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    if (this.events[index].action.properties[0] === undefined || this.events[index].action.properties[0] === null) {
      return '';
    }
    return JSON.stringify(this.events[index].action.properties[0].value, null, 2);
  }


  getStoreSelfDataSourceResponseProperty(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'loadStoreSelfDataSourceResponseProperty');
    if (property == undefined || property === null) {
      return '';
    }
    return property.value;
  }

  setStoreSelfDataSourceResponseProperty(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'loadStoreSelfDataSourceResponseProperty');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('loadStoreSelfDataSourceResponseProperty', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('loadStoreSelfDataSourceResponseProperty', 'string', data.event.target.value);
          }
        }
      }
    }

  }

  setStoreSelfDataSourceResponsePropertyActions(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'loadStoreSelfDataSourceResponseProperty');
          let responseProperty = undefined;
          if (index > -1) {
            responseProperty = this.events[data.index].action.properties[index];
          }
          this.events[data.index].action.properties = [];
          if (responseProperty !== undefined && responseProperty !== null) {
            this.events[data.index].action.properties.push(responseProperty);
          }
          this.events[data.index].action.properties.push(...JSON.parse(data.event.target.value));
        }
      }
    }
  }

  getStoreSelfDataSourceResponsePropertyActions(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const allProps = JSON.parse(JSON.stringify(this.events[index].action.properties));

    const propIndex = this.events[index].action.properties.findIndex(v => v.key === 'loadStoreSelfDataSourceResponseProperty');
    if (propIndex > -1) {
      allProps.splice(propIndex, 1);
    }
    return JSON.stringify(allProps, null, 2);
  }



  setStoreSelfDataSourceQueryParam(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'queryParam');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getStoreSelfDataSourceQueryParam(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'queryParam');
    if (property == undefined || property === null) {
      return '';
    }
    return JSON.stringify(property.value);
  }


  setLoadSelfDataSourceQueryParam(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'queryParam');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getLoadSelfDataSourceQueryParam(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'queryParam');
    if (property == undefined || property === null) {
      return '';
    }
    return JSON.stringify(property.value);
  }

  setStoreOtherDataSourceQueryParam(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'queryParam');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getStoreOtherDataSourceQueryParam(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'queryParam');
    if (property == undefined || property === null) {
      return '';
    }
    return JSON.stringify(property.value);
  }

  setLoadSelfDataSourceBody(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'body');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('body', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('body', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getLoadSelfDataSourceBody(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'body');
    if (property == undefined || property === null) {
      return '';
    }
    return JSON.stringify(property.value);
  }


  setLoadOtherDataSourceBody(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'body');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('body', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('body', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getLoadOtherDataSourceBody(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'body');
    if (property == undefined || property === null) {
      return '';
    }
    return JSON.stringify(property.value);
  }

  setStoreOtherDataSourceBody(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'body');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('body', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('body', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getStoreOtherDataSourceBody(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'body');
    if (property == undefined || property === null) {
      return '';
    }
    return JSON.stringify(property.value);
  }

  setStoreSelfDataSourceBody(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'body');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('body', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('body', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getStoreSelfDataSourceBody(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'body');
    if (property == undefined || property === null) {
      return '';
    }
    return JSON.stringify(property.value);
  }


  setOpenTabQueryParam(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'queryParam');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getOpenTabQueryParam(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'queryParam');
    if (property == undefined || property === null) {
      return '';
    }
    return JSON.stringify(property.value);
  }



  setNavigateUrlQueryParam(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'queryParam');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('queryParam', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }
  }

  getNavigateUrlQueryParam(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'queryParam');
    if (property == undefined || property === null) {
      return '';
    }
    return JSON.stringify(property.value);
  }


  getStoreOtherDataSoureResponseProperty(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'loadStoreOtherDataSoureResponseProperty');
    if (property == undefined || property === null) {
      return '';
    }
    return property.value;
  }

  setStoreOtherDataSoureResponseProperty(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'loadStoreOtherDataSoureResponseProperty');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('loadStoreOtherDataSoureResponseProperty', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('loadStoreOtherDataSoureResponseProperty', 'string', data.event.target.value);
          }
        }
      }
    }

  }

  setStoreOtherDataSoureResponsePropertyActions(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'loadStoreOtherDataSoureResponsePropertyActions');
          let responseProperty = undefined;
          if (index > -1) {
            responseProperty = this.events[data.index].action.properties[index];
          }
          this.events[data.index].action.properties = [];
          if (responseProperty !== undefined && responseProperty !== null) {
            this.events[data.index].action.properties.push(responseProperty);
          }
          this.events[data.index].action.properties.push(...JSON.parse(data.event.target.value));
        }
      }
    }
  }

  getStoreOtherDataSoureResponsePropertyActions(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const allProps = JSON.parse(JSON.stringify(this.events[index].action.properties));

    const propIndex = this.events[index].action.properties.findIndex(v => v.key === 'loadStoreOtherDataSoureResponsePropertyActions');
    if (propIndex > -1) {
      allProps.splice(propIndex, 1);
    }
    return JSON.stringify(allProps, null, 2);
  }

  getIsReadOnly(): any {
    return this.isReadOnly ? 'true' : 'false';
  }

  setIsReadOnlyD(isReadOnly: boolean): void {
    this.isReadOnly = isReadOnly;
    this.attributes['readonly'] = isReadOnly;

  }

  setIsReadOnly(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.isReadOnly = data.event.target.value === 'true' ? true : false;
      this.attributes['readonly'] = data.event.target.value === 'true' ? true : false;
    }
  }


  getIsDisabledOnly(): any {
    return this.isDisabled ? 'true' : 'false';
  }

  setIsDisabledOnlyD(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.attributes['disabled'] = isDisabled;

  }

  setIsDisabledOnly(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.isDisabled = data.event.target.value === 'true' ? true : false;
      this.attributes['disabled'] = data.event.target.value === 'true' ? true : false;
    }
  }

  getTranscript(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'transcript');
    if (property == undefined || property === null) {
      return ''
    }
    return property.value;
  }

  setTranscript(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'transcript');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('transcript', 'string', data.event.target.value));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('transcript', 'string', data.event.target.value);
          }
        }
      }
    }

  }




  getPayLoadKey(): string {
    return this.payLoadKey;
  }

  setPayLoadKey(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.payLoadKey = data.event.target.value;
    }
  }

  setPayLoadKeyD(payLoadKey: string) {
    this.payLoadKey = payLoadKey;
  }



  getRWCallback(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'rw-callback');
    if (property == undefined || property === null) {
      return ''
    }
    return JSON.stringify(property.value);
  }

  setRWCallback(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'rw-callback');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('rw-callback', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('rw-callback', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }

  }

  setAnchorTypeD(anchorType: string) {
    this.anchorType = anchorType;
  }

  getAnchorType(): string {
    return this.anchorType;
  }


  setAnchorType(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.anchorType = data.event.target.value;
    }
  }

  getIsLoading(): string {
    if (this.isLoading === undefined || this.isLoading === null) {
      return 'Yes';
    }
    return this.isLoading ? 'Yes' : 'No';
  }

  setIsLoading(data: ElementPropertyFieldEvent) {
    if (this.isLoading === undefined) {
      this.isLoading = true;
    }
    this.isLoading = data.event.target.value === 'Yes' ? true : false;
  }

  setIsLoadingD(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setPayloadRestFieldPath(data: ElementPropertyFieldEvent) {
    if (data === undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'fieldPath');
          let responseProperty = undefined;
          if (index > -1) {
            responseProperty = this.events[data.index].action.properties[index];
          }
          this.events[data.index].action.properties = [];
          if (responseProperty !== undefined && responseProperty !== null) {
            this.events[data.index].action.properties.push(responseProperty);
          }
          this.events[data.index].action.properties.push(...JSON.parse(data.event.target.value));
        }
      }
    }
  }

  getPayloadRestFieldPath(index: number) {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const allProps = JSON.parse(JSON.stringify(this.events[index].action.properties));

    const propIndex = this.events[index].action.properties.findIndex(v => v.key === 'fieldPath');
    if (propIndex > -1) {
      allProps.splice(propIndex, 1);
    }
    return JSON.stringify(allProps, null, 2);
  }

  setMinDate(date) {
    this.minDate = date
  }

  getMinDate() {
    return this.minDate
  }

  setMaxLength(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.maxLength = data.event.target.value;
      this.attributes['maxlength'] = data.event.target.value;
    }
  }

  setMaxLengthD(maxLength: string) {
    this.maxLength = maxLength;
    this.attributes['maxlength'] = maxLength;
  }

  getMaxLength(): string {
    return this.maxLength;
  }

  setMinLength(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.minLength = data.event.target.value;
      this.attributes['minlength'] = data.event.target.value;
    }
  }

  setMinLengthD(minLength: string) {
    this.minLength = minLength;
    this.attributes['minlength'] = minLength;
  }

  getMinLength(): string {
    return this.minLength;
  }

  getFileName(index: number): string {
    if (this.events[index] === undefined || this.events[index] === null) {
      return '';
    }
    if (this.events[index].action === undefined || this.events[index].action === null) {
      return '';
    }
    if (this.events[index].action.properties === undefined || this.events[index].action.properties === null) {
      return '';
    }
    const property = this.events[index].action.properties.find(v => v.key === 'fileName');
    if (property == undefined || property === null) {
      return ''
    }
    return JSON.stringify(property.value);
  }

  setFileName(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      if (this.events[data.index] !== undefined && this.events[data.index] !== null) {
        if (this.events[data.index].action !== undefined && this.events[data.index].action !== null) {
          if (this.events[data.index].action.properties === undefined || this.events[data.index].action.properties === null) {
            this.events[data.index].action.properties = [];
          }
          const index = this.events[data.index].action.properties.findIndex(v => v.key === 'fileName');
          if (index === -1) {
            this.events[data.index].action.properties.push(new RWActionProperty('fileName', 'string', JSON.parse(data.event.target.value)));
          } else {
            this.events[data.index].action.properties[index] = new RWActionProperty('fileName', 'string', JSON.parse(data.event.target.value));
          }
        }
      }
    }

  }


  setFileTypeD(fileType: string) {
    this.fileType = fileType;
  }

  getFileType(): string {
    return this.fileType;
  }


  setFileType(data: ElementPropertyFieldEvent) {
    if (data == undefined) {
      return;
    }
    if (data.event !== undefined) {
      this.fileType = data.event.target.value;
    }
  }

}



