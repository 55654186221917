import { ElementProperty } from './ElementProperty';
import { ElementPropertyField } from "./ElementPropertyField";
export class ElementPropertyFieldEvent {
    event: any;
    property: ElementProperty;
    field: ElementPropertyField;
    index: number;
    constructor(event: any, property: ElementProperty, field: ElementPropertyField, index: number) {
        this.event = event;
        this.property = property;
        this.field = field;
        this.index = index;
    }
}
