<div class="page-title">
    <label class="page-heading" *ngIf="titleToggle">New Menu</label>
    <label class="page-heading" *ngIf="!titleToggle">Edit Menu</label>
    <div class="page-actions">
        <a class="btn btn-sm btn-secondary" routerLink="/menu">Back</a>
    </div>
</div>
<div class="page-content">
    <div class="new-form">
        <form #newForm="ngForm" (ngSubmit)="submit($event, newForm)" method="POST">
            <label>Create Menu</label>
            <div class="form-row align-items-center">
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">Name</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Name</div>
                        </div>
                        <input name="name" [(ngModel)]="model.name" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Dashboard" required />
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput2">Path</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Path</div>
                        </div>
                        <input name="path" [(ngModel)]="model.path" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="dashboard" required />
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput3">Page</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Page</div>
                        </div>
                        <select class="form-control" name="page" [(ngModel)]="model.page" required>
                            <option [value]="null"></option>
                            <option *ngFor="let page of pages" [value]="page.code + '^' + page.version">{{page.name}}
                                v{{page.version}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-row align-items-center">

                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput4">Show in Menu</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Show in Menu</div>
                        </div>
                        <select class="form-control" name="show" [(ngModel)]="model.show" required>
                            <option [value]="true">Show</option>
                            <option [value]="false">Hide</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">Role</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Role</div>
                        </div>
                        <input name="role" [(ngModel)]="model.role" type="text" class="form-control"
                            id="inlineFormInputGroup" placeholder="Role" />
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="sr-only" for="inlineFormInput1">Navigation Environment</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Navigation Environment</div>
                        </div>
                        <input name="navigationEnvironment" [(ngModel)]="model.navigationEnvironment" type="text" class="form-control"
                            id="inlineFormInputGroup"  />
                    </div>
                </div>
            </div>
            <div class="form-row align-items-center">
                <div class="col text-center">
                    <button type="submit" class="btn btn-primary mb-2">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>