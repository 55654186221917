<header class="header shadow-sm bg-dark">
    <!-- Fixed navbar -->
    <div class="container">
        <nav class="navbar navbar-expand-sm navbar-dark px-0">
            <a class="navbar-brand" href="#">
                <img src="./assets/images/rv_logo_white.png" height="35" />
                <!-- RealVariable -->
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                <ul class="navbar-nav mt-0 mb-0 w-sm-100">
                    <li class="ml-sm-0 nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a class="nav-link" routerLink="">Dashboard</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="ml-sm-0 nav-link" routerLink="pages">Pages</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="ml-sm-0 nav-link" routerLink="menu">Menu</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="ml-sm-0 nav-link" routerLink="settings">Settings</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="ml-sm-0 nav-link" routerLink="data-sources">Data Sources</a>
                    </li>
                </ul>
                <ul class="navbar-nav mt-0 mb-0 w-sm-100">
                    <li class="nav-item">
                        <a class="ml-sm-0 nav-link" href="#">{{loggedUserName}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="ml-sm-0 nav-link" routerLink="/" (click)="logoutClicked()">Logout</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>