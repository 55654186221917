import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ApplicationConfig } from 'src/app/classes/ApplicationConfig';
import { ApplicationPageConfig } from 'src/app/classes/ApplicationPageConfig';
import { ApplicationMenuConfig } from 'src/app/classes/ApplicationMenuConfig';
import { RWDataSource } from 'src/app/classes/RWDataSource';
import { HttpClient } from '@angular/common/http';
import * as monaco from 'monaco-editor';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  application = { applicationName: undefined, description: undefined, externalCss: undefined, footerText: undefined, logo: undefined, backgroundImage: undefined, fontSize: undefined, locale: undefined, currencyCode: undefined };
  logoUpload = { imagePath: undefined, URL: undefined };
  applicationId: string = undefined;
  pages: ApplicationPageConfig[] = [];
  menu: ApplicationMenuConfig[] = [];
  dataSources: RWDataSource[] = [];
  logoList = [];
  invoiceObject: File;
  externalCssEditorInstance: monaco.editor.IStandaloneCodeEditor = undefined;
  submitToggle: boolean = false;

  constructor(private dataProviderService: DataProviderService, private http: HttpClient) { }

  ngOnInit(): void {
    this.loadAppId();
    this.getLogoUrlList();
  }

  // ngAfterViewInit(): void {
  //   const id = 'external-css-editor';
  //   this.externalCssEditorInstance = monaco.editor.create(document.getElementById(id), {
  //     value: '',
  //     language: 'css'
  //   });
  //   console.log("this.externalCssEditorInstance",this.externalCssEditorInstance)
  //   if (this.externalCssEditorInstance !== undefined) {
  //     this.externalCssEditorInstance.setValue(this.application.externalCss);
  //   }
  // }

  submit(e, form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (!this.submitToggle) {
      return;
    }
    const appConfig = new ApplicationConfig();
    appConfig.description = this.application.description;
    appConfig.name = this.application.applicationName;
    appConfig.logo = this.application.logo;
    appConfig.footerText = this.application.footerText;
    appConfig.backgroundImage = this.application.backgroundImage;
    appConfig.fontSize = this.application.fontSize;
    appConfig.locale = this.application.locale;
    appConfig.currencyCode = this.application.currencyCode;
    // if (this.externalCssEditorInstance !== undefined) {
    //   appConfig.externalCss = this.externalCssEditorInstance.getValue();
    // }
    appConfig.externalCss = this.application.externalCss;
    // appConfig.pages = this.pages;
    // appConfig.menu = this.menu;
    // appConfig.dataSources = this.dataSources;
    this.dataProviderService.updateSettingConfig(appConfig).then(done => {
      alert('Application Created');
      form.reset();
      this.loadAppId();
    }, error => {
      alert('Unable to create page ' + error);
    });
  }

  loadAppId() {
    this.dataProviderService.getAppSettingsConfig().then(appConfig => {
      console.log("appConfig", appConfig)
      this.applicationId = appConfig.id;
      this.pages = appConfig.pages;
      this.dataSources = appConfig.dataSources;
      this.menu = appConfig.menu;
      this.application.applicationName = appConfig.name;
      this.application.description = appConfig.description;
      this.application.externalCss = appConfig.externalCss;
      // if (this.externalCssEditorInstance !== undefined) {
      //   this.externalCssEditorInstance.setValue(this.application.externalCss);
      // }
      this.application.locale = appConfig.locale;
      this.application.currencyCode = appConfig.currencyCode;
      this.application.footerText = appConfig.footerText;
      this.application.backgroundImage = appConfig.backgroundImage;
      this.application.logo = appConfig.logo;
      this.application.fontSize = appConfig.fontSize;
      this.submitToggle = true;
    })
  }

  getLogoUrl() {
    return this.http.get('https://api.logo-manager.uat.dizitwin.com/api/logos');
  }

  postLogoUrl(file) {
    return this.http.post('https://api.logo-manager.uat.dizitwin.com/api/upload', file);
  }

  getLogoUrlList() {
    this.getLogoUrl().subscribe(resp => {
      this.logoList = resp['files'];
    });
  }

  onChange(e) {
    this.invoiceObject = e.target.files[0];
  }

  onUploadFile() {
    if (this.logoUpload.imagePath === undefined) {
      return
    }
    const formData = new FormData();
    formData.append('file', this.invoiceObject);
    this.postLogoUrl(formData).subscribe(resp => {
      alert('File Uploaded ');
      this.logoUpload.imagePath = undefined;
      this.getLogoUrlList();
    })
  }
}

