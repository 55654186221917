export enum RWEventType {
  CLICK = 'CLICK',
  HOVER = 'HOVER',
  CHANGE = 'CHANGE',
  KEYUP = 'KEYUP',
  KEYDOWN = 'KEYDOWN',
  KEYPRESS = 'KEYPRESS',
  ON_LOAD = 'ON_LOAD',
  FORMSUBMIT = 'FORMSUBMIT',
  UNDEFINED = 'UNDEFINED',
}
