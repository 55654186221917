

export class RWTableConfig {
    columnVisibilityDropDown: boolean;
    commonSearch: boolean;
    excelExportButton: boolean;
    excelExportFileName: string;
    excelExportButtonText: string;
    showExpandArrows: boolean;
    showExpandAllArrows: boolean;
    showPageLengthDropdown: boolean;
    showTableHeaders: boolean;
    visibleColumnFiltersVisibility: boolean;

    constructor() {
    }
}


