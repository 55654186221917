import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  

  

  private loginStateChange = new Subject();
  loginStateChanged$ = this.loginStateChange.asObservable();

  constructor(private keycloakService: KeycloakService, private router: Router) { }

  setLoginState(state: number) {
    if (state === 0) {
      localStorage.removeItem('loginStatus');
    } else {
      localStorage.setItem('loginStatus', state+"");
    }
    this.loginStateChanged();
  }

  getLoginState(): boolean {
    return (localStorage.getItem('loginStatus') !== undefined && localStorage.getItem('loginStatus') !== null);
  }

  loginStateChanged() {
    this.loginStateChange.next();
  }

  getLoggedUser() {
    try {
      let userDetails = this.keycloakService.getKeycloakInstance().idTokenParsed;
      return userDetails;
    } catch (e) {
      return undefined;
    }
  }

  logout() {
    this.keycloakService.logout();
  }

  redirectToProfile() {
    this.keycloakService.getKeycloakInstance().accountManagement()
  }

  getRoles(): string[]{
    
    return this.keycloakService.getUserRoles();
  }
}
